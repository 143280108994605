import { couponWithAdminToken, versionWithAdminToken } from '.';

/**
 * 최고 관리자 쿠폰 발급
 * @param req
 * @returns
 */
export const postAdminCoupon = (req: any) => couponWithAdminToken.post('', req);

/**
 * 지점 키오스크 버전 정보
 * @returns
 */
export const getAdminVersion = () => versionWithAdminToken.get('');
