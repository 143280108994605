import React, { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';

interface TabProps {
  selected: string;
  setSelected: Dispatch<SetStateAction<string>> | ((val: string) => void);
  tabItems: { key: string; text: string }[];
}

const TabItem = styled.div<{ enabled?: boolean }>`
  cursor: pointer;
  width: 100%;
  padding: 1rem;
  text-align: center;

  font-weight: 600;
  border-bottom: 2px solid ${({ theme, enabled }) => (enabled ? theme.primary : theme.white)};

  ${({ enabled }) =>
    enabled &&
    css`
      color: ${({ theme }) => theme.primary};
    `}
`;

const TabWrapper = styled.div<{ tabItemCount: number }>`
  width: 100%;
  /* padding: 1rem 0; */
  display: grid;
  grid-template-columns: ${({ tabItemCount }) => `repeat(${tabItemCount}, 1fr)`};
`;

const Tab = ({ selected, setSelected, tabItems }: TabProps) => {
  return (
    <TabWrapper tabItemCount={tabItems.length}>
      {tabItems.map((tab) => (
        <TabItem enabled={tab.key === selected} onClick={() => setSelected(tab.key)}>
          {tab.text}
        </TabItem>
      ))}
    </TabWrapper>
  );
};

export default Tab;
