import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { CheckBoxRound } from '../../auth/signup';
import { getUUID, HectoPay } from '../common';
import { RadioBtn, RadioLabel, SolidBtn } from '../commonStyle';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import { backSaleInfo, getMerchantInfo, msgPointWebpayData, webpayData } from '../../lib/api';
import styled from 'styled-components';
import Swal from 'sweetalert2';

const SelectPaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const SelectPaymentType = ({ data, show, onClose, payData, logData }) => {
  const [paymentData, setPaymentData] = useState({
    type: 'card',
    payType: 'credit',
  });

  //결제 데이터 가져오기
  const getPaymentInfo = async () => {
    try {
      let paystatus;
      let apiPayData;
      //api 실행 (payData.type이 message일 경우)
      if (payData.type === 'message') {
        const { status, data } = await msgPointWebpayData({
          msgItemId: payData.id,
          merchantId: payData.mid,
          payMethod: paymentData.payType,
        });
        paystatus = status;
        apiPayData = data;
      } else {
        const { status, data } = await webpayData({
          merchantId: payData.mid,
          amount: payData.amount,
          payMethod: paymentData.payType,
          startDate: payData.startDate,
          startTime: payData.startTime,
          seminarCount: payData.seminarCount,
          seminarId: payData.seminarId,
        });
        paystatus = status;
        apiPayData = data;
      }

      const { data } = await getMerchantInfo(payData.mid);
      const merchantData = data[0];

      if (paystatus === 200) {
        const date = moment().format('YYYYMMDD');
        const time = moment().format('HHmmss');

        let pkt =
          apiPayData.url_store_id +
          (paymentData.type === 'card' ? 'card' : 'corp') +
          apiPayData.merchant_uid +
          date +
          time +
          payData.amount.toString() +
          apiPayData.url_hash_key;

        const hashData = CryptoJS.SHA256(pkt).toString();

        HectoPay(
          apiPayData.url_store_id,
          date,
          time,
          apiPayData.merchant_uid,
          payData.type === 'message' ? '렛미업' : merchantData.store_name,
          payData.type === 'message' || !merchantData.store_name_en
            ? 'letmeup'
            : merchantData.store_name_en,
          payData.type === 'message' ? `${payData.points}p 충전` : payData.name,
          apiPayData.hash_amount,
          hashData,
          payData.amount.toString(),
          payData.id,
          window.location.origin.includes('dev') || window.location.origin.includes('localhost')
            ? 'https://tbnpg.settlebank.co.kr'
            : 'https://npg.settlebank.co.kr',
          paymentData.type,
          payData.mid,
          paymentData.payType,
          payData.type,
          //로그쌓기위한 데이터(message는 불필요)
          logData,
        );

        onClose();
      }
    } catch (err) {
      console.log(err);

      Swal.fire({
        text: '결제중 오류가 발생했습니다. 다시 시도해주세요',
        timer: 3500,
      }).then(() => onClose());
    }
  };

  // 결제수단 변경
  const handletPayment = (paymentType, payType) => {
    // 한개는 세부적인 결제수단, 다른 하나는 넓은 범위의 결제수단(카드, 간편결제)
    setPaymentData({ ...paymentData, type: paymentType, payType: payType });
  };

  return (
    <Modal
      style={{ maxWidth: window.innerWidth < 480 ? '100%' : '65%' }}
      centered
      isOpen={show}
      toggle={onClose}
    >
      <ModalBody>
        <SelectPaymentContainer>
          {payData &&
            (payData.type === 'message' ||
              (payData.type !== 'message' && payData.credit_store_id)) && (
              <RadioLabel>
                <RadioBtn
                  checked={paymentData.type === 'card'}
                  onClick={() => handletPayment('card', 'credit')}
                />
                신용카드
              </RadioLabel>
            )}
          {/* <RadioLabel>
            <RadioBtn
              // disabled
              checked={paymentData.type === "bank"}
              onClick={() => handletPayment("bank", "nx_mid_il")}
            />
            계좌이체
          </RadioLabel>
          <RadioLabel>
            <RadioBtn
              // disabled
              checked={paymentData.type === "vbank"}
              onClick={() => handletPayment("vbank", "nx_mid_il")}
            />
            가상계좌
          </RadioLabel> */}
          {payData &&
            (payData.type === 'message' ||
              (payData.type !== 'message' && payData.simple_store_id)) && (
              <>
                <RadioLabel>
                  <RadioBtn
                    // disabled
                    checked={paymentData.type === 'PAC'}
                    onClick={() => handletPayment('PAC', 'simple')}
                  />
                  페이코
                </RadioLabel>
                <RadioLabel>
                  <RadioBtn
                    // disabled
                    checked={paymentData.type === 'KKP'}
                    onClick={() => handletPayment('KKP', 'simple')}
                  />
                  카카오
                </RadioLabel>{' '}
                <RadioLabel>
                  <RadioBtn
                    // disabled
                    checked={paymentData.type === 'NVP'}
                    onClick={() => handletPayment('NVP', 'simple')}
                  />
                  네이버
                </RadioLabel>
              </>
            )}
          <SolidBtn onClick={() => getPaymentInfo()}>결제하기</SolidBtn>
        </SelectPaymentContainer>
      </ModalBody>
    </Modal>
  );
};

export default SelectPaymentType;
