import React, { useState } from 'react';
import { Input } from 'reactstrap';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import { Checkbox, CheckboxContainer } from '../../pages/messageBulk';
import Swal from 'sweetalert2';
import { sendReceipt } from '../../lib/api';
import useHandleAPICall from '../../lib/hooks/useHandleAPICall';
import useHandleError from '../../lib/hooks/useHandleError';

const InputModal = styled.div`
  .flex {
    display: flex;
    > div {
      justify-content: flex-end;
      width: 50%;
    }
  }
  .inputContainer {
    margin: 1vw 0 2vw;
  }
  .center {
    justify-content: center;
    gap: 2vw;
  }
`;

const InputPhoneNumModal = ({ close, userPhoneData, merchantId, transactionId, userId }) => {
  const [phoneNum, setPhoneNum] = useState('010');
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const sendUserReceipt = async () => {
    if (phoneNum.length !== 11) {
      Swal.fire({
        text: '핸드폰번호 11자리 전부 입력해주세요',
        timer: 3500,
      });
      return;
    }
    try {
      const { status } = await createRequest(sendReceipt, {
        merchantId,
        transactionId,
        userId,
        phoneNum,
      });
      if (status === 200) {
        Swal.fire({
          text: '영수증 재발급이 완료되었습니다.',
          icon: 'success',
          timer: 3500,
        }).then(() => {
          close();
          setPhoneNum('010');
        });
      }
    } catch (err) {
      catchError(err, '영수증 재발급이 실패했습니다. 잠시 후 다시 시도해 주세요.');
    }
  };

  const handleChangeInput = (e) => {
    if (e.target.value.length !== 12) setPhoneNum(e.target.value);
  };

  const selectUserPhoneNum = () => {
    if (phoneNum !== userPhoneData) setPhoneNum(userPhoneData);
    else setPhoneNum('010');
  };
  console.log(userPhoneData);
  return (
    <Modal centered isOpen={close} toggle={close}>
      <ModalBody>
        <InputModal>
          <p>영수증을 발송할 번호를 입력해주세요</p>
          <div className="flex inputContainer">
            <Input value={phoneNum} onChange={(e) => handleChangeInput(e)} />
            <CheckboxContainer>
              <Checkbox>
                <Input
                  id="user"
                  type="checkbox"
                  onChange={() => selectUserPhoneNum()}
                  checked={phoneNum === userPhoneData}
                />
                <span className="checkbox_icon" />

                <p>유저에게 전송</p>
              </Checkbox>
            </CheckboxContainer>
          </div>
          <div className="flex center">
            <button className="btn btn-danger btn-sm" onClick={sendUserReceipt}>
              영수증 재발송
            </button>
            <button
              className="btn btn-sm"
              style={{ backgroundColor: '#282b46', color: '#fff' }}
              onClick={close}>
              창닫기
            </button>
          </div>
        </InputModal>
      </ModalBody>
    </Modal>
  );
};

export default InputPhoneNumModal;
