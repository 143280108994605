import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import Format from '../../util/formatter';
import { CheckBox, FlexCenterH } from '../commonStyle';
import TableFilterDropdown from './TableFilterDropdown';
import Pagination from './TablePagination';

import { ReactComponent as AscendingOrder } from '../../assets/images/sort_asc.svg';
import { ReactComponent as DescendingOrder } from '../../assets/images/sort_desc.svg';
import TooltipText from '../common/TooltipText';
import NotFoundContainer from '../common/NotFoundContainer';

const currentPosts = (currentPage: number, postsPerPage: number, tmp: any) => {
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  let currentPosts = 0;
  currentPosts = tmp.slice(indexOfFirst, indexOfLast);
  return currentPosts;
};

const Container = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  /* &::-webkit-scrollbar {
    display: none;
  } */
`;
const Table = styled.table<{ isStripe?: 'odd' | 'even' | 'none' }>`
  width: 100%;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  color: #555;
  overflow: auto;

  min-height: 28rem;
  /* max-height: 40rem; */

  thead {
    width: 100%;
    display: table;
    overflow: auto;
    font-weight: 600;
    position: sticky;

    top: 0;
    background-color: white;
    border-bottom: 1px solid #eaeaea;
  }
  tbody {
    width: 100%;
    display: table;

    tr {
      &:nth-child(${({ isStripe }) => (isStripe === 'odd' ? 'odd' : 'even')}) {
        /* border-radius: 0.5rem; */
        background-color: ${({ isStripe }) => (isStripe !== 'none' ? '#f5f5f5' : '#ffffff')};
      }
    }
  }
`;

const Th = styled.th<{ width?: string; isStripe?: 'odd' | 'even' | 'none' }>`
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: 3rem;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  padding: 0 0.5rem;
  user-select: none;
  white-space: nowrap;
  border-bottom: ${({ isStripe }) => (isStripe !== 'none' ? 'none' : '1px solid #eaeaea')};
  background-color: white;
`;

const Tr = styled.tr<{ isSelected: boolean }>`
  overflow: auto;
  width: 100%;
  /* background-color: ${({ isSelected }) => (isSelected ? '#1a7cfd14' : '#fff')}; */
  background-color: #f5f5f5;

  &:hover {
    background-color: #eaeaea;
  }
`;

const Td = styled.td<{
  width?: string;
  isStripe?: 'odd' | 'even' | 'none';
}>`
  padding: 0.5rem;
  height: 3rem;
  text-align: center;
  vertical-align: middle;
  border-bottom: ${({ isStripe }) => (isStripe !== 'none' ? 'none' : '1px solid #eaeaea')};
`;

const DivInTd = styled.div<{ width?: string }>`
  width: ${(props) => (props.width ? props.width : 'auto')};
  word-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  word-break: keep-all;
  text-overflow: ellipsis;
`;

const SortButton = styled.button``;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media screen and (max-width: 930px) {
    flex-direction: column;
  }
`;
const TableInfoContainer = styled.div`
  overflow: hidden;
  color: var(--neutral-gray-50, #555);
  text-overflow: ellipsis;

  /* Table/Total_text */
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 1.25rem */

  > span {
    color: var(--primary-primary, #6c60f4);
    font-weight: 700;
  }
`;

export type SortType = 'none' | 'desc' | 'asc';

export type FilterType = {
  key: string;

  filterList: FilterListType[];
  onClickFilterItem?: (key: string, filterValue: FilterListType) => void;
  multiselect?: boolean;
  width?: string;
};

export type FilterListType = {
  id: string;
  value: string;
};

export type ColumnType = {
  accessor: string;
  Header: string | React.ReactNode;
  width?: string;
  type?: TableItem;
  isSort?: boolean;
  sortKey?: string;
  filterKey?: string;
  align?: 'start' | 'center' | 'end';
};

type TableStyleProps = {
  tableContainer?: React.CSSProperties;
  table?: React.CSSProperties;
  thead?: React.CSSProperties;
  tbody?: React.CSSProperties;
  tr?: React.CSSProperties;
  th?: React.CSSProperties;
  td?: React.CSSProperties;
  divInTd?: React.CSSProperties;
};

export type TableProps = {
  columns: ColumnType[];
  defaultData: any[];
  data: any[];
  filters?: FilterType[];
  isCheckbox?: boolean;
  checkboxConfig?: {
    id: string;
    externalChecklist?: any[];
    setExternalChecklist: any;
    checkItem?: (item: any) => void;
    clickRow?: boolean;
  };
  infoConfig?: {
    label1: string;
    label2?: string;
    value1: { type?: 'total' | 'checked'; text?: string };
    value2?: { type?: 'total' | 'checked'; text?: string };
    unit1: string;
    unit2?: string;
  };
  topContainerStyle?: React.CSSProperties;
  style?: TableStyleProps;
  conditionalStyling?: {
    tr: { func: (data: any) => boolean; style: React.CSSProperties };
    td: { key: string; func: (data: any) => boolean; style: React.CSSProperties };
  };
  addOns?: React.ReactNode;
  pagination?: boolean;
  paginationConfig?: {
    postsPerPage?: number;
    currentPage?: number;
    totalcount?: number;
    setCurrentPage?: Dispatch<SetStateAction<number>>;
    fetchData?: (currentPage: number, postsPerPage: number) => void;
  };
  postsPerPage?: number;
  notFoundText?: string;
  clickRowEvent?: (item: any) => void;
  setData?: (data: any[]) => void;
  isStripe?: 'odd' | 'even' | 'none';
  getTableData?: (data: any[]) => void;
};

const NewTableComponent = ({
  columns,
  defaultData,
  data,
  filters,
  isCheckbox,
  checkboxConfig,
  infoConfig,
  style,
  topContainerStyle,
  conditionalStyling,
  addOns,
  pagination,
  paginationConfig,
  postsPerPage = 10,
  notFoundText,
  clickRowEvent,
  setData,
  isStripe = 'none',
  getTableData,
}: TableProps) => {
  const [tableData, setTableData] = useState<any[]>(data);
  const [paginationData, setPaginationData] = useState<any[]>(data);
  const [currentPage, setCurrentPage] = useState(1);
  //const [checklist, setCheckList] = useState<any[]>(checkboxConfig?.externalChecklist || []);
  const [sortType, setSortType] = useState<{ key: string; type: SortType }>({
    key: '',
    type: 'none',
  });

  const [filterState, setFilterState] = useState<{ key: string; value: string | string[] }[]>(
    filters
      ? filters.map((filter) => {
          return { key: filter.key, value: 'all' };
        })
      : [],
  );

  useEffect(() => {
    setCurrentPage(1);
    setFilterState(
      filters
        ? filters.map((filter) => {
            return { key: filter.key, value: 'all' };
          })
        : [],
    );
  }, [defaultData]);

  // 변경 전
  // useEffect(() => {
  //   if (data) {
  //     setTableData(data);
  //     const posts = currentPosts(currentPage, postsPerPage, data);
  //     setPaginationData(posts as any);
  //   }
  // }, [data]);

  // 변경 후
  useEffect(() => {
    if (data) {
      const next = [
        ...data.sort((valueA, valueB) => {
          if (sortType.type === 'desc') {
            if (valueA[sortType.key] === undefined || valueA[sortType.key] === null) return 1;
            if (valueB[sortType.key] === undefined || valueB[sortType.key] === null) return -1;
            return valueA[sortType.key] < valueB[sortType.key] ? 1 : -1;
          }
          if (valueA[sortType.key] === undefined || valueA[sortType.key] === null) return 1;
          if (valueB[sortType.key] === undefined || valueB[sortType.key] === null) return -1;
          return valueA[sortType.key] > valueB[sortType.key] ? 1 : -1;
        }),
      ];

      setTableData(next);

      const posts = currentPosts(currentPage, postsPerPage, next);
      if (!pagination) return setPaginationData(next);
      setPaginationData(posts as any);
    }
  }, [data]);

  useEffect(() => {
    if (pagination) {
      const posts = currentPosts(currentPage, postsPerPage, tableData);
      checkboxConfig?.setExternalChecklist([]);
      setPaginationData(posts as any);
    }
  }, [currentPage]);

  /**
   * 외부 체크리스트 값 변경
   */
  // useEffect(() => {
  //   checkboxConfig?.setExternalChecklist && checkboxConfig.setExternalChecklist([...checklist]);
  // }, [checklist]);

  useEffect(() => {
    const posts = currentPosts(currentPage, postsPerPage, tableData);
    if (!pagination) return setPaginationData(tableData);
    setPaginationData(posts as any);
    setCurrentPage(1);
  }, [postsPerPage]);

  const filterTableData = (key: string, filterValue: FilterListType | FilterListType[]) => {
    // 컴포넌트 내부의 children이 같은 값인지 판정
    const checkVisible = (targetItem: any, criteria: string) => {
      return targetItem === criteria || targetItem?.props?.children === criteria;
    };
    let filtered = defaultData;
    const newFilter = filterState.map((filter) => {
      if (filter.key === key) {
        // 하나의 필터에 대해 다중 선택된 경우
        if (Array.isArray(filterValue)) {
          if (filterValue.length === 0) return { key, value: 'all' };
          filtered = filtered.filter((item) =>
            filterValue.some((filterItem) => {
              return checkVisible(item[filter.key], filterItem.value);
            }),
          );
          return { key, value: filterValue.map((item) => item.value) };
        } else {
          // 단일 선택 필터인 경우
          if (filterValue.id === 'all') return { key, value: 'all' };
          filtered = filtered.filter((item) => checkVisible(item[filter.key], filterValue.value));
          return { key, value: filterValue.value };
        }
      } else {
        if (filter.value !== 'all') {
          filtered = filtered.filter((item) =>
            Array.isArray(filter.value)
              ? filter.value.some((filterItem) => item[filter.key] === filterItem)
              : item[filter.key] === filter.value,
          );
        }
        return filter;
      }
    });
    setFilterState(newFilter);
    if (pagination) setPaginationData(currentPosts(currentPage, postsPerPage, filtered) as any);

    setTableData(filtered);
    getTableData && getTableData(filtered);
    setData && setData(filtered);
  };

  const checkTableItem = (item: any | any[], checkType: 'all' | 'one' = 'one') => {
    if (!isCheckbox || !checkboxConfig) return;
    if (checkType === 'all') {
      if (
        checkboxConfig.externalChecklist &&
        checkboxConfig.externalChecklist.length === paginationData.length
      ) {
        checkboxConfig.setExternalChecklist([]);
      } else {
        checkboxConfig.setExternalChecklist([...paginationData]);
      }
    } else {
      const isExists =
        checkboxConfig.externalChecklist &&
        checkboxConfig.externalChecklist.some(
          (checkItem) => checkItem[checkboxConfig.id] === item[checkboxConfig.id],
        );
      if (isExists) {
        const filtered = checkboxConfig.externalChecklist
          ? checkboxConfig.externalChecklist.filter(
              (checkItem) => checkItem[checkboxConfig.id] !== item[checkboxConfig.id],
            )
          : [];
        checkboxConfig.setExternalChecklist(filtered);
      } else {
        checkboxConfig.setExternalChecklist(
          checkboxConfig.externalChecklist ? [...checkboxConfig.externalChecklist, item] : [item],
        );
      }
    }
  };

  const format = (type: TableItem, value: any) => {
    switch (type) {
      case 'number':
        return Format.formatNumber(value);
      case 'date':
        return Format.formatDate(value);
      case 'datetime':
        return Format.formatDateTime(value);
      case 'point':
        return Format.formatPoint(value);
      case 'percentage':
        return Format.formatPercentage(value);
      case 'phone':
        return Format.formatPhone(value);
      case 'registration':
        return Format.formatRegistrationNumber(value);
      default:
        return value;
    }
  };

  const onClickRow = (item: any) => {
    if (clickRowEvent) clickRowEvent(item);

    if (checkboxConfig && checkboxConfig?.clickRow) checkTableItem(item);
  };

  const sortTableItems = (key: string, sortKey?: string) => {
    try {
      let sortingKey = sortKey ? sortKey : key;
      let changedType: SortType =
        sortType.type === 'asc' ? 'desc' : sortType.type === 'desc' ? 'none' : 'asc';

      if (sortType.key !== sortingKey) {
        changedType = 'asc';
        setSortType({ key: sortingKey, type: 'asc' });
      }
      setSortType({ key: sortingKey, type: changedType });

      if (changedType === 'none') {
        if (filters) {
          filterTableData('-1', { id: 'all', value: '-1' });
        } else {
          setTableData(defaultData);
        }
        return;
      }
      const next = [
        ...tableData.sort((valueA, valueB) => {
          if (changedType === 'desc') {
            if (valueA[sortingKey] === undefined || valueA[sortingKey] === null) return 1;
            if (valueB[sortingKey] === undefined || valueB[sortingKey] === null) return -1;
            return valueA[sortingKey] < valueB[sortingKey] ? 1 : -1;
          }
          if (valueA[sortingKey] === undefined || valueA[sortingKey] === null) return 1;
          if (valueB[sortingKey] === undefined || valueB[sortingKey] === null) return -1;
          return valueA[sortingKey] > valueB[sortingKey] ? 1 : -1;
        }),
      ];
      // if (pagination) setPaginationData([...currentPosts(currentPage, postsPerPage, next)]);
      setTableData(next);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
      <TopContainer style={topContainerStyle}>
        {infoConfig && (
          <TableInfoContainer>
            {infoConfig.label1}{' '}
            <span>
              {infoConfig.value1.type
                ? infoConfig.value1.type === 'checked'
                  ? checkboxConfig?.externalChecklist?.length
                  : tableData.length
                : infoConfig.value1.text}
            </span>
            {infoConfig.unit1}
            {infoConfig.unit2 && (
              <>
                / {infoConfig.label2}{' '}
                <span>
                  {infoConfig.value2?.type
                    ? infoConfig.value2?.type === 'checked'
                      ? checkboxConfig?.externalChecklist?.length
                      : tableData.length
                    : infoConfig.value2?.text}
                </span>
                {infoConfig.unit2}
              </>
            )}
          </TableInfoContainer>
        )}
        {addOns ? addOns : ''}
      </TopContainer>
      <Container style={style?.tableContainer}>
        <Table style={style?.table} isStripe={isStripe}>
          <thead style={style?.thead}>
            {isCheckbox && (
              <Th style={{ width: '2.5rem', ...style?.th }} isStripe={isStripe}>
                <CheckBox
                  checked={
                    checkboxConfig?.externalChecklist &&
                    checkboxConfig?.externalChecklist.length === paginationData.length
                  }
                  onClick={() => checkTableItem(data, 'all')}
                />
              </Th>
            )}
            {columns.map((column) => {
              const filtered = filters
                ? filters.filter(
                    (filter) =>
                      filter.key === (column.filterKey ? column.filterKey : column.accessor),
                  )
                : [];
              return (
                <Th width={column.width ? column.width : 'auto'} style={style?.th}>
                  <div
                    style={{
                      width: column.width ? column.width : '100%',
                      display: 'flex',
                      justifyContent: column.align ? column.align : 'center',
                      alignItems: 'center',
                      gap: '5px',
                    }}>
                    {column.isSort && (
                      <SortButton onClick={() => sortTableItems(column.accessor, column.sortKey)}>
                        {sortType.type === 'none' ||
                        sortType.key !== (column.sortKey ? column.sortKey : column.accessor) ? (
                          <AscendingOrder width={20} height={20} fill="#aaa" />
                        ) : sortType.type === 'asc' ? (
                          <AscendingOrder width={20} height={20} fill={'#6c60f4'} />
                        ) : (
                          <DescendingOrder width={20} height={20} fill={'#6c60f4'} />
                        )}
                      </SortButton>
                    )}
                    {filters && filtered.length ? (
                      <div>
                        <TableFilterDropdown
                          setCurrentPage={setCurrentPage}
                          defaultData={defaultData}
                          width={filtered[0].width}
                          titleWidth={column.width}
                          title={column.Header as string}
                          filterList={filtered[0].filterList}
                          onClickDropdownItem={(filterValue: FilterListType | FilterListType[]) => {
                            filterTableData(
                              column.filterKey ? column.filterKey : column.accessor,
                              filterValue,
                            );
                          }}
                          multiselect={filtered[0].multiselect}
                        />
                      </div>
                    ) : (
                      <span>{column.Header}</span>
                    )}
                  </div>
                </Th>
              );
            })}
          </thead>
          <tbody style={style?.tbody}>
            {pagination
              ? paginationData.length > 0 &&
                paginationData.map((item, index) => (
                  <TableRow
                    rowItem={item}
                    checklist={checkboxConfig?.externalChecklist || []}
                    isCheckbox={isCheckbox}
                    checkboxConfig={checkboxConfig}
                    conditionalStyling={conditionalStyling}
                    style={style}
                    clickRowEvent={clickRowEvent}
                    onClickRow={onClickRow}
                    checkTableItem={checkTableItem}
                    format={format}
                    columns={columns}
                    isStripe={isStripe}
                    index={index}
                  />
                ))
              : tableData.length > 0 &&
                tableData.map((item, index) => (
                  <TableRow
                    rowItem={item}
                    checklist={checkboxConfig?.externalChecklist || []}
                    isCheckbox={isCheckbox}
                    checkboxConfig={checkboxConfig}
                    conditionalStyling={conditionalStyling}
                    style={style}
                    clickRowEvent={clickRowEvent}
                    onClickRow={onClickRow}
                    checkTableItem={checkTableItem}
                    format={format}
                    columns={columns}
                    isStripe={isStripe}
                    index={index}
                  />
                ))}
            {tableData.length === 0 && (
              <FlexCenterH
                style={{
                  minHeight: style?.table?.minHeight ? style.table.minHeight : '28rem',
                  maxHeight: style?.table?.maxHeight ? style.table.maxHeight : '40rem',
                  height: style?.table?.height ? style.table.height : '100%',
                  alignItems: 'center',
                }}>
                <NotFoundContainer text={notFoundText ? notFoundText : '조회된 내역이 없습니다.'} />
              </FlexCenterH>
            )}
          </tbody>
        </Table>
      </Container>
      {pagination && (
        <>
          {pagination && paginationConfig ? (
            <Pagination
              setCurrentPage={
                paginationConfig.setCurrentPage ? paginationConfig.setCurrentPage : setCurrentPage
              }
              currentPage={
                paginationConfig.currentPage ? paginationConfig.currentPage : currentPage
              }
              totalcount={
                paginationConfig.totalcount ? paginationConfig.totalcount : tableData.length
              }
              postsPerPage={
                paginationConfig.postsPerPage ? paginationConfig.postsPerPage : postsPerPage
              }
              fetchData={paginationConfig.fetchData}
            />
          ) : (
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalcount={tableData.length}
              postsPerPage={postsPerPage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default NewTableComponent;

type TableRowProps = {
  rowItem: any;
  checklist: any[];
  isCheckbox?: boolean;
  checkboxConfig?: any;
  conditionalStyling?: any;
  style?: TableStyleProps;
  clickRowEvent?: any;
  onClickRow: (item: any) => void;
  checkTableItem: (item: any) => void;
  format: (type: TableItem, value: any) => void;
  columns: ColumnType[];
  isStripe: 'odd' | 'even' | 'none';
  index: number;
};
const TableRow = ({
  columns,
  rowItem,
  isCheckbox,
  checklist,
  checkboxConfig,
  conditionalStyling,
  style,
  clickRowEvent,
  onClickRow,
  checkTableItem,
  format,
  isStripe = 'none',
  index,
}: TableRowProps) => {
  const conditionalRowStyle =
    conditionalStyling && conditionalStyling.tr
      ? conditionalStyling.tr.func(rowItem)
        ? conditionalStyling.tr.style
        : {}
      : {};

  return (
    <Tr
      isSelected={checklist?.some((checkedItem) =>
        checkboxConfig ? checkedItem[checkboxConfig.id] === rowItem[checkboxConfig.id] : false,
      )}
      style={{ ...conditionalRowStyle, ...style?.tr }}
      onClick={() => onClickRow(rowItem)}>
      {isCheckbox && checkboxConfig && (
        <Td style={{ width: '2.5rem', ...style?.th }} isStripe={isStripe}>
          <CheckBox
            checked={checklist?.some(
              (checkItem) => checkItem[checkboxConfig.id] === rowItem[checkboxConfig.id],
            )}
            onClick={() => checkTableItem(rowItem)}
          />
        </Td>
      )}
      {columns.map((column) => {
        let tdStyle: React.CSSProperties = {
          cursor: clickRowEvent ? 'pointer' : 'default',
          width: column.width,
        };
        if (conditionalStyling && conditionalStyling.td) {
          if (
            conditionalStyling.td.key === column.accessor &&
            conditionalStyling.td.func(rowItem)
          ) {
            tdStyle = {
              ...tdStyle,
              ...conditionalStyling.td.style,
            };
          }
        }
        return (
          <Td style={{ ...tdStyle, ...style?.td }} width={column.width} isStripe={isStripe}>
            {column.type && column.type === 'tooltip' ? (
              <DivInTd width={column.width} style={{ overflow: 'visible', ...style?.divInTd }}>
                <TooltipText width={column.width} text={rowItem[column.accessor]} />
              </DivInTd>
            ) : (
              <DivInTd width={column.width} style={{ ...style?.divInTd }}>
                {column.type
                  ? format(column.type, rowItem[column.accessor])
                  : rowItem[column.accessor] || '-'}
              </DivInTd>
            )}
          </Td>
        );
      })}
    </Tr>
  );
};
