import moment from 'moment';
import Swal from 'sweetalert2';

const SWAL_DEFAULT_TIMER = 3500;

export const currentPosts = (currentPage: number, postsPerPage: number, tmp: any) => {
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  let currentPosts = 0;
  currentPosts = tmp.slice(indexOfFirst, indexOfLast);
  return currentPosts;
};

export const secondsToDayHourFormat = (seconds: number) => {
  // 1일, 1시간, 1분을 초 단위로 계산
  const oneDayInSeconds = 24 * 60 * 60;
  const oneHourInSeconds = 60 * 60;
  const oneMinuteInSeconds = 60;

  // 일, 시간, 분, 초 계산
  const days = Math.floor(seconds / oneDayInSeconds);
  seconds %= oneDayInSeconds;

  const hours = Math.floor(seconds / oneHourInSeconds);
  seconds %= oneHourInSeconds;

  const minutes = Math.floor(seconds / oneMinuteInSeconds);
  seconds %= oneMinuteInSeconds;

  const secs = seconds;

  // 남은 시간이 1일 이상이면 '일' 단위 포함, 그렇지 않으면 '일' 단위 제외
  if (days > 0)
    return `${days}일 ${String(hours).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0',
    )}:${String(secs).padStart(2, '0')}`;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
    secs,
  ).padStart(2, '0')}`;
};

export const secondsToHHmmss = (seconds: string | number, withDays?: boolean) => {
  const parsedSeconds = typeof seconds === 'string' ? parseInt(seconds) : seconds;
  const calculatedHours = withDays ? (parsedSeconds / 3600) % 24 : parsedSeconds / 3600;
  const calculatedMinutes = parsedSeconds % 3600;
  const calculatedSeconds = parsedSeconds % 60;
  if (parsedSeconds >= 0) {
    const hour =
      calculatedHours < 10 ? '0' + Math.floor(calculatedHours) : Math.floor(calculatedHours);
    const min =
      calculatedMinutes / 60 < 10
        ? '0' + Math.floor(calculatedMinutes / 60)
        : Math.floor(calculatedMinutes / 60);
    const sec =
      calculatedSeconds < 10 ? '0' + Math.floor(calculatedSeconds) : Math.floor(calculatedSeconds);
    return hour + ':' + min + ':' + sec;
  } else {
    const hour =
      Math.abs(calculatedHours) < 10
        ? '0' + Math.floor(calculatedHours)
        : Math.floor(calculatedHours);
    const min =
      calculatedMinutes / 60 < 10 && calculatedMinutes / 60 > 0
        ? '0' + Math.floor(calculatedMinutes / 60)
        : Math.floor(calculatedMinutes / 60);
    const sec =
      calculatedSeconds < 10 && calculatedMinutes / 60 > 0
        ? '0' + Math.floor(calculatedSeconds)
        : Math.floor(calculatedSeconds);
    return `${hour}:${Math.abs(typeof min === 'string' ? parseInt(min) : min)}:${Math.abs(
      typeof sec === 'string' ? parseInt(sec) : sec,
    )} 초과`;
  }
};

export const diffTimeToRest = (t2: number) => {
  const day = moment.duration(moment(new Date()).diff(t2)).days();
  const hours =
    day > 0
      ? day * 24 + moment.duration(moment(new Date()).diff(t2)).hours()
      : moment.duration(moment(new Date()).diff(t2)).hours();
  const minutes = moment.duration(moment(new Date()).diff(t2)).minutes();
  const seconds = moment.duration(moment(new Date()).diff(t2)).seconds();
  return (
    (hours > 0 && hours < 10 ? '0' + hours : hours) +
    ' : ' +
    (minutes > -1 && minutes < 10 ? '0' + minutes : minutes) +
    ' : ' +
    (seconds > -1 && seconds < 10 ? '0' + seconds : seconds)
  );
};

export const diffTime1 = (lastInData: string, remainTime: number) => {
  const nowTime = moment();
  const lastIn = moment(lastInData);
  const remainTimeData = moment.duration(nowTime.diff(lastIn)).asSeconds();
  return remainTime - Math.ceil(remainTimeData);
};

export const differentialOfDate = (startDate: string | Date, endDate: string) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const remainTimeData = moment.duration(end.diff(start)).asDays();

  return Math.floor(remainTimeData);
};

export const swalReturnWarning = (text: string) =>
  Swal.fire({
    icon: 'warning',
    timer: SWAL_DEFAULT_TIMER,
    html: typeof text === 'string' ? text.replaceAll('\n', '<br />') : text,
    confirmButtonText: '확인',
  });

export const maskPhoneNumber = (phoneNumber: string) => {
  const splitted = phoneNumber.split('-');
  if (splitted.length === 2)
    return `${splitted[0].substring(0, splitted[0].length - 2)}**-${splitted[1]}`;
  return `${splitted[0]}-****-${splitted[2]}`;
};

export const checkIfCurrentFloor = (seat: { floor: number | null }, currentFloor?: number) => {
  if (!currentFloor) return true;
  if (!seat.floor) return currentFloor === 1;
  return seat.floor === currentFloor;
};
