import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import configDB from '../data/customizer/config';
import { getPayAllList, merchantId_api } from '../lib/api';
import { ko } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import styled from 'styled-components';
import { numberWithCommas } from '../components/common';
import SelectDate from '../components/selectDate';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || configDB.data.color.secondary_color;

const CardContainer = styled(Container)`
  .date-pickers {
    display: flex;
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vw;
  }
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1vw;

    .m-0 {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      input {
        width: 100%;
      }
    }
    input {
      width: 80% !important;
    }
  }

  .payType {
    display: flex;
    justify-content: space-around;
    .payTypeList {
      width: 30%;

      .semi-content {
        width: 88%;
        justify-content: space-between;
        display: flex;
      }
    }
  }

  @media screen and (max-width: 784px) {
    /* padding: 0 !important; */
    .payType {
      flex-direction: column;
      width: 76%;
      justify-content: center;
      margin: auto;
      .payTypeList {
        width: 100% !important;
        padding: 2vw;
      }
    }
    .payType {
      display: flex;
      justify-content: center;
      .payTypeList {
        .semi-content {
          width: 100%;
        }
      }
    }
  }
`;

export const DatePickerStyle = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  margin: 0 10px;
  h5 {
    margin: 0 8px;
    font-weight: 400;
  }
  > div > div > button {
    width: 100%;
  }
  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 9px 12px;
    font-size: 14px;
    margin-left: 10px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 5px 0 0;
    > div > div > button {
      padding: 6px 10px;
      font-size: 13px;
      > svg {
        width: 17px;
      }
    }
    select {
      padding: 9px 5px;
    }
  }
`;

let merchantId = '';

const SalesChart = ({ paddingStyle }) => {
  const [salesList, setSalesList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    getMerchantId();
  }, []);

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      merchantId = data.id;
      getPaylogAllListApi(data.id);
    }
  };

  const getPaylogAllListApi = async (merchantId) => {
    if (merchantId) {
      const { data } = await getPayAllList(
        merchantId,
        moment(startDate).format('YYYY-MM-DD 00:00:00'),
        moment(endDate).format('YYYY-MM-DD 23:59:59'),
      );

      if (data.length > 0) {
        setSalesList(data);
      }
    }
  };

  let credit = 0;
  let kakaopay = 0;
  let cash = 0;
  let virtual = 0;
  let webPayment = 0;
  let creditPrice = 0;
  let kakaopayPrice = 0;
  let cashPrice = 0;
  let virtualPrice = 0;
  let webPaymentPrice = 0;
  let credit_cancel = 0;
  let kakaopay_cancel = 0;
  let cash_cancel = 0;
  let virtual_cancel = 0;
  let webPayment_cancel = 0;
  let creditPrice_cancel = 0;
  let kakaopayPrice_cancel = 0;
  let cashPrice_cancel = 0;
  let virtualPrice_cancel = 0;
  let webPaymentPrice_cancel = 0;

  for (let i = 0; i < salesList.length; i++) {
    if (
      salesList[i].paymentType === 'credit' ||
      salesList[i].paymentType.paymentType === 'applepay'
    ) {
      credit += 1;
      creditPrice += salesList[i].amount;
    } else if (
      salesList[i].paymentType === 'credit-cancel' ||
      salesList[i].paymentType.paymentType === 'applepay-cancel'
    ) {
      credit_cancel += 1;
      creditPrice_cancel += salesList[i].amount;
    } else if (salesList[i].paymentType === 'cash') {
      cash += 1;
      cashPrice += salesList[i].amount;
    } else if (salesList[i].paymentType === 'cash-cancel') {
      cash_cancel += 1;
      cashPrice_cancel += salesList[i].amount;
    } else if (
      salesList[i].paymentType === 'kakaopay' ||
      salesList[i].paymentType === 'naverpay' ||
      salesList[i].paymentType === 'payco'
    ) {
      kakaopay += 1;
      kakaopayPrice += salesList[i].amount;
    } else if (
      salesList[i].paymentType === 'kakaopay-cancel' ||
      salesList[i].paymentType === 'naverpay-cancel' ||
      salesList[i].paymentType === 'payco-cancel'
    ) {
      kakaopay_cancel += 1;
      kakaopayPrice_cancel += salesList[i].amount;
    } else if (salesList[i].paymentType === 'virtual') {
      virtual += 1;
      virtualPrice += salesList[i].amount;
    } else if (salesList[i].paymentType === 'virtual-cancel') {
      virtual_cancel += 1;
      virtualPrice_cancel += salesList[i].amount;
    } else if (salesList[i].paymentType === 'toss' || salesList[i].paymentType === 'hecto') {
      webPayment += 1;
      webPaymentPrice += salesList[i].amount;
    } else if (
      salesList[i].paymentType === 'toss-cancel' ||
      salesList[i].paymentType === 'hecto-cancel'
    ) {
      webPayment_cancel += 1;
      webPaymentPrice_cancel += salesList[i].amount;
    } else {
    }
  }

  const totalPayDataCount = +credit + +cash + +kakaopay + +virtual + +webPayment;
  const totalPayCancelCount =
    +credit_cancel + +cash_cancel + +kakaopay_cancel + +virtual_cancel + +webPayment_cancel;
  const totalPayDataPrice =
    +creditPrice + +cashPrice + +kakaopayPrice + +virtualPrice + +webPaymentPrice;
  const totalPayCancelDataPrice =
    +creditPrice_cancel +
    +cashPrice_cancel +
    +kakaopayPrice_cancel +
    +virtualPrice_cancel +
    +webPaymentPrice_cancel;
  const credit1 =
    credit > 0
      ? Math.round(((credit - credit_cancel) / (totalPayDataCount - totalPayCancelCount)) * 100)
      : 0;
  const cash1 =
    cash > 0
      ? Math.round(((cash - cash_cancel) / (totalPayDataCount - totalPayCancelCount)) * 100)
      : 0;
  const kakaopay1 =
    kakaopay > 0
      ? Math.round(((kakaopay - kakaopay_cancel) / (totalPayDataCount - totalPayCancelCount)) * 100)
      : 0;
  const virtual1 =
    virtual > 0
      ? Math.round(((virtual - virtual_cancel) / (totalPayDataCount - totalPayCancelCount)) * 100)
      : 0;
  const webPayment1 =
    webPayment > 0
      ? Math.round(
          ((webPayment - webPayment_cancel) / (totalPayDataCount - totalPayCancelCount)) * 100,
        )
      : 0;

  // sale chart

  const apexRadialBarChart = {
    series: [credit, cash, kakaopay, virtual, webPayment],
    options: {
      chart: {
        height: 350,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: '22px',
            },
            value: {
              show: true,
              fontSize: '16px',
              formatter: function (val) {
                return val + '회';
              },
            },
            total: {
              show: true,
              label: '결제 총 횟수',
              formatter: function (w) {
                return (
                  w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0) + '회'
                );
              },
            },
          },
        },
      },
      labels: ['카드', '현금', '간편결제', '가상결제', '웹결제'],
      colors: [primary, secondary, '#51bb25', '#F5D368', '#0A8FFF'],
    },
  };

  useEffect(() => {
    getPaylogAllListApi(merchantId);
  }, [startDate, endDate]);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="btn-primary btn-sm" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
    <Fragment>
      <CardContainer fluid={true} style={{ padding: paddingStyle === 'none' ? 0 : '' }}>
        <Card>
          <CardHeader>입장권 유형별 매출 현황</CardHeader>
          <CardBody>
            <div className="topbar">
              <DatePickerStyle>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  selectsStart
                  locale={ko}
                  startDate={startDate}
                  maxDate={endDate}
                  endDate={endDate}
                  customInput={<ExampleCustomInput />}
                />
                <h5 style={{ margin: '0 8px' }}> ~ </h5>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  locale={ko}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  customInput={<ExampleCustomInput />}
                />
                <SelectDate
                  setStartDate={(val) => setStartDate(val)}
                  setEndDate={(val) => setEndDate(val)}
                />
              </DatePickerStyle>
            </div>
            <p>
              기간 매출 총액 : {numberWithCommas(totalPayDataPrice - totalPayCancelDataPrice)} 원
            </p>
            <div id="circlechart">
              <Chart
                options={apexRadialBarChart.options}
                series={apexRadialBarChart.series}
                type="radialBar"
                height={320}
              />
            </div>
            <div>
              <div className="payType">
                <div className="payTypeList">
                  <div className="semi-content">
                    <p>카드</p>
                    <p>{credit1}%</p>
                  </div>
                  <div className="semi-content">
                    <p>결제횟수</p> <p>{credit - credit_cancel} 회</p>
                  </div>
                  <div className="semi-content"></div>
                  <div className="semi-content">
                    <p>금액</p> <p>{numberWithCommas(creditPrice - creditPrice_cancel)} 원</p>
                  </div>
                </div>
                <div className="payTypeList">
                  <div className="semi-content">
                    <p>현금</p>
                    <p>{cash1}%</p>
                  </div>
                  <div className="semi-content">
                    <p>결제횟수</p> <p>{cash - cash_cancel} 회</p>
                  </div>
                  <div className="semi-content">
                    <p>금액</p> <p>{numberWithCommas(cashPrice - cashPrice_cancel)} 원</p>
                  </div>
                </div>
                <div className="payTypeList">
                  <div className="semi-content">
                    <p>간편결제</p>
                    <p>{kakaopay1} %</p>
                  </div>
                  <div className="semi-content">
                    <p>결제횟수</p> <p>{kakaopay - kakaopay_cancel} 회</p>
                  </div>
                  <div className="semi-content">
                    <p>금액</p> <p>{numberWithCommas(kakaopayPrice - kakaopayPrice_cancel)} 원</p>
                  </div>
                </div>
                <div className="payTypeList">
                  <div className="semi-content">
                    <p>가상결제</p>
                    <p>{virtual1} %</p>
                  </div>
                  <div className="semi-content">
                    <p>결제횟수</p> <p>{virtual - virtual_cancel} 회</p>
                  </div>
                  <div className="semi-content">
                    <p>금액</p> <p>{numberWithCommas(virtualPrice - virtualPrice_cancel)} 원</p>
                  </div>
                </div>
                <div className="payTypeList">
                  <div className="semi-content">
                    <p>웹결제</p>
                    <p>{webPayment1} %</p>
                  </div>
                  <div className="semi-content">
                    <p>결제횟수</p> <p>{webPayment - webPayment_cancel} 회</p>
                  </div>
                  <div className="semi-content">
                    <p>금액</p>{' '}
                    <p>{numberWithCommas(webPaymentPrice - webPaymentPrice_cancel)} 원</p>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </CardContainer>
    </Fragment>
  );
};
export default SalesChart;
