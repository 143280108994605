import React, { useEffect } from 'react';
import { getUserData, getWebPayRequest, merchantId_api, msgPointWebpay } from '../../lib/api';
import Breadcrumb from '../../layout/breadcrumb';
import { Card, CardHeader, Col, Row } from 'reactstrap';
import Swal from 'sweetalert2';

import { DashboardCard, SolidBtn } from '../../components/commonStyle';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Success = () => {
  const url1 = window.location.href;
  const url = new URL(url1);
  console.log(url1);

  const handleSuccess = () => {
    Swal.fire({
      icon: 'success',
      title: '결제가 완료됐습니다.',
      text: '자동으로 결제내역 페이지로 이동됩니다.',
      timer: 2500,
      showCancelButton: false,
      showConfirmButton: false,
    }).then(() => {
      window.location.href = `${process.env.PUBLIC_URL}/user/payment-info`;
    });
  };

  useEffect(() => {
    handleSuccess();
  }, []);
  return (
    <>
      <Row>
        <Col sm="12" style={{ paddingLeft: 5, paddingRight: 5 }}>
          <DashboardCard>
            <Container>
              <p>결제가 완료되었습니다.</p>
            </Container>
          </DashboardCard>
        </Col>
      </Row>
    </>
  );
};
export default Success;
