import React from 'react';
import styled from 'styled-components';
import Delete from '../../assets/images/icon_image_delete.svg';

import TextareaAutosize from 'react-textarea-autosize';

interface IProps {
  image: string;
  message: string;
  placeholder?: string;
  height?: number;
  handleDelete?: () => void;
}

const Content = styled.div<{ height?: number }>`
  height: ${({ height }) => (height ? `calc(${height}rem)` : 'calc(14.688rem)')};
  overflow-y: scroll;

  .message-image {
    padding: 1rem;
    max-width: 100%;
  }

  textarea {
    resize: none;
    padding: 1rem;
    font-size: 1rem;
    font-family: Pretendard;
    background: transparent;
    width: 100%;
    border: none;

    &:focus {
      outline: none;
    }
  }
`;

const DeleteButton = styled.button`
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.25rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const MessageViewer = ({ image, message, height, handleDelete }: IProps) => {
  return (
    <Content height={height}>
      {image && (
        <div style={{ position: 'relative' }}>
          <DeleteButton onClick={handleDelete}>
            <img src={Delete} alt="delete-button" />
          </DeleteButton>
          <img className="message-image" src={image} alt="message-image-preview" />
        </div>
      )}
      <TextareaAutosize value={message} />
    </Content>
  );
};

export default MessageViewer;
