import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumbs from '../layout/breadcrumb';
import { Container, Row, Col, Card, CardBody, Input, CardFooter } from 'reactstrap';
import { getTicketList, merchantId_api, ticketDelete, ticketUpdate } from '../lib/api';
import { InputS, InputWithP, LabelS } from './ticketList';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import TicketsInsertModal from '../components/modal/ticketsInsertModal';
import LockersInsertModal from '../components/modal/lockersInsertModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TabButton, TabList } from './seatList';
import { AddBtn } from './lockerChange';
import { LabelRow } from './ticketCustom';
import PageContainer from '../components/common/PageContainer';
import { Button } from '../components/common/Button';

import IconAddTicket from '../assets/images/button/icon_button_ticket_inverted.svg';
import IconAddLocker from '../assets/images/button/icon_button_add_locker_inverted.svg';
import { Dropdown } from '../components/common/Dropdown';

const TabList1 = styled(TabList)`
  display: flex;
  @media screen and (max-width: 480px) {
    > div {
      width: 100%;
    }
    flex-direction: column-reverse;
    padding: 0 10px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

const TicketTable = styled.table`
  input,
  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 14px;
  }
  /* select {
    width: 50%;
  } */
  // width: fit-content;
  width: 100%;
  margin: 0 auto;
  white-space: nowrap;
  p {
    text-align: center;
  }
  tr {
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    > td {
      > p {
        color: #334d6e;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  td {
    width: 10rem;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  td:nth-child(1) {
    // 이름
    width: 10rem;
  }
  td:nth-child(2), // 타입 , 좌석
	td:nth-child(3) {
    width: 5rem;
  }
  td:nth-child(4) {
    // 설명
    width: 10rem;
  }

  td:nth-child(5) {
    // 할인률
    width: 3rem;
  }
  td:nth-child(6) {
    // 가격
    width: 5rem;
    input {
      text-align: end;
    }
  }
  td:nth-child(7) {
    // 최종 가격
    width: 8rem;
  }

  td:nth-child(8) {
    // 등급
    width: 3rem;
    input {
      width: 3rem;
      text-align: center;
    }
  }
  td:nth-child(9) {
    // 유효기간
    width: 5rem;
    input {
      width: 4rem;
    }
  }
  td:nth-child(10) {
    // 부여시간
    input {
      width: 3rem;
      margin: 0 5px;
    }
  }
  td:nth-child(11) {
    // 제한
    width: 10rem;
    label {
      margin: 0;
      h5 {
        margin-right: 5px;
      }
    }
    input {
      width: 15px;
      margin: 0 5px 0 0;
    }
  }
  @media screen and (max-width: 768px) {
    td {
      width: 100px;
    }
    td:nth-child(1) {
      width: 150px;
    }
    td:nth-child(2),
    td:nth-child(3) {
      width: 78px;
    }
    td:nth-child(4) {
      width: 250px;
    }
    td:nth-child(5),
    td:nth-child(8) {
      width: 50px;
    }
    td:nth-child(6) {
      width: 90px;
    }
    td:nth-child(9) {
      input {
        width: 70px;
      }
    }
    td:nth-child(10) {
      width: 160px;
      input {
        width: 50%;
        margin: 0 3px;
      }
    }
    td:nth-child(11) {
      width: 200px;
      label {
        margin: 0;
        h5 {
          margin-right: 3px;
        }
      }
      input {
        width: 15px;
        margin: 0 3px 0 0;
      }
    }

    tr {
      > td {
        > p {
          font-size: 13px;
        }
      }
    }
  }
`;

const TableDiv = styled.div`
  width: 100%;
  overflow: auto;
  padding-bottom: 20px;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c4cbd6;
    border-radius: 1vw;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 1vw;
  }
`;

const LockerTable = styled.div`
  /* width: fit-content; */
  width: 100%;
  margin: 0 auto;
  white-space: nowrap;

  input,
  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 14px;
  }
  p {
    text-align: center;
  }
  tr {
    width: 100%;

    border-bottom: 1px solid #e1e1e1;
    display: flex;
    > td {
      > p {
        color: #334d6e;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  td {
    width: 15%;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  td:nth-child(1) {
    // 이름
    width: 8rem;
  }
  td:nth-child(2) {
    // 설명
    width: 13rem;
  }
  td:nth-child(3),
  td:nth-child(6) {
    // 할인률, 기간
    width: 5rem;
  }
  td:nth-child(4) {
    // 가격
    width: 8rem;
    input {
      text-align: end;
    }
  }
  td:nth-child(5) {
    // 최종가격
    width: 8rem;
  }
  td:nth-child(7) {
    // 제한
    width: 8rem;
    label {
      margin: 0;
    }
    input {
      width: 20px;
      margin: 0 5px 0 0;
    }
  }
  @media screen and (max-width: 480px) {
    td {
      width: 100px;
    }
    td:nth-child(1) {
      width: 100px;
    }
    td:nth-child(2) {
      width: 250px;
    }
    td:nth-child(3),
    td:nth-child(6) {
      width: 70px;
    }
    td:nth-child(4) {
      width: 90px;
    }
    td:nth-child(5) {
      width: 90px;
    }
    td:nth-child(7) {
      width: 190px;
    }

    tr {
      > td {
        > p {
          font-size: 13px;
        }
      }
    }
  }
`;

const ProductList = ({ setIsLoading }) => {
  //   const userInfo = useRecoilValue(userInfoState);
  //티켓(이용권)
  const [ticketlist, setTicketList] = useState([{ T_min: '', T_hour: '' }]);
  //사물함(locker)
  const [lockerlist, setLockerList] = useState([]);
  //고정되는 데이터

  const [tabData, setTabData] = useState(1);
  const [isTicketInsertModal, setIsTicketInsertModal] = useState(false);
  const [isLockerInsertModal, setIsLockerInsertModal] = useState(false);

  const discountRate = (price, percent) => {
    const priceData = price - (percent / 100) * price - ((price - (percent / 100) * price) % 10);
    return priceData;
  };

  const getMerchantId = async () => {
    setIsLoading(true);
    const data = await merchantId_api();

    if (data.status === 200) {
      const tickets = await getTicketList(data.data.id);
      if (tickets.status === 200) {
        setLockerList(
          tickets.data
            .filter((item) => item.T_type === 5)
            .sort(function (a, b) {
              return a.T_sort < b.T_sort ? -1 : a.T_sort > b.T_sort ? 1 : 0;
            }),
        );
        setTicketList(
          tickets.data
            .map((item) =>
              item.T_type === 1 || item.T_type === 2 || item.T_type === 3
                ? {
                    ...item,
                    T_hour: Math.floor(item.T_time / 3600),
                    T_min: (+item.T_time / 60) % 60,
                    totalPrice: discountRate(item.T_price, item.T_discount), // (item.T_price * (100 - item.T_discount)) / 100,
                  }
                : {
                    ...item,
                    totalPrice: discountRate(item.T_price, item.T_discount), // (item.T_price * (100 - item.T_discount)) / 100,
                  },
            )
            .filter((item) => +item.T_type === tabData)
            .sort(function (a, b) {
              return a.T_sort < b.T_sort ? -1 : a.T_sort > b.T_sort ? 1 : 0;
            }),
        );
      }
    }
    setIsLoading(false);
  };

  const handleChange = (e, i) => {
    let updateData;
    const copyData = [...ticketlist];
    if (e.target.name === 'T_type' && e.target.value === '1') {
      updateData = copyData.map((item) =>
        item.id === ticketlist[i].id
          ? {
              ...item,
              [e.target.name]: +e.target.value,
              T_day: 0,
              T_expireDate: 0,
              seatType: 0,
            }
          : item,
      );
    } else if (e.target.name === 'T_type' && e.target.value === '2') {
      updateData = copyData.map((item) =>
        item.id === ticketlist[i].id
          ? {
              ...item,
              [e.target.name]: +e.target.value,
              T_time: 0,
            }
          : item,
      );
    } else if (e.target.name === 'T_type' && e.target.value === '3') {
      updateData = copyData.map((item) =>
        item.id === ticketlist[i].id
          ? {
              ...item,
              [e.target.name]: +e.target.value,
              T_time: 0,
            }
          : item,
      );
    } else if (e.target.name === 'T_description' || e.target.name === 'T_name') {
      updateData = copyData.map((item) =>
        item.id === ticketlist[i].id
          ? {
              ...item,
              [e.target.name]: e.target.value,
            }
          : item,
      );
    } else if (e.target.name === 'seatType' && e.target.value === 0) {
      updateData = copyData.map((item) =>
        item.id === ticketlist[i].id
          ? {
              ...item,
              [e.target.name]: +e.target.value,
              T_type: 2,
            }
          : item,
      );
    } else if (e.target.name === 'count' && +e.target.value === -1) {
      updateData = copyData.map((item) =>
        item.id === ticketlist[i].id
          ? {
              ...item,
              T_count: 1,
            }
          : item,
      );
    } else if (e.target.name === 'count' && +e.target.value !== -1) {
      updateData = copyData.map((item) =>
        item.id === ticketlist[i].id
          ? {
              ...item,
              T_count: -1,
            }
          : item,
      );
    } else if (e.target.name === 'T_price') {
      updateData = copyData.map((item) =>
        item.id === ticketlist[i].id
          ? {
              ...item,
              [e.target.name]: +e.target.value,
              totalPrice: discountRate(+e.target.value, item.T_discount), // (+e.target.value * (100 - item.T_discount)) / 100,
            }
          : item,
      );
    } else if (e.target.name === 'T_discount') {
      updateData = copyData.map((item) =>
        item.id === ticketlist[i].id
          ? {
              ...item,
              [e.target.name]: +e.target.value,
              totalPrice: discountRate(+item.T_price, +e.target.value), // (+item.T_price * (100 - +e.target.value)) / 100,
            }
          : item,
      );
    } else if (e.target.name === 'T_level') {
      updateData = copyData.map((item) =>
        item.id === ticketlist[i].id
          ? {
              ...item,
              [e.target.name]: +e.target.value > 4 ? 4 : +e.target.value,
            }
          : item,
      );
    } else {
      updateData = copyData.map((item) =>
        item.id === ticketlist[i].id
          ? {
              ...item,
              [e.target.name]: +e.target.value,
            }
          : item,
      );
    }

    setTicketList(updateData);
    setLockerList(updateData.filter((item) => item.T_type == 5));
  };

  let doubleSubmitFlag = false;

  function doubleSubmitCheck() {
    if (doubleSubmitFlag) {
      return doubleSubmitFlag;
    } else {
      doubleSubmitFlag = true;
      return false;
    }
  }
  const ticketDataChange = async () => {
    if (doubleSubmitCheck()) return;

    let copyData = [...ticketlist];
    let check500 = copyData.some(
      (item) =>
        item.T_price % 500 != 0 || ((item.T_price * (100 - item.T_discount)) / 100) % 500 != 0,
    );

    if (false) {
      // 500원 체크 삭제 2022-05-01 ko
      Swal.fire({
        icon: 'warming',
        title: '최종가격은 500원단위(ex>500,1000)가 되도록 수정해주세요',
        timer: 2000,
      });
    } else {
      let changeData = copyData.map((item, i) =>
        item.T_hour || item.T_min
          ? {
              ...item,
              T_time: item.T_hour * 3600 + item.T_min * 60,
            }
          : item,
      );

      if (changeData.length !== 0) {
        const data = await ticketUpdate(changeData);

        if (data.status === 200) {
          Swal.fire('상품수정이 완료되었습니다.').then(() => {
            doubleSubmitFlag = false;
            window.location.reload();
          });
        } else {
          Swal.fire('에러가 발생하였습니다. 다시 시도해 주세요').then(() => {
            doubleSubmitFlag = false;
            window.location.reload();
          });
        }
      } else {
        Swal.fire('상품수정한 데이터가 없습니다.').then(() => {
          doubleSubmitFlag = false;
        });
      }
    }
  };

  const deleteTicket = async (e) => {
    Swal.fire({
      icon: 'warning',
      title: '삭제하면 복구하실 수 없습니다.',
      text: '그래도 지우시겠습니까?',
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: '#04c9db',
      confirmButtonText: '예',
      cancelButtonText: '아니오',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resultData = await ticketDelete(e.id, e.merchantId, e.T_type);

        if (resultData.status === 200) {
          getMerchantId();
          Swal.fire('삭제가 완료되었습니다.');
        }
      }
    });
  };

  useEffect(() => {
    getMerchantId();
  }, [tabData]);

  const timeChange = (e, i) => {
    let updateData;
    let copyData = [...ticketlist];

    updateData = copyData.map((item) =>
      item.id === ticketlist[i].id
        ? {
            ...item,
            [e.target.name]: e.target.value,
          }
        : item,
    );
    setTicketList(updateData);
  };

  const handleModal = () => {
    setIsTicketInsertModal(!isTicketInsertModal);
  };

  const handleLockerModal = () => {
    setIsLockerInsertModal(!isLockerInsertModal);
  };

  const dragAndDrop = async (result) => {
    // let items;
    // if(result.id ==="locker")
    try {
      const items = Array.from(ticketlist);
      let [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      const data = await ticketUpdate(items);
      if (data.status === 200) {
        getMerchantId();
      } else {
        Swal.fire({
          icon: 'error',
          title: '순서를 변경 중 오류가 발생했습니다.',
          timer: 2000,
        }).then((reorderedItem = []));
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '순서를 변경 중 오류가 발생했습니다.',
        timer: 2000,
      });
    }
  };

  return (
    <>
      {isTicketInsertModal && (
        <TicketsInsertModal
          isOpen={isTicketInsertModal}
          onClose={handleModal}
          setData={setTicketList}
          setReload={getMerchantId}
        />
      )}
      {isLockerInsertModal && (
        <LockersInsertModal
          isOpen={isLockerInsertModal}
          onClose={handleLockerModal}
          setData={setLockerList}
          setReload={getMerchantId}
        />
      )}
      <Fragment>
        <PageContainer
          title="상품 목록"
          itemsRightSideTitle={[
            <Button icon={IconAddTicket} onClick={() => handleModal()} buttonType="default">
              이용권 추가
            </Button>,
            <Button icon={IconAddLocker} onClick={() => handleLockerModal()} buttonType="default">
              사물함 추가
            </Button>,
          ]}>
          <TabList1>
            <div>
              <TabButton selected={tabData === 1} onClick={() => setTabData(1)}>
                일회권
              </TabButton>
              <TabButton selected={tabData === 2} onClick={() => setTabData(2)}>
                기간권
              </TabButton>
              <TabButton
                style={{ width: '7rem' }}
                selected={tabData === 3}
                onClick={() => setTabData(3)}>
                시간정액권
              </TabButton>
              <TabButton selected={tabData === 5} onClick={() => setTabData(5)}>
                사물함
              </TabButton>
            </div>
          </TabList1>
          {ticketlist.length > 0 ? (
            <>
              {tabData === 1 || tabData === 2 || tabData === 3 ? (
                <>
                  <TableDiv>
                    <TicketTable>
                      <thead>
                        <tr>
                          <td>
                            <p>이름</p>
                          </td>
                          <td>
                            <p>타입</p>
                          </td>
                          <td>
                            <p>좌석</p>
                          </td>
                          <td>
                            <p>설명</p>
                          </td>
                          <td>
                            <p>할인률</p>
                          </td>
                          <td>
                            <p>가격(₩)</p>
                          </td>
                          <td>
                            <p>최종가격(₩)</p>
                          </td>
                          <td>
                            <p>등급</p>
                          </td>
                          <td>
                            <p>유효기간</p>
                          </td>
                          <td>
                            <p>부여시간</p>
                          </td>
                          <td>
                            <p>제한</p>
                          </td>
                          <td>
                            <p>제거</p>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <DragDropContext onDragEnd={dragAndDrop}>
                          <Droppable droppableId="tickets">
                            {(provided) => (
                              <div
                                className="tickets"
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {ticketlist?.map((item, index) => (
                                  <Draggable
                                    key={item.T_sort + index}
                                    draggableId={`${index + 1}`}
                                    index={index}>
                                    {(provided) => (
                                      <tr
                                        key={'T' + item.T_sort}
                                        ref={provided.innerRef}
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}>
                                        <td>
                                          <InputS
                                            type="text"
                                            name="T_name"
                                            value={item.T_name || ''}
                                            onChange={(e) => handleChange(e, index)}
                                          />
                                        </td>
                                        <td>
                                          {item.T_type === 1
                                            ? '일회권'
                                            : item.T_type === 2
                                            ? '기간권'
                                            : item.T_type === 3
                                            ? '시간정액권'
                                            : item.T_type === 4
                                            ? '세미나'
                                            : '사물함'}
                                        </td>
                                        <td>
                                          <Dropdown>
                                            <select
                                              name="seatType"
                                              value={item.seatType}
                                              onChange={(e) => handleChange(e, index)}>
                                              <option value={+0}>자유석</option>
                                              <option
                                                value={+1}
                                                disabled={item.T_type === 1 || item.T_type === 3}>
                                                고정석
                                              </option>
                                            </select>
                                          </Dropdown>
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            name="T_description"
                                            value={item.T_description || ''}
                                            onChange={(e) => handleChange(e, index)}
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            name="T_discount"
                                            min="0"
                                            value={item.T_discount || ''}
                                            onChange={(e) => handleChange(e, index)}
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            name="T_price"
                                            min="0"
                                            value={item.T_price || ''}
                                            onChange={(e) => handleChange(e, index)}
                                          />
                                        </td>

                                        <td>
                                          <p
                                            style={{
                                              fontWeight: 500,
                                              fontSize: 13,
                                              color: 'inherit',
                                            }}>
                                            {item?.totalPrice
                                              ?.toString()
                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            원
                                          </p>
                                        </td>

                                        <td>
                                          <Input
                                            type="text"
                                            name="T_level"
                                            value={item.T_level || ''}
                                            onChange={(e) => handleChange(e, index)}
                                          />
                                        </td>
                                        <td>
                                          {item?.T_type === 3 ? (
                                            <InputWithP>
                                              <Input
                                                type="text"
                                                name="T_expireDate"
                                                value={item.T_expireDate || ''}
                                                onChange={(e) => handleChange(e, index)}
                                                disabled={!(item?.T_type === 3)}
                                              />
                                              <p>일</p>
                                            </InputWithP>
                                          ) : (
                                            '-'
                                          )}
                                        </td>
                                        <td>
                                          {(item.T_type === 1 ||
                                            item.T_type === 3 ||
                                            item.T_type === 5) && (
                                            <InputWithP>
                                              <Input
                                                type="text"
                                                // min="0"
                                                name="T_hour"
                                                value={
                                                  item.T_time < 3599 && item.T_hour === 0
                                                    ? 0
                                                    : Math.floor(item?.T_hour)
                                                }
                                                onChange={(e) => timeChange(e, index)}
                                              />
                                              <p>시간</p>
                                            </InputWithP>
                                          )}

                                          <InputWithP>
                                            <Input
                                              type="text"
                                              // min="0"
                                              style={{ width: '40px' }}
                                              name={item.T_type === 2 ? 'T_day' : 'T_min'}
                                              value={
                                                item.T_type === 2
                                                  ? item?.T_day
                                                  : Math.floor(item?.T_min)
                                              }
                                              onChange={(e) => timeChange(e, index)}
                                            />
                                            <p>{item.T_type === 2 ? '일' : '분'}</p>
                                          </InputWithP>
                                        </td>
                                        <td>
                                          <LabelRow
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              gap: '0.2rem',
                                            }}>
                                            <LabelS
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                              htmlFor={`count-${index}`}>
                                              <InputS
                                                id={`count-${index}`}
                                                type="checkbox"
                                                name="count"
                                                onChange={(e) => handleChange(e, index)}
                                                value={item?.T_count || ''}
                                                checked={item?.T_count === -1 ? false : true}
                                              />
                                              <h5>이용권 수 제한</h5>{' '}
                                            </LabelS>
                                            {item?.T_count !== -1 && (
                                              <InputWithP>
                                                <InputS
                                                  style={{
                                                    width: '50px',
                                                  }}
                                                  type="number"
                                                  min="0"
                                                  name="T_count"
                                                  onChange={(e) => handleChange(e, index)}
                                                  value={item?.T_count || ''}
                                                />
                                                <p>개</p>
                                              </InputWithP>
                                            )}
                                          </LabelRow>
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-danger"
                                            onClick={() => deleteTicket(item)}
                                            style={{ whiteSpace: 'nowrap' }}>
                                            삭제
                                          </button>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </tbody>
                    </TicketTable>
                  </TableDiv>
                </>
              ) : (
                <>
                  <TableDiv>
                    <LockerTable>
                      <thead>
                        <tr>
                          <td>
                            <p>이름</p>
                          </td>
                          <td>
                            <p>설명</p>
                          </td>
                          <td>
                            <p>할인률</p>
                          </td>
                          <td>
                            <p>가격(₩)</p>
                          </td>
                          <td>
                            <p>최종가격(₩)</p>
                          </td>
                          <td>
                            <p>기간</p>
                          </td>
                          <td>
                            <p>제한</p>
                          </td>
                          <td>
                            <p>제거</p>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <DragDropContext onDragEnd={dragAndDrop}>
                          <Droppable droppableId="lockers">
                            {(provided) => (
                              <div
                                className="lockers"
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {lockerlist &&
                                  lockerlist.map((item, index) => (
                                    <Draggable
                                      key={item.T_sort}
                                      draggableId={`${index}`}
                                      index={index}>
                                      {(provided) => (
                                        <tr
                                          key={'T' + item.T_sort}
                                          ref={provided.innerRef}
                                          {...provided.dragHandleProps}
                                          {...provided.draggableProps}>
                                          <td>
                                            <Input
                                              type="text"
                                              name="T_name"
                                              value={item.T_name || ''}
                                              onChange={(e) => handleChange(e, index)}
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              type="text"
                                              name="T_description"
                                              value={item.T_description || ''}
                                              onChange={(e) => handleChange(e, index)}
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              type="text"
                                              name="T_discount"
                                              value={item.T_discount || ''}
                                              onChange={(e) => handleChange(e, index)}
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              type="number"
                                              name="T_price"
                                              min={100}
                                              value={item.T_price || ''}
                                              onChange={(e) => handleChange(e, index)}
                                            />
                                          </td>
                                          <td>
                                            <p
                                              style={{
                                                fontWeight: 500,
                                                fontSize: 13,
                                                color: 'inherit',
                                              }}>
                                              {((item?.T_price * (100 - item?.T_discount)) / 100)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                              원
                                            </p>
                                          </td>

                                          <td>
                                            <Input
                                              style={{
                                                marginRight: '5px',
                                              }}
                                              type="text"
                                              name="T_day"
                                              value={item.T_day || ''}
                                              disabled={
                                                item?.T_type === 2 || item?.T_type === 5
                                                  ? false
                                                  : true
                                              }
                                              onChange={(e) => handleChange(e, index)}
                                            />
                                            일
                                          </td>
                                          <td>
                                            <LabelRow
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '0.2rem',
                                              }}>
                                              <LabelS
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                }}
                                                htmlFor={`count-${index}`}>
                                                <InputS
                                                  id={`count-${index}`}
                                                  type="checkbox"
                                                  name="count"
                                                  onChange={(e) => handleChange(e, index)}
                                                  value={item?.T_count || ''}
                                                  checked={item?.T_count === -1 ? false : true}
                                                />
                                                <h5>이용권 수 제한</h5>
                                              </LabelS>
                                              {item?.T_count !== -1 && (
                                                <InputWithP>
                                                  <InputS
                                                    style={{
                                                      width: '50px',
                                                    }}
                                                    type="number"
                                                    min="0"
                                                    name="T_count"
                                                    onChange={(e) => handleChange(e, index)}
                                                    value={item?.T_count || ''}
                                                  />
                                                  <p>개</p>
                                                </InputWithP>
                                              )}
                                            </LabelRow>
                                          </td>
                                          <td>
                                            <button
                                              className="btn btn-danger"
                                              onClick={() => deleteTicket(item)}
                                              style={{ whiteSpace: 'nowrap' }}>
                                              삭제
                                            </button>
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </tbody>
                    </LockerTable>
                  </TableDiv>
                </>
              )}

              <ButtonWrapper>
                <button className="btn btn-primary" onClick={ticketDataChange}>
                  수정하기
                </button>
              </ButtonWrapper>
            </>
          ) : (
            <h6>등록된 상품이 없습니다.</h6>
          )}
        </PageContainer>
      </Fragment>
    </>
  );
};

export default ProductList;
