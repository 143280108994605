import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  cursor: pointer;
`;
const Tooltip = styled.div<{ padding?: boolean }>`
  position: absolute;

  display: inline-flex;
  padding: ${(props) => (props.padding ? '1.5rem' : '0')};
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;

  border-radius: 0.25rem;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
  z-index: 10000;
`;

type Props = {
  children: ReactNode;
  tooltipComponent: ReactNode;
  padding?: boolean;
  style?: React.CSSProperties;
  tooltipStyle?: React.CSSProperties;
  delayCount?: number;
  show?: boolean;
  position?: number[];
};
const TooltipWrapper = ({
  children,
  tooltipComponent,
  padding,
  style,
  tooltipStyle,
  delayCount,
  show = true,
}: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    if (!show) return;
    let timer: NodeJS.Timeout | null = null;

    if (hovering) {
      if (delayCount) {
        timer = setTimeout(() => {
          setShowTooltip(true);
        }, 500);
      } else {
        setShowTooltip(true);
      }
    } else {
      if (delayCount) {
        if (timer) clearTimeout(timer);
        setShowTooltip(false);
      } else {
        setShowTooltip(false);
      }
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [hovering]);

  return (
    <Container style={style}>
      <div onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
        {children}
      </div>

      {showTooltip && (
        <Tooltip padding={padding} style={tooltipStyle}>
          {tooltipComponent}
        </Tooltip>
      )}
    </Container>
  );
};

export default TooltipWrapper;
