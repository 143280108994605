import styled from 'styled-components';
import exclamation from '../../assets/images/exclamation.svg';
import React from 'react';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  marginTop?: number;
  marginBottom?: number;
}

const Container = styled.div<ContainerProps>`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;

  margin-top: ${({ marginTop }) => `${marginTop === undefined ? '30' : marginTop}px`};
  margin-bottom: ${({ marginBottom }) => `${marginBottom === undefined ? '30' : marginBottom}px`};

  > img {
    margin-bottom: 0.5rem;
  }
  > p {
    font-weight: 600;
    color: #747474;
    font-size: 16px;
    text-align: center;
    line-height: 150%;

    word-break: keep-all;
  }
`;

interface NotFoundContainerProps {
  text: string;
  marginTop?: number;
  marginBottom?: number;
}

const NotFoundContainer = ({ text, marginTop, marginBottom }: NotFoundContainerProps) => {
  return (
    <Container marginTop={marginTop} marginBottom={marginBottom}>
      <img src={exclamation} alt="exclamation icon" />
      <p
        className="notFoundText"
        dangerouslySetInnerHTML={{
          __html: typeof text === 'string' ? text.replaceAll('\n', '<br />') : text,
        }}
      />
    </Container>
  );
};
export default NotFoundContainer;
