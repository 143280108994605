import styled from "styled-components";

export const Flex = styled.div`
	display: flex;
	gap: ${(props) => (props.gap ? `${props.gap}rem` : "0")};
	width: ${(props) => (props.full ? "100%" : "auto")};
	flex-direction: ${(props) => (props.column ? "column" : "row")};
`;

export const FlexCenter = styled(Flex)`
	align-items: center;
	justify-content: center;
`;
export const FlexCenterV = styled(Flex)`
	align-items: center;
`;
export const FlexCenterH = styled(Flex)`
	justify-content: center;
`;
