import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import { DashboardCard } from '../components/commonStyle';
import Receipt from '../components/receipt';

const MessageSuccess = () => {
  //1.userLevel 확인하여 잘못된 레벨이 접근할 경우 막기

  //meta2, meta10는 보여주면 안됨.
  const handleSuccess = () => {
    Swal.fire({
      icon: 'success',
      title: '결제가 완료됐습니다.',
      text: '자동으로 메시지 충전 페이지로 이동됩니다.',
      timer: 2500,
      showCancelButton: false,
      showConfirmButton: false,
    }).then(() => {
      window.location.href = `${process.env.PUBLIC_URL}/message/charge`;
    });
  };

  useEffect(() => {
    handleSuccess();
  }, []);
  return (
    <DashboardCard>
      <p>결제가 완료되었습니다.</p>
      {/* <Receipt /> */}
    </DashboardCard>
  );
};

export default MessageSuccess;
