import React from 'react';
import styled from 'styled-components';
import dropIcon from '../../assets/images/ic_drop.svg';
import { FlexCenterV } from '../../style/flexStyle';
import { DatePickerStyle } from '../../pages/seatInfo';
import { Dropdown } from '../common/Dropdown';

export const DropDown = styled.select`
  padding: 8px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  background: url(${dropIcon}) #f8fcff no-repeat;
  background-position: right 10px bottom 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;

  font-size: 0.875rem;

  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 16px;
    background-clip: padding-box;
    border: 6px solid transparent;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 16px;
  }
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 16px;
    background-clip: padding-box;
    border: 6px solid transparent;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 16px;
  }
  option {
    :disabled {
      color: #e1e1e1;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const PerPageSelect = ({ perpage, onChange }) => {
  return (
    <Dropdown>
      <select
        value={perpage}
        onChange={(e) => {
          onChange(e.target.value);
        }}>
        <option value="10">10개씩 보기</option>
        <option value="20">20개씩 보기</option>
        <option value="50">50개씩 보기</option>
        <option value="100">100개씩 보기</option>
      </select>
    </Dropdown>
  );
};

export default PerPageSelect;
