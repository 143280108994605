import React, { useState } from 'react';
import { Input } from 'reactstrap';
import styled from 'styled-components';

const Container = styled.form`
  width: 100%;
  max-width: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const SearchButton = styled.button`
  position: absolute;
  right: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
`;

/**
 *
 * @param {*} props
 * @param handleSearchKeyword : 검색어 변경시 실행되는 함수( (string)=>void | undefined )
 * @param onSearch : 검색 버튼 클릭시 실행되는 함수( (string)=>void | undefined )
 * @param placeholder : 검색창 placeholder ( string | undefined )
 * @returns
 */
const SearchBar = (props) => {
  const [inputValue, setInputValue] = useState('');

  const onChange = (e) => {
    setInputValue(e.target.value);
    if (props.handleSearchKeyword) {
      props.handleSearchKeyword(e.target.value);
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    if (props.onSearch) props.onSearch(inputValue);
  };

  return (
    <Container onSubmit={onSearch}>
      <Input
        placeholder={props.placeholder ? props.placeholder : '검색어를 입력해주세요'}
        value={inputValue}
        className="form-control"
        onChange={onChange}
      />
      <SearchButton type="submit">
        <i className="fa fa-search" />
      </SearchButton>
    </Container>
  );
};

export default SearchBar;
