import React from 'react';

import styled from 'styled-components';
import Warning from '../../assets/images/icon_warning_filled.svg';

import { useState } from 'react';
import { QuestionMark } from '../QuestionMark';

interface IProps {
  style?: object;
}

const Content = styled.div`
  position: absolute;
  z-index: 1;

  transform: translate(-50%, -120%);

  width: 24rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  line-height: 150%;

  padding: 1rem;

  background: white;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

  ul {
    padding-left: 0.5rem;
    list-style: disc;

    li {
      display: grid;
      grid-template-columns: 6rem auto;
      width: 100%;
    }
  }

  .warning {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    font-weight: bold;
  }
`;

const Title = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;

const MessageDisclaimerTooltip = ({ style }: IProps) => {
  const [tooltipEnabled, setTooltipEnabled] = useState<boolean>(false);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <QuestionMark
          onMouseEnter={() => setTooltipEnabled(true)}
          onMouseLeave={() => setTooltipEnabled(false)}
        />
      </div>
      {tooltipEnabled && (
        <Content style={style}>
          <Title>사진 첨부 시 주의사항</Title>
          <ul>
            <li>
              <span>·&nbsp;&nbsp;첨부 장수 제한</span>
              <span>1장</span>
            </li>
            <li>
              <span>·&nbsp;&nbsp;파일 형식</span>
              <span>JPG, PNG만 가능</span>
            </li>
            <li>
              <span>·&nbsp;&nbsp;이미지 사이즈</span>
              <span>
                가로, 세로 최대 640px, 넘어갈 시 자동 조정
                <br />
                조정된 이미지는 900Kbyte까지 첨부 가능
              </span>
            </li>
          </ul>
          <div className="warning">
            <img src={Warning} alt="message-disclaimer-warning-icon" />
            <span>첨부 자체가 안 되는 경우는?</span>
          </div>
          <div>
            사용하시는 웹 브라우저의 특성 때문일 수 있습니다. 크롬 브라우저를 사용하시면 더 빠르고
            안전하게 서비스를 이용할 수 있습니다.
          </div>
        </Content>
      )}
    </>
  );
};

export default MessageDisclaimerTooltip;
