import io from "socket.io-client";

const getSiteLink = window.location.href;

export const socket =
  getSiteLink && getSiteLink.includes("localhost")
    ? io(process.env.REACT_APP_SOCKET_URL_DEV, {
        transports: ["websocket"],
        cors: { origin: "*" },
      })
    : io(process.env.REACT_APP_SOCKET_URL, {
        transports: ["websocket"],
        cors: { origin: "*" },
      });
export const joinRoom = (id) => socket.emit("JoinSocketRoom", id);
export const openDoor = (id) => socket.emit("request_openDoor", id);
export const allOpenDoor = (id) => socket.emit("request_AllopenDoor", id);

export const payCancel = (id, tid) => socket.emit("refund_Product", id, tid);

export const recetipReprint = (id, rid) =>
  socket.emit("reprint_Product", id, rid);
export const pRestart = (id) => socket.emit("restart_program", id);
export const kRestart = (id) => socket.emit("restart_kiosk", id);

export const innerPRestart = (id) => socket.emit("inner_restart_program", id);
export const innerKRestart = (id) => socket.emit("inner_restart_kiosk", id);
export const sendQrCode = (merchant, num, code) =>
  socket.emit("qr_print_from_backoffice", merchant, num, code);

//id:merchant, num:자판기 아이템 숫자
export const vendingMachine = (id, num) =>
  socket.emit("request_vendingMachine", id, num);

export const healthCheckSocket = () => socket.emit("req_socket_connect");

//멀티키오스크에 따른 문열림, 재시작
//문열림
export const multiOpenDoor = (id, floor) =>
  socket.emit("request_openDoor_inner", id, floor);

//PC 재시작
export const multiPcRestart = (id, floor) =>
  socket.emit("inner_restart_kiosk_Floor", id, floor);

//프로그램 재시작
export const multiProgramRestart = (id, floor) =>
  socket.emit("inner_restart_program_Floor", id, floor);

//프로그램 업데이트
export const merchantUpdateKiosk = () => socket.emit("request_AllupdateKiosk");
export const outerUpdate = (id) => socket.emit("request_updateKiosk_outer", id);
export const innerUpdate = (id) => socket.emit("request_updateKiosk_inner", id);
