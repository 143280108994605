import React from 'react';
import styled from 'styled-components';
import LoadingSvg from '../assets/images/loading.svg';

const Loading = () => {
  return (
    <div>
      <img src={LoadingSvg} alt="로딩중.." />
    </div>
  );
};

export default Loading;

const LoadingBack = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  /* background-color: #00000033; */
  z-index: 999;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const BackgroundLoading = () => {
  return (
    <LoadingBack>
      <img src={LoadingSvg} alt="로딩중.." />
    </LoadingBack>
  );
};
