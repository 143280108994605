import html2canvas from 'html2canvas';
import moment from 'moment';
import React, { useState } from 'react';
import { Download } from 'react-feather';
import styled from 'styled-components';
import Swal from 'sweetalert2';

const ReceiptComponent = styled.div`
  width: 50vw;
  margin: 5vw auto;

  border-radius: 0.83vw;
  padding: 2vw;
  p {
    margin-bottom: 0;
  }
  .area {
    border-bottom: 1px solid #c9d0d6;
    margin-bottom: 5vw;
    > h4 {
      margin-bottom: 0;
      font-size: 2.5vw;
      border-bottom: 1px solid black;
    }
    &:last-child {
      .flex {
        > p {
          &:last-child {
            font-size: 2vw;
            color: black;
          }
        }
      }
    }
  }

  .flex {
    display: flex;
    padding: 1vw;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    > p {
      &:first-child {
        font-size: 2vw;
        color: #989898;
      }
      &:last-child {
        font-weight: 500;
        font-size: 2vw;
        color: #989898;
      }
    }
  }
  .price {
    border-top: 1px solid #e1e1e1;
    > h5 {
      margin-bottom: 0;
      padding: 1vw 0;
      font-size: 2.3vw;
    }
    > p {
      &:last-child {
        color: #ff6e6e !important;
      }
    }
  }

  @media screen and (max-width: 623px) {
    width: 80vw;
  }
`;

const DownloadBtn = styled.button`
  position: fixed;
  bottom: 0;
  border: none;
  background-color: #00cfe2;
  color: #ffffff;
  width: 100%;
  padding: 2vw 0;
  font-size: 2vw;
  @media screen and (max-width: 623px) {
    padding: 5vw 0;
    font-size: 5vw;
  }
`;
const Receipt = ({ receiptData }) => {
  const VAT = Math.floor(receiptData?.amount / 11);

  const onSave = (uri, filename) => {
    let link = document.createElement('a');
    document.body.appendChild(link);
    link.href = uri;
    link.download = filename;
    link.click();
    document.body.removeChild(link);
    Swal.fire({
      text: '영수증이 저장되었습니다.',
      timer: 2000,
    });
  };

  const saveImage = () => {
    //url로 불러오는 이미지는 저장이 안되는 경우가 발생.
    //해당이미지까지 불러오고 싶다면 allowTaint: true, useCORS: true 를 설정할것.
    html2canvas(document.querySelector('.saveImg'), {
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      onSave(canvas.toDataURL('image/png'), `Receipt_${moment().format('YYYYMMDDhhssmm')}.png`);
    });
  };

  return (
    <>
      <ReceiptComponent className="saveImg">
        <div className="area">
          <h4>지점정보</h4>
          <div className="flex">
            <p>지점명</p> <p>{receiptData?.merchantName}</p>
          </div>
          <div className="flex">
            <p>사업자번호</p> <p>{receiptData?.br_number}</p>
          </div>
          <div className="flex">
            <p>전화번호</p> <p>{receiptData?.merchantContact}</p>
          </div>
          <div className="flex">
            <p>매장 주소</p> <p>{receiptData?.store_address}</p>
          </div>
        </div>
        {(receiptData?.addMilage || receiptData?.minerMilage) && (
          <div className="area">
            <h4>마일리지 적립</h4>
            <div className="flex">
              <p>적립마일리지</p> <p>{receiptData?.addMilage}</p>
            </div>
            <div className="flex">
              <p>사용마일리지</p> <p>{receiptData?.minerMilage}</p>
            </div>
          </div>
        )}

        <div className="area">
          <h4>거래정보</h4>
          <div className="flex">
            <p>상품명</p> <p>{receiptData?.productionName}</p>
          </div>
          <div className="flex">
            <p>거래유형</p> <p>{receiptData?.paymentType?.includes('cancel') ? '환불' : '승인'}</p>
          </div>
          <div className="flex">
            <p>결제수단</p>{' '}
            <p>
              {receiptData?.paymentType?.includes('credit')
                ? '카드'
                : receiptData?.paymentType?.includes('cash')
                  ? '현금'
                  : receiptData?.paymentType?.includes('payco')
                    ? '페이코'
                    : receiptData?.paymentType?.includes('kakaopay')
                      ? '카카오페이'
                      : receiptData?.paymentType?.includes('naverpay')
                        ? '네이버페이'
                        : receiptData?.paymentType?.includes('localpay')
                          ? '지역화폐'
                          : receiptData?.paymentType?.includes('applepay')
                            ? '애플페이'
                            : receiptData?.paymentType?.includes('hecto')
                              ? '헥토파이낸셜(웹결제)'
                              : '-'}
            </p>
          </div>
          <div className="flex">
            <p>
              {receiptData?.paymentType?.includes('cancel') ? '환불' : '승인'}
              시간
            </p>{' '}
            <p>{moment(receiptData?.approveDate).format('YYYY-MM-DD HH:mm:ss')}</p>
          </div>
          <div className="flex">
            <p>과세금액</p> <p>{receiptData?.amount - VAT}원</p>
          </div>
          <div className="flex">
            <p>부가세</p> <p>{VAT}원</p>
          </div>

          <div className="flex price">
            <h5>총 결제금액</h5>
            <p>{receiptData?.amount}원</p>
          </div>
        </div>
      </ReceiptComponent>
      <DownloadBtn onClick={saveImage}>영수증 다운로드</DownloadBtn>
    </>
  );
};

export default Receipt;
