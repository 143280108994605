import React from 'react';
import styled from 'styled-components';

interface SeatIconProps {
  seatColor: string;
  borderColor: string;
}

interface SeatLegendItemProps extends SeatIconProps {
  text: string;
}

const SeatIcon = styled.div<SeatIconProps>`
  width: 1.125rem;
  height: 1.125rem;

  background: ${({ seatColor }) => seatColor};
  border: ${({ borderColor }) => `2px solid ${borderColor}`};
`;

const StyledSeatLegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const SeatLegendItem = ({ seatColor, borderColor, text }: SeatLegendItemProps) => {
  return (
    <StyledSeatLegendItem>
      <SeatIcon seatColor={seatColor} borderColor={borderColor} />
      {text}
    </StyledSeatLegendItem>
  );
};

export const SeatLegend = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  padding: 0.5rem 0;
`;
