import styled, { css } from 'styled-components';
import checkmark from '../assets/images/checkmark.svg';
// 대시보드 카드
export const DashboardCard = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: #ffffff;
  border: 1px solid #dde4eb;
  border-radius: 1rem;
`;

export const ScrollbarStyle = css`
  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 16px;
    background-clip: padding-box;
    border: 6px solid transparent;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 16px;
  }
`;

export const FlexWrapCenter = styled.div<{
  gap?: number;
  justifyContent?: 'center' | 'flex-start' | 'flex-end';
  direction?: 'row' | 'column';
}>`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
  align-items: center;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'flex-start')};
  gap: ${({ gap }) => (gap ? gap : 0)}rem;
`;

//checkbox
export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
  background: center 55% no-repeat url(${checkmark});
  background-color: #ffff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #ccc;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  :checked {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.main[600]};
  }

  :disabled {
    background-color: #eaeaea;
    border: none;
    cursor: auto;
    background-color: #eaeaea;
  }

  :disabled:checked {
    background-color: #eaeaea;
  }

  :not(:checked) {
    background: none;
    background-color: none;
    border: 2px solid #d5d5d5;
  }

  :not(:checked):disabled {
    border: none;
    background-color: #f6f6f6;
  }
`;

export const FlexCenterH = styled.div<{ gap?: number }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : 0)}rem;
`;

// 라디오 버튼
export const RadioBtn = styled.input.attrs({ type: 'radio' })`
  background: center 55% no-repeat url(${checkmark});
  background-color: #ffff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #e1e1e1;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  :checked {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: var(--dashboard-default);
  }
  :disabled {
    background-color: #e1e1e1;
  }
`;

// RadioLabel
export const RadioLabel = styled.label`
  display: flex;
  gap: 0.5rem;
`;

// solidBtn(noneRound)
export const SolidBtn = styled.button`
  border: none;
  background-color: var(--dashboard-default);
  color: #ffffff;
  width: fit-content;
  padding: 2vw;
  border-radius: 100vw;
`;
