import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import configDB from '../data/customizer/config';
import Arrow from '../assets/images/dashboard/pageArrow.svg';
import Arrow2 from '../assets/images/dashboard/pageArrow2.svg';
import useWindow from '../lib/hooks/useWindow';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

const PaginationItemS = styled(PaginationItem)`
  border: none;
  display: flex;
  align-items: center;
  margin: 0 0.125rem;
  :nth-child(
      ${(props) =>
          props.page % props.$pageBasis !== 0
            ? (props.page % props.$pageBasis) + 2
            : props.$pageBasis + 2}
    ) {
    .page-link {
      color: #fff !important;
      background-color: ${primary};
      border-radius: 0.2vw;
      :focus {
        box-shadow: none;
      }
    }
  }

  .page-link {
    width: 2rem;
    height: 2rem;
    border: none;
    font-size: 1rem;
    background-color: #cfd8e2;
    color: #fff !important;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    :focus {
      box-shadow: none;
    }
    :hover {
      background-color: #b2f1f6;
    }
    ${({ enabled }) =>
      enabled &&
      css`
        background: ${({ theme }) => theme.main[600]};
      `}
  }
  @media screen and (max-width: 805px) {
    /* font-size: 3.33vw; */
    /* margin: 0 1.1vw; */
    /* :nth-child(${(props) => (props.page % 10 !== 0 ? (props.page % 10) + 2 : 12)}) { */
    .page-link {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.75rem;
    }
    /* } */
    /* .page-link {
      padding: 1.1vw 2vw;
      width: 5.55vw;
      height: 5.55vw;
      border-radius: 0.55vw;
      font-size: 3.33vw;
      :hover {
        background-color: ${primary};
      }
    } */
  }

  /* @media screen and (max-width: 768px) {
    .page-link {
      margin: 0;
    }
  }

  @media screen and (max-width: 480px) {
    .page-link {
      padding: 0.3rem 0.5rem;
    }
  }
  @media screen and (max-width: 375px) {
    .page-link {
      padding: 0.15rem 0.4rem;
    }
  } */
`;

const PageContainer = styled.div`
  .page-link {
    border: none !important;
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    color: #91979e !important;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0.26vw; */
  }
  @media screen and (max-width: 805px) {
    font-size: 0.75rem;
    .page-link {
      width: 1.5rem;
      height: 1.5rem;
      :hover {
        background-color: #ffffff;
        border: none;
      }
    }
  }
`;

interface PaginationProps {
  setCurrentPage: (page: number) => void | React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  postsPerPage: number;
  totalLength: number;
  pagecount?: number;
  padding?: number;
}

const PaginationComponent = ({
  setCurrentPage,
  totalLength,
  postsPerPage,
  currentPage,
  pagecount,
  padding,
}: PaginationProps) => {
  const windowSize = useWindow();
  const PageBasis = useMemo(() => {
    if (!windowSize || !windowSize.width) return 10;
    if (windowSize.width < 768) return 5;
    return 10;
  }, [windowSize]);
  const arrayNum = useMemo(
    () =>
      currentPage % PageBasis === 0
        ? Math.floor(currentPage / PageBasis) - 1
        : Math.floor(currentPage / PageBasis),
    [currentPage, PageBasis],
  );
  // pagination
  let page = pagecount ? totalLength : Math.ceil(totalLength / postsPerPage);
  // let pageItem = [];
  //출력되는 페이지
  // let printPage = [];
  // let arrayNum =
  //   currentPage % PageBasis === 0 ? Math.floor(currentPage / PageBasis) - 1 : Math.floor(currentPage / PageBasis);

  // console.log(arrayNum);

  const [printPage, setPrintPage] = useState<any[]>([]);
  const [pageItem, setPageItem] = useState<any[]>([]);
  useEffect(() => {
    const printPageTemp: any[] = [];
    const pageItemTemp: any[] = [];
    if (page) {
      for (let i = 0; i < page; i++) {
        pageItemTemp.push(
          <PaginationItemS
            page={currentPage}
            $pageBasis={PageBasis}
            key={i + 1}
            onClick={({ target }: any) => {
              console.log(+target.innerText);
              setCurrentPage(+target.innerText);
            }}>
            <PaginationLink enabled={`${currentPage === i + 1}`}>{i + 1}</PaginationLink>
          </PaginationItemS>,
        );
      }
      if (pageItemTemp.length > PageBasis) {
        // printPage = pageItem.division(10);
        for (let i = 0; i < pageItemTemp.length; i += PageBasis)
          printPageTemp.push(pageItemTemp.slice(i, i + PageBasis));
      }
    }
    setPrintPage(printPageTemp);
    setPageItem(pageItemTemp);
  }, [page, PageBasis, currentPage]);

  return (
    <div
      style={{
        justifyContent: 'center',
        padding: padding === 0 ? '1vw 0 0' : '2vw 0 7vw',
        display: 'flex',
      }}>
      <Pagination className="pagination-primary pagination">
        <PaginationItem
          onClick={() => {
            currentPage - PageBasis > 0
              ? setCurrentPage(currentPage - PageBasis)
              : setCurrentPage(1);
          }}>
          <PageContainer style={{ color: '#91979e !important' }}>
            <PaginationLink>
              <span aria-hidden="true">
                <img style={{ transform: 'rotate(180deg)' }} src={Arrow2} alt="«" />
              </span>
              <span className="sr-only">{''}</span>
            </PaginationLink>
          </PageContainer>
        </PaginationItem>
        <PaginationItem
          onClick={() => {
            currentPage - 1 === 0 ? setCurrentPage(1) : setCurrentPage(currentPage - 1);
          }}>
          <PageContainer>
            <PaginationLink>
              <span aria-hidden="true">
                {' '}
                <img style={{ transform: 'rotate(180deg)' }} src={Arrow} alt="&lsaquo;" />
              </span>
              <span className="sr-only">{''}</span>
            </PaginationLink>
          </PageContainer>
        </PaginationItem>
        {printPage?.length > 0 ? printPage[arrayNum] : pageItem}
        <PaginationItem
          onClick={() => {
            +currentPage === +pageItem?.length
              ? setCurrentPage(+pageItem?.length)
              : setCurrentPage(currentPage + 1);
          }}>
          <PageContainer>
            <PaginationLink>
              <span aria-hidden="true">
                {' '}
                <img src={Arrow} alt="&rsaquo;" />
              </span>
              <span className="sr-only">{}</span>
            </PaginationLink>
          </PageContainer>
        </PaginationItem>
        <PaginationItem
          onClick={() => {
            currentPage + PageBasis < pageItem?.length
              ? setCurrentPage(currentPage + PageBasis)
              : setCurrentPage(pageItem?.length);
          }}>
          <PageContainer>
            <PaginationLink>
              <span aria-hidden="true">
                <img src={Arrow2} alt="»" />
              </span>
              <span className="sr-only">{}</span>
            </PaginationLink>
          </PageContainer>
        </PaginationItem>
      </Pagination>
    </div>
  );
};
export default PaginationComponent;
