import { AxiosResponse } from 'axios';

import { loadingState } from '../../state';
import { useSetRecoilState } from 'recoil';

const useHandleAPICall = () => {
  const setLoading = useSetRecoilState(loadingState);
  const createRequest = async (
    fn: (...props: any) => Promise<AxiosResponse<any, any>>,
    ...props: any[]
  ) => {
    try {
      setLoading(true);
      const response = await fn(...props);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
  return { createRequest };
};

export default useHandleAPICall;
