import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

import { getAllToken, removeToken } from '../util/token';
import { patchRefresh } from './api/v2/auth';
import { swalReturnWarning } from '../util/commonFunctions';

export const withRefreshTokenAccepted = function (config: InternalAxiosRequestConfig<any>) {
  const { userRefreshToken, merchantRefreshToken, adminRefreshToken } = getAllToken();
  if (merchantRefreshToken) {
    config.headers['refreshtoken'] = `${merchantRefreshToken}`;
    return config;
  }
  if (userRefreshToken) {
    config.headers['refreshtoken'] = `${userRefreshToken}`;
    return config;
  }
  if (adminRefreshToken) {
    config.headers['refreshtoken'] = `${adminRefreshToken}`;
    return config;
  }
  return config;
};

export const withUserTokenAccepted = function (config: InternalAxiosRequestConfig<any>) {
  const { userToken } = getAllToken();
  // const requestBlocked = localStorage.getItem('requestBlocked');
  // if (requestBlocked === '1') throw new Error();
  if (userToken) config.headers['Authorization'] = `Bearer ${userToken}`;
  return config;
};

export const withAdminTokenAccepted = function (config: InternalAxiosRequestConfig<any>) {
  const { adminToken } = getAllToken();
  // const requestBlocked = localStorage.getItem('requestBlocked');
  // if (requestBlocked === '1') throw new Error();
  if (adminToken) config.headers['Authorization'] = `Bearer ${adminToken}`;
  return config;
};

export const withMerchantTokenAccepted = function (config: InternalAxiosRequestConfig<any>) {
  const { merchantToken } = getAllToken();
  // const requestBlocked = localStorage.getItem('requestBlocked');
  // if (requestBlocked === '1') throw new Error();
  if (merchantToken) config.headers['Authorization'] = `Bearer ${merchantToken}`;
  return config;
};

export const apply = (response: any): Promise<any> => {
  return response;
};

/**
 * 사용자 토큰 에러 시
 * @param error
 * @returns
 */
export const withTokenError = function (error: AxiosError) {
  return Promise.reject(error);
};

export const withTokenRejected = async (error: AxiosError) => {
  const { config, response } = error as AxiosError;
  const tokenList = getAllToken();

  try {
    if (response?.status === 429) {
      swalReturnWarning(
        '현재 접속이 많아 요청을 처리할 수 없습니다.\n잠시 후 다시 시도해 주시면 원활하게 이용하실 수 있습니다.',
      );
      return;
    }

    if (response?.status === 401) {
      const authorization = config?.headers?.Authorization;
      const authorizationToken = authorization ? (authorization as string).split(' ')[1] : null;

      if (!config?.headers?.Authorization) {
        removeToken();
        window.location.href = '/auth/login';
        return;
      }
      const tokenType =
        authorizationToken === tokenList.adminToken
          ? 'adminRefreshToken'
          : authorizationToken === tokenList.merchantToken
          ? 'merchantRefreshToken'
          : 'userRefreshToken';
      try {
        const res: any = await patchRefresh(tokenType);
        return axios({
          ...config,
          headers: {
            ...config.headers,
            Authorization: 'Bearer ' + res.data.accessToken,
          },
        });
      } catch (error) {
        removeToken([
          'adminToken',
          'adminRefreshToken',
          'merchantToken',
          'merchantRefreshToken',
          'userToken',
          'userRefreshToken',
        ]);
        window.location.href = '/auth/login';
      }
      return;
    }
    return Promise.reject(response);
  } catch (error) {
    return Promise.reject(response);
  }
};

/**
 * 토큰이 만료되었을 때 호출되는 함수
 * @param error
 */
export const withTokenExpired = (error: AxiosError) => {
  if (error.response?.status !== 401) return Promise.reject(error);

  removeToken([
    'adminToken',
    'adminRefreshToken',
    'merchantToken',
    'merchantRefreshToken',
    'userToken',
    'userRefreshToken',
  ]);
  window.location.href = '/auth/login';
  return Promise.reject(error);
};
