const theme = {
  // Neutral colors
  white: '#FFFFFF',
  black: '#000000',
  red: '#FF0000',
  warning: '#ca3b3b',
  neutralGray: {
    100: '#F5F5F5',
    200: '#EAEAEA',
    300: '#CCCCCC',
    400: '#999999',
    500: '#555555',
    600: '#222222',
  },
  main: {
    100: '#e1f4f4',
    200: '#c3eaea',
    300: '#a3dfe0',
    400: '#80d4d5',
    500: '#57c9cb',
    600: '#00bec1',
  },

  // Main theme colors
  primary: '#00BEC1',
  excel: '#f73164',
  secondary: {
    100: '#eff5f5',
    200: '#dfebec',
    300: '#bfd7d9',
    400: '#9fc4c6',
    500: '#7eb0b3',
    600: '#5f9da0',
  },
  background: '#F6F9FA',

  // Main gradation colors
  mainGradationStart: '#00BEC1',
  mainGradationEnd: '#00d7db',

  // Colors for components
  indigo: '#0F2A4A',
  lightIndigo: '#35557C',

  skyGray: '#EDF4F5',
  darkNavy: '#0F2A4A',

  wall: '#CCCCCC',
  seatLevel: '#0F2A4A',
  seatBackground: '#555555',
  normalSeat: 'white',
  occupiedSeat: '#35557C',
  fixedSeat: '#CCE2FF',
  unavailableSeat: '#CCCCCC',
  seminarSeat: '#C4BCE8',
  idleSeat: 'transparent',
  normalSeatWithDisposableTicket: '#999999',
  normalSeatWithPeriodicTicket: '#E68E21',
  normalSeatWithTimeBasedTicket: '#02838F',
  normalSeatWithLockerTicket: '#555555',
  fixedSeatWithPeriodicTicket: '#1968C9',

  normalSeatWithDisposableTicketRest: '#cccccc',
  normalSeatWithPeriodicTicketRest: '#f5d2a6',
  normalSeatWithTimeBasedTicketRest: '#9acdd2',
  fixedSeatWithPeriodicTicketRest: '#a2c3e9',

  mainFeatureButton: '#EBF3FC',

  // Colors with alpha channel
  whiteAlpha: {
    900: '#FFFFFFE5',
  },
  darkNavyAlpha: {
    800: '#0F2A4ACC',
  },
};

export default theme;
