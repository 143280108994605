import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumbs from '../layout/breadcrumb';
import { Container, Row, Col, Card, CardBody, Input, CardFooter } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { getMerchantInfo, merchantId_api, postSendManyMessage } from '../lib/api';
import { LabelRow } from './ticketCustom';
import { Label2 } from './setting';

const CardCotainer = styled.div``;
export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  p {
    margin: -1.5px 15px 0px 7px;
  }
`;
export const Checkbox = styled.label`
  input {
    display: none;
  }
  display: flex;

  // 체크박스 테두리
  .checkbox_icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: transparent;
    border: 2px solid #ccc;
    border-radius: 2px;
    position: relative;
    cursor: pointer;
  }

  // 체크박스 가상요소
  .checkbox_icon::before,
  .checkbox_icon::after {
    content: '';
    display: inline-block;
    width: 2px;
    height: 0;
    background-color: #fff;
    position: absolute;
    transform-origin: left top;
  }

  // 가상요소 before
  .checkbox_icon::before {
    top: 7.2px;
    left: 0px;
    transform: rotate(-40deg);
  }

  // 가상요소 after
  .checkbox_icon::after {
    top: 12.6px;
    left: 5px;
    transform: rotate(-138deg);
  }

  // 체크되었을 때 테두리 설정
  input:checked + .checkbox_icon {
    border-color: #05c8da;
    background-color: #05c8da;
  }

  // 체크되었을 때 가상요소 before
  input:checked + .checkbox_icon::before {
    height: 6.8px; // 높이값 지정
    transition: all 0.1s ease; // 0.15초 변화시간 줌
  }

  // 체크되었을 때 가상요소 after
  input:checked + .checkbox_icon::after {
    height: 12.8px; // 높이값 지정
    transition: all 0.1s ease-in-out; // 0.15초 변화시간 + 딜레이 시간 줌
  }
`;

let mid = '';

const MessageSend = () => {
  const dataLists = [
    { id: 1, data: '일회권' },
    { id: 2, data: '기간권' },
    { id: 3, data: '시간정액권' },
    { id: 4, data: '세미나' },
    { id: 5, data: '사물함' },
    { id: 6, data: '고정석' },
  ];
  const [checkItems, setCheckItems] = useState(dataLists.map((li) => li.id));
  const [isUsing, setIsUsing] = useState(2);
  const [msg, setMsg] = useState('');
  const [isAd, setIsAd] = useState(1);
  const [name, setName] = useState('');

  useEffect(() => {
    getMerchantId();
  }, []);

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      mid = data.id;
      const res = await getMerchantInfo(data.id);
      setName(res.data[0].name);
    }
  };
  const handleOnchange = ({ target }) => {
    setMsg(target.value);
  };
  const handleSelectedOnchange = ({ target }) => {
    setIsUsing(+target.value);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      if (checkItems.length > 0) {
        if (msg.length > 0) {
          Swal.fire({
            text: '발송중입니다.',
            showConfirmButton: false,
          });
          const res = await postSendManyMessage({
            mid,
            msg: isAd === 1 ? `(광고)${name} ${msg} 무료거부 0805005710` : msg,
            isUsing,
            type: checkItems,
          });

          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              title: '메시지 전송이 완료되었습니다.',
              timer: 3000,
            }).then(() => {
              setCheckItems(dataLists.map((li) => li.id));
              setIsUsing(2);
              setMsg('');
              setIsAd(1);
            });
          } else if (res.status === 202) {
            Swal.fire({
              icon: 'error',
              title: '메시지포인트가 부족합니다. 충전후 사용 부탁드립니다.',
              timer: 3000,
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: '메시지를 입력해주세요.',
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: '이용권 타입을 하나 이상 선택해주세요.',
          timer: 2000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // 체크박스 전체 단일 개체 선택
  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheckItems([...checkItems, id]);
    } else {
      // 체크 해제
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  // 체크박스 전체 선택
  const handleAllCheck = (checked) => {
    if (checked) {
      const idArray = [];
      dataLists.forEach((el) => idArray.push(el.id));
      setCheckItems(idArray);
    } else {
      setCheckItems([]);
    }
  };

  return (
    <Fragment>
      <Breadcrumbs parent="상품관리" title="사물함 등록" />
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{ paddingLeft: 5, paddingRight: 5 }}>
            <Card>
              <CardBody>
                <h5 style={{ marginBottom: '20px' }}>단체 메시지전송</h5>
                <CardCotainer>
                  <CheckboxContainer>
                    <Checkbox htmlFor="all">
                      <Input
                        id="all"
                        type="checkbox"
                        onChange={({ target }) => handleAllCheck(target.checked)}
                        checked={checkItems.length === dataLists.length}
                      />
                      <span className="checkbox_icon" />

                      <p>전체선택</p>
                    </Checkbox>
                  </CheckboxContainer>
                  <CheckboxContainer>
                    {dataLists.map((list) => (
                      <Checkbox key={list.id}>
                        <Input
                          type="checkbox"
                          onChange={({ target }) => handleSingleCheck(target.checked, list.id)}
                          checked={checkItems.includes(list.id)}
                        />
                        <span className="checkbox_icon" />

                        <p> {list.data}</p>
                      </Checkbox>
                    ))}{' '}
                  </CheckboxContainer>

                  <LabelRow style={{ margin: '20px 0' }}>
                    <h5
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        width: '65px',
                      }}>
                      대상자
                    </h5>
                    <Input
                      name="isUsing"
                      type="select"
                      className="custom-select"
                      onChange={handleSelectedOnchange}>
                      <option value={2} defaultChecked>
                        전체
                      </option>
                      <option value={1}>현재입장고객</option>
                      <option value={0}>입장하지 않은 고객</option>
                    </Input>
                  </LabelRow>

                  <CheckboxContainer>
                    <Checkbox>
                      <Input
                        id="isAd"
                        type="checkbox"
                        checked={isAd === 1}
                        onChange={() => setIsAd(isAd === 1 ? 0 : 1)}
                      />{' '}
                      <span className="checkbox_icon" />
                      <p>광고</p>
                    </Checkbox>
                  </CheckboxContainer>

                  <Label2>
                    <h5
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        marginTop: '10px',
                      }}>
                      전송메시지
                    </h5>
                    <Input
                      type="textarea"
                      className="form-control"
                      rows="3"
                      id="isUsing"
                      name="msg"
                      onChange={handleOnchange}
                      value={msg || ''}
                    />
                  </Label2>
                  <CardFooter className="text-center">
                    <button onClick={handleOnSubmit} className="btn btn-primary">
                      전송하기
                    </button>
                  </CardFooter>
                </CardCotainer>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MessageSend;
