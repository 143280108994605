import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
// import { Home } from 'react-feather';
// import { Link } from 'react-router-dom';

const Breadcrumbs = (props) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-title">
          {/* <Row>
           
            <Col xs="6" style={{ flex: "0 0 85%", maxWidth: "100%" }}>
              <Breadcrumb>
                <BreadcrumbItem>홈</BreadcrumbItem>
                <BreadcrumbItem>{props.parent}</BreadcrumbItem>
                <BreadcrumbItem active>{props.title}</BreadcrumbItem>
              </Breadcrumb>
            </Col> 
          </Row> */}
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
