import React from 'react';
import * as CSS from 'csstype';
import DataTable, { TableProps } from 'react-data-table-component';
import NotFoundContainer from './NotFoundContainer';
import styled from 'styled-components';
import Format from '../../util/formatter';

interface Props extends TableProps<any> {
  tableHeight?: number;
  noItemMessage?: string;
}

interface TableHeaderProps {
  summary?: {
    mainSummaryValue?: string | number;
    mainSummaryPrefix?: string;
    mainSummaryUnitString?: string;
    subSummaryValue?: string | number;
    subSummaryPrefix?: string;
    subSummaryUnitString?: string;
  };
  buttons?: any[];
}

const Table = ({ tableHeight, noItemMessage, ...props }: Props) => {
  const marginHeight = tableHeight ? (tableHeight / 2 - 36 > 0 ? tableHeight / 2 - 36 : 0) : 238; // default height equiv. 560px
  return (
    <TableStyle>
      <DataTable
        {...props}
        persistTableHead
        noDataComponent={
          <NotFoundContainer
            text={noItemMessage || '조회된 내역이 없어요.'}
            marginBottom={marginHeight}
            marginTop={marginHeight}
          />
        }
      />
    </TableStyle>
  );
};

const TableStyle = styled.div`
  p {
    margin: 0;
  }
`;

export const TableHeaderContainer = styled.div<{
  justifyContent?: CSS.Properties['justifyContent'];
  alignItems?: CSS.Properties['alignItems'];
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'space-between'};
  flex-wrap: wrap;
  gap: 1rem;

  > div {
    display: flex;
    gap: 0.5rem;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: ${({ justifyContent }) => justifyContent ?? 'space-between'};
    align-items: ${({ justifyContent }) => justifyContent ?? 'center'};
  }
`;

const TableHeaderText = styled.span`
  font-size: 1.25rem;
  > span {
    color: ${({ theme }) => theme.main[600]};
  }
`;

const TableButtonContainer = styled.div<{ side?: 'left' | 'right' }>`
  display: flex;
  flex-wrap: wrap;

  align-items: center;
  gap: 0.5rem;

  justify-content: ${({ side }) =>
    side === 'left' ? 'flex-start' : side === 'right' ? 'flex-end' : 'center'};

  > button {
    margin: 0;
  }
`;

export const TableHeaderContainerV2 = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  input {
    margin: 0;
  }

  .inner-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .inner-grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  /* @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  } */
`;

export const TableHeaderArea = ({ summary, buttons }: TableHeaderProps) => {
  return (
    <TableHeaderContainer>
      {summary && (
        <TableHeaderText>
          {summary?.mainSummaryUnitString && summary.mainSummaryValue !== undefined && (
            <>
              {summary?.mainSummaryPrefix || ''}

              <span>{Format.formatNumber(summary.mainSummaryValue)}</span>
              {summary?.mainSummaryUnitString}
            </>
          )}
          {summary?.subSummaryValue !== undefined ? ' / ' : ''}
          {summary?.subSummaryUnitString && summary.subSummaryValue !== undefined && (
            <>
              {summary?.subSummaryPrefix || ''}
              <span>{Format.formatNumber(summary.subSummaryValue)}</span>
              {summary?.subSummaryUnitString}
            </>
          )}
        </TableHeaderText>
      )}
      {buttons && <TableButtonContainer>{buttons}</TableButtonContainer>}
    </TableHeaderContainer>
  );
};

export default Table;
