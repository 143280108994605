import styled from 'styled-components';

import questionMark from '../assets/images/questionMark.svg';
import questionMark_large from '../assets/images/questionMark_large.svg';
import textMark from '../assets/images/textMark.png';
export const QuestionMark = styled.span<{ size?: '15' | '24' }>`
  background: ${({ size }) =>
    size
      ? size === '15'
        ? `url(${questionMark})`
        : `url(${questionMark_large})`
      : `url(${questionMark})`};
  width: ${({ size }) => (size ? `${size}px` : '15px')};
  height: ${({ size }) => (size ? `${size}px` : '15px')};
  margin-left: 3px;
  display: inline-block;
  position: relative;
  margin-bottom: -1.5px;
  > span {
    display: none;
    position: absolute;
  }
  :hover > span {
    background: 100% url(${textMark});
    display: flex;
    flex-direction: column;
    width: 180px;
    height: 100px;
    top: 11px;
    right: -40px;
    text-align: start;
    padding: 25px 19px;
    &.report {
      box-shadow: 0px 0px 10px 0px #00000040;
      background: #ffffff;
      width: 330px;
      padding: 10px;
      justify-content: center;
      border-radius: 5px;
      > span {
        width: 100%;
        > p {
          margin-bottom: 5px;
          font-weight: 400;
          color: #555555;
          &:first-child {
            font-weight: 600;
          }
          &:nth-child(4) {
            margin-bottom: 0;
            color: #f23b3b;
          }
        }
      }
    }
    > p {
      color: #555555;
      font-weight: 400;
      :first-child {
        margin-bottom: 10px;
        font-weight: 700;
      }
    }
  }
`;
