import React, { useEffect, useMemo, useState } from 'react';
import { goingToExitSeatColumn } from '../../../data/columns';
import CommonWrapper from '../CommonWrapper';
import { getRemainTime } from '../../../lib/api/v2/seat';
import Table from '../../common/Table';

const GoingToExitSeatList = () => {
  const [list, setList] = useState<any[]>([]);
  const fetch = async () => {
    try {
      const { data } = await getRemainTime();
      setList([...data.map((seat: any) => ({ ...seat, level: `Lv.${seat.level}` }))]);
    } catch (error) {}
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <CommonWrapper title="퇴실 예정석" isConfirmButton={false} isCancelButton={false}>
      <div style={{ paddingBottom: '2rem' }}>
        <Table data={list} columns={goingToExitSeatColumn} />
      </div>
    </CommonWrapper>
  );
};

export default GoingToExitSeatList;
