import React, { ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';

import styled from 'styled-components';

import { ReactComponent as Close } from '../../assets/images/close.svg';
import { swalReturnWarning } from '../../util/commonFunctions';
import { Button } from '../common/Button';

export const IMAGE_TYPE = 'image/jpeg, image/png';
export const DOCUMENT_TYPE = 'image/jpeg, image/png, application/pdf';

interface FileSelectorProps {
  fileRef: React.RefObject<HTMLInputElement>;
  currentFile: string;
  setCurrentFile: Dispatch<SetStateAction<string>> | ((filename: string) => void);
  fileType: string;
  disabled?: boolean;
}

const FileName = styled.div`
  width: 100%;
  height: 2rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.main[600]};

  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;

  background: ${({ theme }) => theme.main[100]};

  span {
    width: calc(100% - 2rem);
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    color: ${({ theme }) => theme.main[600]};
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: none;
    padding: 0;
    border: none;
  }
`;

const FileSelector = ({
  fileRef,
  currentFile,
  setCurrentFile,
  fileType,
  disabled,
}: FileSelectorProps) => {
  const handleSelectFile = () => {
    if (!fileRef.current) return;
    fileRef.current.click();
  };

  const onClickRemoveFile = () => {
    if (!fileRef.current) return;
    fileRef.current.value = '';
    setCurrentFile('');
  };

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!fileRef.current) return;
    const target = e.currentTarget;
    const file = target.files as FileList;
    if (file.length === 0) return;
    if (file[0].size > 1024 * 1024 * 10) {
      setCurrentFile(''); // 설정된 파일명 초기화 (기존 파일명이 유지되어도 사용 불가)
      fileRef.current.value = ''; // 파일 초기화
      return swalReturnWarning('파일 크기는 10MB를 초과할 수 없습니다.');
    }
    setCurrentFile(file[0].name);
  };

  const FileText = useMemo(() => {
    if (disabled && currentFile) return '승인된 파일은 재업로드를 할 수 없어요.';
    if (disabled) return '이미 승인되었으므로 업로드를 할 수 없어요.';
    if (currentFile.length === 0) return `등록된 파일이 없어요.`;
    return (
      <FileName>
        <span>{currentFile.startsWith('http') ? '파일이 등록되어 있습니다.' : currentFile}</span>
        <button onClick={onClickRemoveFile}>
          <Close />
        </button>
      </FileName>
    );
  }, [disabled, currentFile, fileType]);

  return (
    <div
      style={{
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'start',
        width: '100%',
        gridTemplateColumns: '6rem auto',
        gap: '0.5rem',
      }}>
      <Button
        buttonType="default"
        onClick={handleSelectFile}
        style={{ width: '6rem' }}
        disabled={disabled}>
        업로드
      </Button>
      <input
        type="file"
        accept={fileType || ''}
        ref={fileRef}
        style={{ display: 'none' }}
        onChange={onChangeFile}
      />
      {FileText}
    </div>
  );
};

export default FileSelector;
