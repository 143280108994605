import React from 'react';
import { Dispatch, SetStateAction } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

interface PaginationProps {
  setCurrentPage: Dispatch<SetStateAction<number>>;
  currentPage: number;
  totalcount: number;
  postsPerPage: number;
  fetchData?: any;
}

const PaginationStyle = styled.div`
  width: fit-content;
  font-size: 18px;
  color: ${({ theme }) => theme.indigo};
  font-weight: 600;
  margin: 30px auto;
  ul {
    display: flex;

    li {
      a {
        border-radius: 5px;
        padding: 5px 11px;
        margin: 10px;
        font-size: 14px;
        :focus {
          box-shadow: none;
        }
      }
    }
  }
  .activePage {
    a {
      color: #fff !important;
      background-color: ${({ theme }) => theme.main[600]};
    }
  }
`;

const Pagination: React.FunctionComponent<PaginationProps> = ({
  setCurrentPage,
  totalcount,
  postsPerPage,
  currentPage,
  fetchData,
}) => {
  let page = Math.ceil(totalcount / postsPerPage);
  return (
    <>
      {totalcount > 0 && (
        <PaginationStyle style={{ margin: postsPerPage === 6 ? '0 auto' : '0' }}>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={(e) => {
              // console.log(e.selected + 1);
              setCurrentPage(e.selected + 1);
              fetchData && fetchData(e.selected + 1);
            }}
            forcePage={currentPage - 1}
            pageRangeDisplayed={5}
            pageCount={page}
            previousLabel="<"
            marginPagesDisplayed={1} //   renderOnZeroPageCount={null}
            activeClassName={'activePage'}
          />
        </PaginationStyle>
      )}
      {/* <Items currentItems={currentItems} /> */}
    </>
  );
};
export default Pagination;
