import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  color: #555;
  overflow: auto;

  min-height: 28rem;
  /* max-height: 40rem; */

  thead {
    width: 100%;
    display: table;
    overflow: auto;
    font-weight: 600;
    position: sticky;

    top: 0;
    background-color: white;
    border-bottom: 1px solid #eaeaea;
  }
  tbody {
    width: 100%;
    display: table;

    tr {
      &:nth-child(${(props) => (props.isStripe === 'odd' ? 'odd' : 'even')}) {
        /* border-radius: 0.5rem; */
        background-color: ${({ isStripe }) => (isStripe ? '#f5f5f5' : '#ffffff')};
      }
    }
  }
`;

const Th = styled.th`
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: 3rem;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  padding: 0 0.5rem;
  user-select: none;
  white-space: nowrap;
  border-bottom: ${({ isStripe }) => (isStripe ? 'none' : '1px solid #eaeaea')};
  background-color: white;
`;
const Tr = styled.tr`
  overflow: auto;
  width: 100%;
  background-color: ${({ isSelected }) => (isSelected ? '#1a7cfd14' : '#fff')};

  &:hover {
    background-color: #f5f5f5;
  }
`;

const Td = styled.td`
  padding: 0.5rem;
  height: 3rem;
  text-align: center;
  vertical-align: middle;
  border-bottom: ${({ isStripe }) => (isStripe ? 'none' : '1px solid #eaeaea')};

  > div {
    width: ${(props) => (props.width ? props.width : 'auto')};
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const TableComponent = (props) => {
  return (
    <Container>
      <Table isStripe={props.isStripe}>
        <thead>
          {props.columns.map((column) => (
            <Th width={column.width ? column.width : 'auto'}>
              <div
                style={{
                  width: column.width ? column.width : '100%',
                  display: 'flex',
                  justifyContent: column.align ? column.align : 'center',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <span>{column.Header}</span>
              </div>
            </Th>
          ))}
        </thead>
        <tbody>
          {props.data.length > 0 &&
            props.data.map((item) => (
              <TableRow
                rowItem={item}
                onClickRow={props.onClickRow ? props.onClickRow : undefined}
                columns={props.columns}
              />
            ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default TableComponent;

const TableRow = (props) => {
  return (
    <Tr onClick={props.onClickRow ? () => props.onClickRow(props.rowItem) : undefined}>
      {props.columns.map((column) => {
        return (
          <Td style={{ width: column.width }} width={column.width}>
            <div>{props.rowItem[column.accessor] || '-'}</div>
          </Td>
        );
      })}
    </Tr>
  );
};
