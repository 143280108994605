import React, { Fragment, useState } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import moment from 'moment';
import { ko } from 'date-fns/locale';
import Swal from 'sweetalert2';
import { deleteCoupon, getCouponData, getCouponIssuedData, getUserData } from '../../lib/api';
import { useEffect } from 'react';
import { couponUsedColumnUser } from '../../data/columns';
import DataTable from 'react-data-table-component';
import PaginationComponent from '../../components/pagination';
import { getInfo } from '../../lib/api/v2/user';
import Table from '../../components/common/Table';
import PageContainer from '../../components/common/PageContainer';

const Coupon = (props) => {
  const [merchantId, setMerchantId] = useState(-1);
  const [startTableDate, setStartTableDate] = useState(new Date('2020-01-01'));
  const [endTableDate, setEndTableDate] = useState(new Date('2030-01-01'));
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [couponData, setConponData] = useState([]);
  const [tabMenu, setTabMenu] = useState(1);
  const [count, setCount] = useState(0);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);

  const getData = async () => {
    try {
      const { data } = await getInfo();
      const { merchantId, phoneNum } = data;
      setMerchantId(merchantId);
      let res;
      if (tabMenu === 1) {
        res = await getCouponIssuedData(
          merchantId,
          moment(startTableDate).format('YYYY-MM-DD 00:00:00'),
          moment(endTableDate).format('YYYY-MM-DD 23:59:59'),
          phoneNum,
          currentPage,
          postsPerPage,
        );
      } else {
        res = await getCouponData(
          merchantId,
          moment(startTableDate).format('YYYY-MM-DD 00:00:00'),
          moment(endTableDate).format('YYYY-MM-DD 23:59:59'),
          phoneNum,
          currentPage,
          postsPerPage,
          true,
        );
      }

      if (res.status === 200) {
        setCount(res.data.totalPage);

        setConponData(
          res.data.coupons,
          res.data.coupons.map((item) => {
            item.create = moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss');
            item.couponType =
              +item.type === 1
                ? '일회권'
                : +item.type === 2
                ? '기간권'
                : +item.type === 3
                ? '시간정액권'
                : +item.type === 4
                ? '세미나실'
                : '사물함';
            item.expdate = `${moment(item.expStartDate).format('YYYY-MM-DD HH:mm:ss')} ~
                    ${moment(item.expEndDate).format('YYYY-MM-DD HH:mm:ss')}`;
            item.used = +item.isUsed === 0 ? '사용전' : '사용완료';
            item.expirationDate = +item.type === 3 ? item.day : '-';
          }),
        );
      }
    } catch (error) {
      Swal.fire('데이터를 불러오는데 실패하였습니다. 다시 시도해주세요.');
    }
  };

  useEffect(() => {
    getData();
  }, [tabMenu, startTableDate, endTableDate, currentPage]);

  return (
    <Fragment>
      <Breadcrumbs parent="마일리지 & 쿠폰" title="쿠폰" />
      <PageContainer title="쿠폰 내역">
        <Table data={couponData} columns={couponUsedColumnUser} striped={true} center={true} />
        {count > 0 && (
          <PaginationComponent
            setCurrentPage={setCurrentPage}
            setPostsPerPageAdd={setPostsPerPageAdd}
            setPostsPerPageAdd1={setPostsPerPageAdd1}
            setPageNum={setPageNum}
            totalLength={count}
            postsPerPage={postsPerPage}
            currentPage={currentPage}
            pageNum={pageNum}
            postsPerPageAdd={postsPerPageAdd}
            postsPerPageAdd1={postsPerPageAdd1}
            pagecount={true}
          />
        )}
      </PageContainer>
    </Fragment>
  );
};

export default Coupon;
