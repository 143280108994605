import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../layout/breadcrumb';
import { Container, Row, Col, Card, CardBody, Form, Input, Label } from 'reactstrap';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import { ko } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DetailsModal from '../components/modal/detailsModal';
import moment from 'moment';
import {
  merchantId_api,
  ticketForceExit,
  ticketUsing,
  TicketOverTime,
  userTicketUpdate,
  userTicketBlock,
  userList,
  allTicketList,
} from '../lib/api';
import { ticketListColumns } from '../data/columns';
import 'moment/locale/ko';
import PaginationComponent from '../components/pagination';
import Swal from 'sweetalert2';
import AddTimeModal from '../components/modal/addTimeModal';
import TicketCustom, { ExampleCustomInput, LabelRow } from './ticketCustom';
import { exportExcel, payLog } from '../components/common';
import { AddBtn } from './lockerChange';
import { DatePickerStyle } from './seatInfo';
import SelectDate from '../components/selectDate';
import { ToggleBtn } from './seatList';
import TicketInfoModal from '../components/modal/ticketInfoModal';
import PerPageSelect from '../components/table/PerPageSelect';
import Table, { TableHeaderContainer, TableHeaderContainerV2 } from '../components/common/Table';
import PageContainer from '../components/common/PageContainer';

import { Button, ExcelButton, ToggleButton } from '../components/common/Button';
import { SearchButton } from '../components/input';
import DatePickerComponent from '../components/filter/DatePicker';
import { Dropdown } from '../components/common/Dropdown';
import Search from '../components/common/Search';

// let merchantId = "";

import IconSave from '../assets/images/button/icon_button_save_inverted.svg';
import IconExcel from '../assets/images/button/icon_button_excel.svg';
import TooltipText from '../components/common/TooltipText';
import useMousePosition from '../lib/hooks/useMousePosition';

export const LabelS = styled(Label)`
  margin-right: 1vw;
  input {
    margin-right: 0.4vw;
    position: static;
    margin-left: 0;
  }
`;
export const InputS = styled(Input).attrs({ required: true })``;

export const InputWithP = styled.span`
  display: flex;
  align-items: center;

  input {
    width: 40%;
    margin-right: 0.2vw;
    margin-top: 0;
    :not(:first-child) {
      margin-left: 0.8vw;
    }
  }
  p {
    margin-bottom: 0;
  }
`;

export const secondToHour = (seconds) => {
  if (seconds >= 0) {
    const hour =
      parseInt(seconds / 3600) < 10 ? '0' + parseInt(seconds / 3600) : parseInt(seconds / 3600);
    const min =
      parseInt((seconds % 3600) / 60) < 10
        ? '0' + parseInt((seconds % 3600) / 60)
        : parseInt((seconds % 3600) / 60);
    const sec = seconds % 60 < 10 ? '0' + (seconds % 60) : seconds % 60;
    return hour + ':' + min + ':' + sec;
  } else {
    const hour =
      Math.abs(parseInt(seconds / 3600)) < 10
        ? '0' + parseInt(seconds / 3600)
        : parseInt(seconds / 3600);
    const min =
      parseInt((seconds % 3600) / 60) < 10 && parseInt((seconds % 3600) / 60) > 0
        ? '0' + parseInt((seconds % 3600) / 60)
        : parseInt((seconds % 3600) / 60);
    const sec =
      seconds % 60 < 10 && parseInt((seconds % 3600) / 60) > 0
        ? '0' + (seconds % 60)
        : seconds % 60;
    return `${hour}:${Math.abs(min)}:${Math.abs(sec)} 초과`;
  }
};

const TicketInfo = ({ setIsLoading }) => {
  const mousePosition = useMousePosition();
  const [merchantId, setMerchantId] = useState('');
  const [tagModal, setTagModal] = useState(false); // 이용권 정보 여닫기
  const [lists, setLists] = useState([]); // 테이블에 넘겨줄 데이터 (가변값)
  const [defaultList, setDefaultList] = useState([]); // 초기 데이터 (고정값)

  const [startDate, setStartDate] = useState(
    // 사용자 지정 시작 날짜
    moment().startOf('isoWeek').toDate(),
  );
  const [endDate, setEndDate] = useState(
    // 사용자 지정 끝 날짜
    moment().endOf('isoWeek').toDate(),
  );
  const [searchKeyword, setSearchKeyword] = useState(null); // 검색어

  /** 페이지네이션 정보 **/
  const [currentPage, setCurrentPage] = useState(1); //현재 페이지
  const [postsPerPage, setPostsPerPage] = useState(10); // 페이지당 보여줄 목록 개수

  const [selected, setSelected] = useState(null); // 자세히 버튼 클릭 할 때마다 해당 데이터의 index 값으로 설정됨

  /** 시간/기간 수정 모달 관련 */
  const [isModal, setIsModal] = useState(false); //  모달 오픈 여부
  const [addTime, setAddTime] = useState(null); // 추가 시간 -> 일회권의 경우
  const [addDay, setAddDay] = useState(null); // 추가 일자 -> 기간권의 경우
  const [amount, setAmount] = useState(0); // 금액
  const [payType, setPayType] = useState('cash'); // 결제 종류
  const [addTimeCheck, setAddTimeCheck] = useState(false); // 시간/기간 수정 시 해당 정보 표시 여부
  const [sign, setSign] = useState('+'); // 적립/차감 여부

  const [isCustom, setIsCustom] = useState(false); // 입장권 발행 모달 여닫는 변수
  const [detailUserModal, setDetailUserModal] = useState(false); // 전화번호 클릭 시 나오는 회원 정보 모달 여닫는 변수
  const [userData, setUserData] = useState({}); // 회원 정보 모달에 넘겨주는 회원 정보

  // 초과결제 filter on/off
  const [isShowOverTime, setIsShowOverTime] = useState(false);

  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  const [searchOption, setSearchOption] = useState('전화번호');

  const [selectedItem, setSelectedItem] = useState(null);

  const [tooltip, setTooltip] = useState({
    enabled: false,
    message: '',
  });

  let searchKeywordData = [
    '전화번호',
    '권번',
    '유형',
    '등급',
    '사용가능여부',
    '사용상태',
    '퇴장처리',
    '입장권차단',
  ];

  useEffect(() => {
    getMerchantId();
  }, []);

  useEffect(() => {
    if (merchantId) {
      getUserTicketListApi(merchantId);
      setCurrentPage(1);
    }
  }, [merchantId, isShowOverTime, startDate, endDate]);

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      setMerchantId(data.id);
    }
  };

  // Open Modal
  const handleSetLists = (item) => {
    // 자세히 버튼 클릭 시 실행
    setSelectedItem(item);
    // console.log(e.target.id, idx);
    // tagToggle();
    // setTagModal(true);
  };

  const tagToggle = () => {
    //onToggle

    // if (tagModal === false) {
    //   setTagModal(true);
    // } else {
    //   setTagModal(false);
    // }
    if (selectedItem) setSelectedItem(null);

    if (addTimeCheck) {
      setAddTimeCheck(!addTimeCheck);
    }
  };

  const sendDetailData = (phoneNum) => {
    getUserList(phoneNum);
  };

  const getUserList = async (phoneNum) => {
    const result = await userList(merchantId, phoneNum);
    if (result && result.status === 200) {
      setUserData(result.data[0]);
      setDetailUserModal(!detailUserModal);
    }
  };

  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  const getUserTicketListApi = async (merchantId) => {
    setIsLoading(true);
    if (merchantId) {
      const data = await allTicketList(
        merchantId,
        moment(startDate).format('YYYYMMDD000000'),
        moment(endDate).format('YYYYMMDD235959'),
      );

      if (data.status === 200) {
        let changeList = [
          // 테이블에 표시할 가공된 데이터 값
          data.data,
          data.data.map((item, idx) => {
            item.typeName = // 입장권 이름 출력
              item.type === 1 && item.seatNumber
                ? ` 일회권 (${item.seatNumber}번)`
                : item.type === 1 && !item.seatNumber
                ? ' 일회권'
                : item.type === 4 && item.seatNumber
                ? ` 세미나실 (${item.seatNumber}번)`
                : item.type === 4 && !item.seatNumber
                ? ' 세미나실'
                : item.type === 5 && item.seatNumber
                ? ` 사물함 (${item.seatNumber}번)`
                : item.type === 5 && !item.seatNumber
                ? ' 사물함'
                : item.type === 2
                ? ` 기간권 - ${item.seatType === 0 ? '자유석' : '고정석'}`
                : item.type === 3
                ? '시간정액권'
                : '사물함';

            item.type1 = // 입장권 이름 출력
              item.type === 1 ? (
                <p style={{ color: '#EBAF22' }}>
                  일회권
                  {item.seatNumber ? `(${item.seatNumber}번)` : ''}
                </p>
              ) : item.type === 2 ? (
                <>
                  {item.seatType === 0 ? (
                    <p style={{ color: '#E03D64' }}>기간권 - 자유석</p>
                  ) : (
                    <p style={{ color: '#1BBABC' }}>기간권 - 고정석</p>
                  )}
                </>
              ) : item.type === 3 ? (
                <p style={{ color: '#33946B' }}>시간정액권</p>
              ) : item.type === 4 ? (
                <p style={{ color: '#3E67B6' }}>
                  세미나실
                  {item.seatNumber ? `(${item.seatNumber}번)` : ''}
                </p>
              ) : (
                <p style={{ color: '#AB84FF' }}>
                  사물함
                  {item.seatNumber ? `(${item.seatNumber}번)` : ''}
                </p>
              );
            // 전화번호 태그 (클릭 시  사용자 정보 모달 open)
            item.phoneNumtag = (
              <div
                onClick={() => {
                  sendDetailData(item.phoneNum);
                }}
                className="phoneNum-click">
                {item.phoneNum}
              </div>
            );
            // 마지막 입장 시간
            item.lastIn = item.lastIn ? moment(item.lastIn).format('YYYY-MM-DD HH:mm:ss') : '';
            // 이용권 시작 날짜
            item.startDate = item.startDate
              ? moment(item.startDate).format('YYYY-MM-DD HH:mm:ss')
              : '';

            const t1 = moment(item.endDate, 'YYYY-MM-DD HH:mm:ss'); // 이용권 종료 날짜
            const t3 = moment(new Date(), 'YYYY-MM-DD HH:mm:ss'); // 오늘 날짜

            item.action = // ?
              +item.type === 2 && +item.seatType === 0 // 기간권이고 자유석일때
                ? secondToHour(item.remainTime)
                : secondToHour(item.remainTime + item.usedTime);

            // 유효기간 -> 시간정액권, 사물함만 존재
            // 종료시간이 현재시간보다 클 경우 표시
            item.expireDate2 =
              (item.type === 3 || item.type === 5) &&
              Math.ceil(moment.duration(moment(t1).diff(t3)).asDays()) > 0
                ? `${Math.ceil(moment.duration(moment(t1).diff(t3)).asDays())}일`
                : '-';

            item.T_isUsing =
              item.isUsing === 1 ? (
                <div>사용중</div>
              ) : item.isUsing === 2 ? (
                <div style={{ color: '#F04E36' }}>시간 초과</div>
              ) : item.lastOut ? (
                <div style={{ color: '#53852A' }}>퇴장완료</div>
              ) : (
                <div>사용전</div>
              );

            item.usingTimeKeyword =
              item.isUsing === 1
                ? '사용중'
                : item.isUsing === 2
                ? '시간초과'
                : item.lastOut
                ? '퇴장완료'
                : '사용전';

            item.blockKeyword = item.block === 1 ? '티켓사용불가' : '티켓사용가능';
            item.T_block =
              item.block === 1 ? (
                <p style={{ color: '#F04E36' }}>티켓사용불가</p>
              ) : (
                <p>티켓사용가능</p>
              );
            item.action2 = (
              <button className="btn-primary btn-sm" id={idx} onClick={(e) => handleSetLists(item)}>
                자세히
              </button>
            );

            item.outButton =
              item.isUsing === 1 ? (
                <button className="btn-secondary btn-sm" onClick={() => outTicket(item)}>
                  강제퇴장처리
                </button>
              ) : item.isUsing === 2 ? (
                <button className="btn-success btn-sm" onClick={() => additional(item)}>
                  초과결제해제
                </button>
              ) : (
                <button disabled className="btn-sm" onClick={() => outTicket(item)}>
                  퇴장처리
                </button>
              );

            item.last2 = item.lastOut ? moment(item.lastOut).format('YYYY-MM-DD HH:mm:ss') : '';
            item.isUsingKeyword =
              item.isUsing === 1
                ? '강제퇴장처리'
                : item.isUsing === 2
                ? '초과결제해제'
                : '퇴장처리';
            const nowTime = moment();
            const endTime = moment(item.endDate);
            const startTime = moment(item.startDate);
            const lastInTime = moment(item.lastIn);
            const lastOutTime = moment(item.lastOut);
            const today_diff = moment.duration(endTime.diff(nowTime)).asMilliseconds();
            //현재시간기준으로 시작시간하고의 차이
            const today_diff2 = moment.duration(nowTime.diff(startTime)).asMilliseconds();
            const remainTimeData = moment.duration(nowTime.diff(lastInTime)).asSeconds();
            const seminarNotUsing = moment.duration(endTime.diff(nowTime)).asSeconds();
            const excessData = moment.duration(nowTime.diff(lastOutTime)).asSeconds();
            //마지막으로 입장했는가 퇴장했는가
            const lastTime = moment.duration(lastInTime.diff(lastOutTime)).asMilliseconds();

            item.remainTimeSec =
              //일회권, 기간권, 시간정액권 사용중이면서, 퇴장기록이 없는경우
              ((item.isUsing === 1 && !item.lastOut && item.lastIn) ||
                (item.isUsing === 1 && item.lastOut && item.lastIn && lastTime > 0)) &&
              (item.type === 1 || item.type === 2 || item.type === 3)
                ? secondToHour(item.remainTime - Math.ceil(remainTimeData))
                : //세미나실인경우 (입실처리x 시간초과x)
                item.type === 4 && today_diff > 0 && today_diff2 > 0
                ? Math.ceil(seminarNotUsing)
                : //세미나실인경우 (입실처리x 시간초과o)
                item.type === 4 && today_diff < 0 && today_diff2 > 0
                ? '00:00:00'
                : // 일회권,기간권, 시간정액권 사용중이면서 시간을 초과한경우
                item.isUsing === 2 && item.lastOut
                ? +Math.ceil(excessData)
                : secondToHour(item.remainTime); // 위 조건에 해당하지 않을 때는 백엔드 remanTime 그대로 출력!
            //잔여시간 조회

            //엑셀
            item.remainTime2 =
              // 일회권, 기간권, 시간정액권 사용 중이면서, 퇴장 기록이 없는 경우
              ((item.isUsing === 1 && !item.lastOut && item.lastIn) ||
                (item.isUsing === 1 && item.lastOut && item.lastIn && lastTime > 0)) &&
              (item.type === 1 || item.type === 2 || item.type === 3)
                ? secondToHour(item.remainTime - Math.ceil(remainTimeData))
                : // 세미나실인 경우 (입실 처리x, 시간 초과x)
                item.type === 4 && today_diff > 0 && today_diff2 > 0
                ? secondToHour(Math.ceil(seminarNotUsing))
                : // 세미나실인 경우 (입실 처리x, 시간 초과o)
                item.type === 4 && today_diff < 0 && today_diff2 > 0
                ? '00:00:00'
                : // 일회권, 기간권, 시간정액권 사용 중이면서 시간을 초과한 경우
                item.isUsing === 2 && item.lastOut
                ? `${secondToHour(Math.ceil(excessData))} 초과`
                : // 그외에 조건
                item.type === 2 && item.endDate
                ? `${moment(moment(item.endDate).format('YYYY-MM-DD')).diff(
                    moment().format('YYYY-MM-DD'),
                    'days',
                  )}일 남음`
                : (item.type === 4 || item.type === 5) && item.endDate
                ? `${moment(item.endDate).diff(moment(), 'days')}일 남음`
                : secondToHour(item.remainTime);

            item.ticketBlock = // 사용가능
              //입장권 차단 활성화
              //1. 끝나는 시간이 지나지 않았고, 차단이 가능한 상태인경우(block:0), 남아있는 시간이 있는 경우
              (today_diff > 0 && +item.block === 0 && item.remainTime > 0) ||
              (item.type === 5 && today_diff > 0 && item.block !== 1) ? (
                <ToggleBtn>
                  <input
                    type="checkbox"
                    checked={item.block === 0}
                    onClick={() => blockApiFunc(item)}
                  />
                  <span className="onoff-switch"></span>
                </ToggleBtn>
              ) : //입장권 허용 활성화
              //1. 끝나는 시간이 지나지 않았고,차단되어있으면서, 남아있는 시간이 있는 경우
              today_diff > 0 &&
                +item.block === 1 &&
                item.remainTime > 0 &&
                (item.type === 2 || item.type === 3) ? (
                <ToggleBtn>
                  <input
                    type="checkbox"
                    checked={item.block === 0}
                    onClick={() => blockApiFunc(item)}
                  />
                  <span className="onoff-switch"></span>
                </ToggleBtn>
              ) : (
                <>
                  {/*조작할 수 없는 상태 disabled -> 일회권,세미나실 차단된 경우() */}
                  <ToggleBtn
                    onMouseEnter={() => {
                      setTooltip({
                        enabled: true,
                        message: '유효기간이 지났거나 입장권 특성 상 허용이 불가능한 입장권입니다.',
                      });
                    }}
                    onMouseLeave={() => setTooltip({ ...tooltip, enabled: false })}>
                    <input
                      type="checkbox"
                      disabled
                      // onClick={() => blockApiFunc(item)}
                    />
                    <span className="onoff-switch disabled"></span>
                  </ToggleBtn>
                </>
              );

            item.ticketBlockKeyword =
              //입장권 차단 활성화 키워드
              //1. 끝나는 시간이 지나지 않았고, 차단이 가능한 상태인경우(block:0), 남아있는 시간이 있는 경우
              today_diff > 0 && +item.block === 0 && item.remainTime > 0
                ? '입장권 차단'
                : //입장권 허용 활성화
                //1. 끝나는 시간이 지나지 않았고,차단되어있으면서, 남아있는 시간이 있는 경우
                today_diff > 0 && +item.block === 1 && item.remainTime > 0
                ? '입장권 허용'
                : '입장권 차단';
          }),
        ];

        if (searchKeyword) {
          if (isShowOverTime) {
            searchData(
              changeList[0].filter((item) => item.isUsingKeyword?.includes('초과')),
              true,
            );
            setDefaultList(changeList[0].filter((item) => item.isUsingKeyword?.includes('초과')));
          } else {
            searchData(changeList[0], true);
            setDefaultList(changeList[0]);
          }
        } else {
          if (isShowOverTime) {
            setLists(changeList[0].filter((item) => item.isUsingKeyword?.includes('초과')));
            setDefaultList(changeList[0].filter((item) => item.isUsingKeyword?.includes('초과')));
          } else {
            setLists(changeList[0]);
            setDefaultList(changeList[0]);
          }
        }
      }
    }
    setIsLoading(false);
  };

  const blockApiFunc = async (item) => {
    Swal.fire({
      showCancelButton: true,
      confirmButtonColor: '#04c9db',
      cancelButtonColor: '#F53C14',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      reverseButtons: true,
      title: `${item.block === 0 ? '입장권 차단' : '입장권 허용'}하시겠습니까?`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // 퇴장처리->입장권차단 순서로 진행
        // 1. 입장되어있는 이용권 퇴장처리(isUsing이 1일 경우에만 퇴장처리)
        if (item.isUsing === 1 && item.block === 0) {
          const data = await ticketUsing(item.userId, item.merchantId);
          if (data.status === 200) {
            //퇴장처리
            const res = await ticketForceExit({
              merchantId: item.merchantId,
              id: item.id,
              userId: item.userId,
              seatId: data.data.seatId,
            });
            //퇴장 후 처리(차단-block)
            if (res.status === 200) {
              if (item.block === 0) {
                let result = await userTicketBlock({
                  ticketId: item.id,
                  merchantId: item.merchantId,
                  block: 1,
                  // type: 4,
                });

                if (result.status === 200) {
                  Swal.fire({
                    icon: 'success',
                    title: '입장권 차단이 완료되었습니다.',
                    timer: 3000,
                  }).then(() => getUserTicketListApi(merchantId));
                }
              }
            }
          }
        } //2. 초과 결제 처리
        else if (item.isUsing === 2) {
          Swal.fire({
            icon: 'error',
            title: '초과결제를 해야합니다. 초과결제를 처리하신 후 다시 시도해주세요',
            timer: 3000,
          });
        } // 3. 입장하지 않은 상태의 이용권 차단
        else if (
          (item.isUsing === 0 && item.block === 0) ||
          (item.isUsing === 3 && item.block === 0)
        ) {
          const result = await userTicketBlock({
            ticketId: item.id,
            merchantId: item.merchantId,
            block: 1,
            // type: 4,
          });
          if (result.status === 200) {
            Swal.fire({
              icon: 'success',
              title: '입장권 차단이 완료되었습니다.',
              timer: 3000,
            }).then(() => getUserTicketListApi(merchantId));
          }
        } // 4. 이미 차단된 이용권
        else if (item.block === 1) {
          let resultEnter = await userTicketBlock({
            ticketId: item.id,
            merchantId: item.merchantId,
            block: 0,
          });

          if (resultEnter.status === 200) {
            Swal.fire({
              icon: 'success',
              title: '입장권 허용이 완료되었습니다.',
              timer: 3000,
            }).then(() => getUserTicketListApi(merchantId));
          }
        }
      }
    });
  };

  const outTicket = async (e) => {
    const nowTime = moment();
    const diffTime = moment.duration(nowTime.diff(e.endDate)).asMilliseconds();
    if (diffTime > 0) {
      Swal.fire(
        '이미 만료되어 퇴장처리가 불가능한 이용자 입니다.',
        '초과결제 취소가 필요한경우 퇴장여부의 초과결제 취소를 눌러주세요',
      );
    } else {
      Swal.fire({
        title: '퇴장처리를 하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F53C14',
        cancelButtonColor: '#04c9db',
        confirmButtonText: '퇴장',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = await ticketUsing(e.userId, e.merchantId);
          if (data.status === 200) {
            let result = await ticketForceExit({
              merchantId: e.merchantId,
              id: e.id,
              userId: e.userId,
              seatId: data.data.seatId,
            });

            if (result.status === 200) {
              Swal.fire('퇴장처리가 완료되었습니다.');
              getMerchantId();
              getUserTicketListApi(merchantId);
            } else {
              Swal.fire('퇴장처리 중 에러가 발생했습니다. 다시 시도해주세요.');
            }
          }
        }
      });
    }
  };

  //입장권 초과결제
  const additional = async (ticket) => {
    Swal.fire({
      title: '초과결제 해제 하시겠습니까?',
      text: '초과결제 해제시 해당 이용권은 퇴장완료로 변경됩니다.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: '#04c9db',
      confirmButtonText: '초과결제 해제',
      cancelButtonText: '창닫기',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await TicketOverTime({
          merchantId: ticket.merchantId,
          ticketId: ticket.id,
        });

        if (res.status === 200) {
          Swal.fire('초과 결제 해제가 완료되었습니다.');
          getMerchantId();
          getUserTicketListApi(merchantId);
        } else {
          Swal.fire('초과 결제 해제 처리 중 에러가 발생했습니다. 다시시도해주세요.');
        }
      }
    });
  };

  const handleSearchKeyword = (keyword) => {
    if (keyword === '') {
      setSearchKeyword(null);
    } else {
      setSearchKeyword(keyword);
    }
  };

  const handleSave = async (ticket) => {
    // onSave

    if (addTime || addDay) {
      const maxTime = Math.ceil(
        moment.duration(moment(ticket.endDate).diff(new Date())).asSeconds() + 30,
      );

      const h = Math.floor(maxTime / 3600);
      const m = Math.floor((maxTime % 3600) / 60) - 1;

      if (sign === '-' && addTime > maxTime) {
        Swal.fire({
          icon: 'error',
          title: '남은시간보다 차감시간이 클 수 없습니다.',
          text: `${h > 0 ? (m > 0 ? `${h}시간 ${m}분` : `${h}시간`) : `${m}분`} 차감 가능합니다.`,
          timer: 3500,
        });
      } else {
        Swal.fire({
          title: '변경한 내용을 저장 하시겠습니까?',
          showCancelButton: true,
          confirmButtonColor: '#F53C14',
          cancelButtonColor: '#04c9db',
          confirmButtonText: '저장',
          cancelButtonText: '취소',
        }).then(async (result) => {
          if (result.value) {
            let res = await userTicketUpdate({
              ticketId: ticket.id,
              merchantId: ticket.merchantId,
              userId: ticket.userId,
              startDate: ticket.startDate,
              endDate: ticket.endDate,
              time:
                ticket.type === 2 || ticket.type === 5
                  ? 0
                  : (ticket.type !== 2 || ticket.type !== 5) && sign === '-'
                  ? -addTime
                  : addTime,
              day: ticket.type === 2 || ticket.type === 5 ? addDay : 0,
              paymentType: payType,
              amount: amount,
              level: ticket.level,
            });

            if ((addTime || addDay) && res.status === 200) {
              let payData = {
                payType: payType,
                amount: amount,
                userId: ticket.userId,
                addTime: addTime,
                addDay: addDay,
                meta8: ` 시간:${
                  ticket.type === 2 || ticket.type === 5
                    ? 0
                    : (ticket.type !== 2 || ticket.type !== 5) && sign === '-'
                    ? -addTime
                    : addTime
                }초 기간:${ticket.type === 2 || ticket.type === 5 ? addDay : 0}일 ${
                  sign === '-' ? '차감' : '추가'
                }`,
                merchantId: merchantId,
                ticketId: ticket.id,
              };
              const payLogFunc = await payLog(payData);

              if (payLogFunc === 200) {
                Swal.fire({
                  title: '저장이 완료되었습니다.',
                  timer: 2000,
                }).then(
                  tagToggle(),
                  setAddTime(null),
                  setAddDay(null),
                  getMerchantId(),
                  getUserTicketListApi(merchantId),
                );
              } else {
                Swal.fire('저장이 완료되었습니다').then(
                  tagToggle(),
                  getMerchantId(),
                  setAddTime(null),
                  setAddDay(null),

                  getUserTicketListApi(merchantId),
                );
              }
            }
          }
        });
      }
    } else {
      Swal.fire({
        title: '변경한 내용을 저장 하시겠습니까?',
        showCancelButton: true,
        confirmButtonColor: '#F53C14',
        cancelButtonColor: '#04c9db',
        confirmButtonText: '저장',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.value) {
          let res = await userTicketUpdate({
            ticketId: ticket.id,
            merchantId: ticket.merchantId,
            userId: ticket.userId,
            startDate: ticket.startDate, // choiceStart ? choiceStart : e.startDate,
            endDate: ticket.endDate, // choiceEnd ? choiceEnd : e.endDate,
            time:
              ticket.type === 2 || ticket.type === 5
                ? 0
                : (ticket.type !== 2 || ticket.type !== 5) && sign === '-'
                ? -addTime
                : addTime,
            day: ticket.type === 2 || ticket.type === 5 ? addDay : 0,
            paymentType: payType,
            amount: amount,
            level: ticket.level,
          });

          if (res.status === 200) {
            Swal.fire({
              title: '저장이 완료되었습니다.',
              timer: 2000,
            }).then(
              tagToggle(),
              setAddTime(null),
              setAddDay(null),
              getMerchantId(),
              getUserTicketListApi(merchantId),
            );
          } else {
            Swal.fire('저장 중 에러가 발생했습니다.').then(
              tagToggle(),
              setAddTime(null),
              setAddDay(null),
              getMerchantId(),
              getUserTicketListApi(merchantId),
            );
          }
        }
      });
    }
  };
  const onCancelTicketModal = () => {
    setAddTime(null);
    setAddDay(null);
    setPayType('cash');
    setAmount(0);
    setAddTimeCheck(false);
  };

  const openAddTimeModal = () => {
    setIsModal(!isModal);
  };

  const saveAddData = (e) => {
    setAddTimeCheck(e);
  };

  const close = () => {
    setIsCustom(!isCustom);
  };

  const searchData = (datalist, currentSetting) => {
    if (!searchKeyword) {
      getUserTicketListApi(merchantId);
      setCurrentPage(1);
    } else {
      let copyData;
      if (datalist && Array.isArray(datalist)) {
        copyData = [...datalist];
      } else {
        copyData = [...defaultList];
      }
      let searchData;
      if (searchOption === '권번') {
        searchData = copyData.filter((item) => item.id?.toString().includes(searchKeyword));
      } else if (searchOption === '유형') {
        searchData = copyData.filter((item) => item.typeName?.includes(searchKeyword));
      } else if (searchOption === '등급') {
        searchData = copyData.filter((item) => item.level?.toString().includes(searchKeyword));
      } else if (searchOption === '전화번호') {
        searchData = copyData.filter((item) => item.phoneNum?.includes(searchKeyword));
      } else if (searchOption === '사용가능여부') {
        searchData = copyData.filter((item) => item.blockKeyword?.includes(searchKeyword));
      } else if (searchOption === '사용상태') {
        searchData = copyData.filter((item) => item.usingTimeKeyword?.includes(searchKeyword));
      } else if (searchOption === '퇴장처리') {
        searchData = copyData.filter((item) => item.isUsingKeyword?.includes(searchKeyword));
      } else if (searchOption === '입장권차단') {
        searchData = copyData.filter((item) => item.ticketBlockKeyword?.includes(searchKeyword));
      }

      setLists(searchData);

      if (!currentSetting) {
        setCurrentPage(1);
      }
    }
  };

  //초과결제 filterbtn
  const overTimeFilter = () => {
    setIsShowOverTime(!isShowOverTime);
  };

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  return (
    <Fragment>
      <Tooltip enabled={tooltip.enabled} x={mousePosition.x} y={mousePosition.y}>
        {tooltip.message}
      </Tooltip>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          seat={userData}
          back={true}
          // setData={getUserTicketListApi}
        />
      )}

      {isModal && (
        <AddTimeModal
          isOpen={isModal}
          data={selectedItem}
          time={setAddTime}
          day={setAddDay}
          payType={setPayType}
          amount={setAmount}
          timeValue={addTime}
          dayValue={addDay}
          payTypeValue={payType}
          amountValue={amount}
          close={openAddTimeModal}
          check={saveAddData}
          signData={setSign}
        />
      )}

      {isCustom && <TicketCustom mid={merchantId} close={close} reloading={getUserTicketListApi} />}
      {selectedItem && (
        <TicketInfoModal
          isOpen={!!selectedItem}
          onToggle={tagToggle}
          ticket={selectedItem}
          onSave={handleSave}
          onCancel={onCancelTicketModal}
          addTimeCheck={addTimeCheck}
          addTime={addTime}
          sign={sign}
          addDay={addDay}
          amount={amount}
          payType={payType}
          openTimeModal={() => setIsModal(true)}
        />
      )}

      <PageContainer
        title="입장권 정보 조회"
        itemsRightSideTitle={[
          <Button icon={IconSave} onClick={() => setIsCustom(true)} buttonType="default">
            입장권 발행
          </Button>,
          <ExcelButton
            onClick={() => exportExcel(lists, startDate, endDate, '입장권 정보 조회')}
          />,
        ]}>
        <TableHeaderContainerV2>
          <DatePickerComponent
            withCalendar
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startMaxDate={endDate}
            endMinDate={startDate}
          />
          {/* <DatePickerStyle>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              selectsStart
              locale={ko}
              startDate={startDate}
              maxDate={endDate}
              endDate={endDate}
              customInput={<ExampleCustomInput />}
            />
            <h5> - </h5>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={ko}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              customInput={<ExampleCustomInput />}
            />
            <SelectDate
              page="ticket"
              setStartDate={(val) => setStartDate(val)}
              setEndDate={(val) => setEndDate(val)}
              nowTime={'이번 주'}
            />
          </DatePickerStyle> */}

          <div className="inner-grid-2">
            <ToggleButton enabled={isShowOverTime} buttonType="action" onClick={overTimeFilter}>
              초과결제 확인 {isShowOverTime ? 'ON' : 'OFF'}
            </ToggleButton>
            <PerPageSelect onChange={setPostsPerPage} />
          </div>
          <div className="inner-grid-3">
            <Dropdown>
              <select onChange={(e) => setSearchOption(e.target.value)} value={searchOption}>
                {searchKeywordData.map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </Dropdown>

            <Search
              style={{ gridColumn: 'span 2' }}
              onSubmit={searchData}
              searchKeyword={searchKeyword}
              setSearchKeyword={handleSearchKeyword}
            />

            {/* <Form
              onSubmit={(e) => {
                e.preventDefault();
                searchData();
              }}>
              <LabelRow className="m-0">
                <Input
                  className="form-control"
                  type="text"
                  placeholder="검색어를 입력해주세요"
                  defaultValue={searchKeyword}
                  onChange={(e) => handleSearchKeyword(e.target.value)}
                />

                <SearchButton onClick={() => searchData()} />
              </LabelRow>
            </Form> */}
          </div>
        </TableHeaderContainerV2>

        {lists.length > 0 ? (
          <Table
            data={currentPosts(lists)}
            columns={ticketListColumns}
            striped={true}
            center={true}
          />
        ) : (
          <h5 style={{ textAlign: 'center' }}>정보가 없습니다.</h5>
        )}

        {lists.length > 0 && (
          <PaginationComponent
            setCurrentPage={setCurrentPage}
            totalLength={lists.length}
            postsPerPage={postsPerPage}
            currentPage={currentPage}
          />
        )}
      </PageContainer>
    </Fragment>
  );
};

export default TicketInfo;

const Tooltip = styled.div`
  display: ${({ enabled }) => (enabled ? 'inherit' : 'none')};
  position: fixed;

  left: ${({ x }) => `${x + 10}px`};
  top: ${({ y }) => `${y + 10}px`};

  background: white;
  padding: 1rem;
  border-radius: 0.25rem;
  z-index: 10;

  word-break: keep-all;

  max-width: 10rem;

  box-shadow: 0px 3px 12px rgba(89, 102, 122, 0.15);
`;
