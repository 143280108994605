import { createGlobalStyle } from 'styled-components';

/**
 * CSS Normalization
 */
const GlobalStyle = createGlobalStyle`

button {
    background: none;
    border:none;
}
input, button, select {
    color: black;
}
/* Select box 스타일 초기화 */ 
select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

/* IE 에서 Select box 화살표 제거 */ 
select::-ms-expand { display: none; }

`;

export default GlobalStyle;
