export const timeTable = [
  {
    time: "0000",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0030",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0100",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0130",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0200",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0230",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0300",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0330",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0400",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0430",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0500",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0530",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0600",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0630",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0700",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0730",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0800",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0830",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0900",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0930",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1000",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1030",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1100",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1130",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1200",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1230",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1300",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1330",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1400",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1430",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1500",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1530",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1600",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1630",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1700",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1730",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1800",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1830",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1900",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "1930",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "2000",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "2030",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "2100",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "2130",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "2200",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "2230",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "2300",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "2330",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0000",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0030",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0100",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0130",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0200",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0230",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0300",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0330",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0400",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0430",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0500",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0530",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
  {
    time: "0600",
    isStart: 0,
    isEnd: 0,
    reservation: 0,
    notClick: 0,
    reserved: 0,
  },
];
