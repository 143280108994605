import { userWithMerchantToken, userWithoutToken, userWithUserToken } from '.';

interface RegisterData {
  merchantId: number;
  password: string;
  phoneNum: string;
  gender: 0 | 1;
  parentPhoneNum: string;
  name: string;
  email: string;
  job: string;
  smsDeny: boolean;
  alertKaKaoToggle: boolean;
}

/**
 * 사용자가 이용 중인 지점 목록
 * @returns
 */
export const getMerchant = () => userWithUserToken.get(`/merchant`);

/**
 * 사용자 정보 조회
 * @returns
 */
export const getInfo = () => userWithMerchantToken.get('/info');

/**
 * 사용자 회원가입 (v2)
 * @param registerData
 * @returns
 */
export const postRegister = (registerData: RegisterData) =>
  userWithoutToken.post(`/register`, registerData);
