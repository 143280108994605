import styled from 'styled-components';

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  background: ${({ theme }) => theme.neutralGray[100]};

  /* padding: 1rem; */
  padding-top: 4rem;
  min-height: calc(100vh - 10rem);

  @media screen and (min-width: 1280px) {
    width: calc(100% - 14rem);
    margin-left: 14rem;
  }
`;

export default PageBody;
