import React, { useEffect, useMemo, useState } from 'react';
import PageContainer from '../../components/common/PageContainer';
import Table, { TableHeaderContainerV2 } from '../../components/common/Table';
import PaginationComponent from '../../components/pagination';
import { merchantKioskVersionColumns } from '../../data/columns';
import { getAdminVersion } from '../../lib/api/v2/admin';
import Search from '../../components/common/Search';
import { ActionButton } from '../../components/common/Button';
import { swalMessage } from '../../components/common';
import { innerUpdate, outerUpdate } from '../../lib/socket';

const MerchantKioskVersion = () => {
  const [merchantList, setMerchantList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');

  const CurrentPageList = useMemo(() => {
    if (!merchantList || !merchantList.length) return [];
    const filtered = merchantList.filter((merchant) => {
      if (!searchKeyword) return true;
      return merchant?.merchantName && merchant.merchantName.includes(searchKeyword);
    });
    const indexOfLast = currentPage * 10;
    const indexOfFirst = indexOfLast - 10;
    return filtered.slice(indexOfFirst, indexOfLast);
  }, [merchantList, searchKeyword, currentPage]);

  const fetch = async () => {
    try {
      const { data } = await getAdminVersion();
      const tableData = data.map((merchant: any) => ({
        ...merchant,
        version_outer: merchant.version_outer || '-',
        version_inner: merchant.version_inner || '-',
        updateOuterButton: (
          <ActionButton
            buttonType="action"
            onClick={() => updateKiosk(merchant.merchantId, 'outer', merchant.version_outer)}>
            업데이트
          </ActionButton>
        ),
        updateInnerButton: (
          <ActionButton
            buttonType="action"
            onClick={() => updateKiosk(merchant.merchantId, 'inner', merchant.version_inner)}>
            업데이트
          </ActionButton>
        ),
      }));

      setMerchantList(tableData);
    } catch (error) {}
  };

  const openWarningModal = (message: string) => swalMessage('warning', message, '확인', false);

  const updateKiosk = (
    merchantId: number,
    position: 'outer' | 'inner',
    currentVersion?: string,
  ) => {
    if (!merchantId || merchantId < 1) return openWarningModal(`유효하지 않은 지점입니다.`);
    if (!currentVersion)
      return openWarningModal(
        `선택한 지점의 ${
          position === 'inner' ? '내부' : '외부'
        }에는 키오스크가 설치되지 않았습니다.`,
      );
    if (position === 'outer') outerUpdate(merchantId);
    else innerUpdate(merchantId);
    swalMessage('success', '업데이트를 요청했습니다.');
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchKeyword]);

  return (
    <PageContainer title="키오스크 버전">
      <TableHeaderContainerV2>
        <Search
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          onSubmit={() => {}}
        />
      </TableHeaderContainerV2>

      <Table data={CurrentPageList} columns={merchantKioskVersionColumns} />
      {merchantList.length > 0 && (
        <PaginationComponent
          setCurrentPage={setCurrentPage}
          totalLength={merchantList.length}
          postsPerPage={10}
          currentPage={currentPage}
        />
      )}
    </PageContainer>
  );
};

export default MerchantKioskVersion;
