import React from 'react';
import styled from 'styled-components';

import ModalHeader from './ModalHeader';
import { ModalButton } from '../atom/ModalButton';
import { swalMessage } from '../common';
import useModal from '../../lib/hooks/useModal';
const Container = styled.div`
  max-height: 90dvh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media screen and (min-width: 768px) {
    max-width: 80vw;
  }
`;

const Content = styled.div`
  padding: 0 2.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  /* max-height: calc(85vh - 5rem); */

  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 16px;
    background-clip: padding-box;
    border: 6px solid transparent;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 16px;
  }
`;
const BottomContainer = styled.div<{ buttonPosition: 'center' | 'start' | 'end' }>`
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.buttonPosition};
  gap: 0.75rem;
  padding: 0 2.5rem 2.5rem 2.5rem;
`;

export const mobileModalOptions = {
  style: {
    width: '100%',
    height: '100%',
    padding: 0,
    top: 0,
    left: 0,
    right: 0,
    transform: 'none',
    maxWidth: 'none',
    borderRadius: '0',
    border: 'none',
  },
  uncontrolled: true,
};

export const commonWrapperOptions = {
  noPadding: true,
  hasCancelButton: true,
  onClickCancel: () => {},
  uncontrolled: true,
};

type Props = {
  title: string;
  cancelBtnText?: string;
  confirmBtnText?: string;
  isCloseButton?: boolean;
  isCancelButton?: boolean;
  isConfirmButton?: boolean;
  isHeaderBolder?: boolean;
  onConfirm?: () => void | string | Promise<any>;
  callbackOnConfirmError?: (error?: Error) => void | Promise<any>; // onConfirm 함수에서 비동기 처리 시 Error가 발생할 경우 실행할 함수
  notCloseWhenConfirm?: boolean;
  notCloseWhenCancel?: boolean;
  onCloseModal?: () => void;
  buttonPosition?: 'center' | 'start' | 'end';
  buttonPlanItemId?: string;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  bottomAddOn?: React.ReactNode;
  children: React.ReactNode;
  confirmButtonStyle?: React.CSSProperties;
  bottomStyle?: React.CSSProperties;
  isConfirmBeforeClose?: boolean;
  confirmBeforeCloseText?: string;
};

const CommonWrapper = ({
  title,
  cancelBtnText = '취소',
  confirmBtnText = '확인',
  isCloseButton = true,
  isCancelButton = true,
  isConfirmButton = true,
  isHeaderBolder = true,
  onConfirm,
  callbackOnConfirmError,
  notCloseWhenConfirm = false,
  notCloseWhenCancel = false,
  onCloseModal,
  buttonPosition = 'end',
  buttonPlanItemId,
  style,
  contentStyle,
  bottomAddOn,
  children,
  confirmButtonStyle,
  bottomStyle,
  isConfirmBeforeClose,
  confirmBeforeCloseText = '정말 닫으시겠습니까?',
}: Props) => {
  const { closeLatestModal } = useModal();

  /**
   * Confirm button을 눌렀을 때 실행되는 callback function
   * 실행을 중단하는 조건은 아래 두 가지가 있습니다.
   *
   * 1) 비동기 또는 동기 함수 내에서 Error 객체를 반환하는 경우 (catch절의 callbackOnConfirmError() 실행)
   * 2) onConfirm()이 'abort' 리터럴을 반환 (아무 것도 추가 실행하지 않고 중단)
   * @returns undefined
   */
  const onClickConfirmButton = async () => {
    try {
      if (onConfirm) {
        const result = await onConfirm();
        if (result && result === 'abort') return; // 함수의 return 값이 'abort'인 경우 중단
      }
      if (!notCloseWhenConfirm) closeLatestModal();
    } catch (error) {
      const currentError = error as Error;
      // Promise나 함수에서 에러 발생 시 닫기 중단
      callbackOnConfirmError && callbackOnConfirmError(currentError);
    }
  };

  const onClickCancelButton = () => {
    if (isConfirmBeforeClose)
      return swalMessage('warning', confirmBeforeCloseText, '닫기', true, () => closeLatestModal());
    if (!notCloseWhenCancel) closeLatestModal();
    if (onCloseModal) onCloseModal();
  };
  return (
    <Container style={style}>
      <div
        style={{
          width: '100%',
          top: 0,
          left: 0,
          zIndex: 100,
          backgroundColor: '#fff',
        }}>
        <ModalHeader
          title={title}
          close={isCloseButton ? onClickCancelButton : undefined}
          isHeaderBolder={isHeaderBolder}
        />
      </div>
      <Content style={contentStyle}>{children}</Content>
      {isCancelButton || isConfirmButton || bottomAddOn ? (
        <BottomContainer buttonPosition={buttonPosition} style={bottomStyle}>
          {bottomAddOn && bottomAddOn}
          {isCancelButton && (
            <ModalButton buttonType="cancel" onClick={onClickCancelButton}>
              {cancelBtnText}
            </ModalButton>
          )}
          {isConfirmButton && (
            <>
              <ModalButton
                id="submit_btn"
                buttonType="confirm"
                onClick={onClickConfirmButton}
                style={confirmButtonStyle}>
                {confirmBtnText}
              </ModalButton>
            </>
          )}
        </BottomContainer>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default CommonWrapper;
