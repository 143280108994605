import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

import { ko } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import styled from 'styled-components';

import { numberWithCommas } from '../../common';
import SelectDate from '../../selectDate';
import { getPayAllList } from '../../../lib/api';
import Arrow from '../../../assets/images/dashboard/arrow.svg';
import Schedule from '../../../assets/images/dashboard/schedule.svg';
import { DashboardCard } from '../../commonStyle';
import useHandleAPICall from '../../../lib/hooks/useHandleAPICall';
import useHandleError from '../../../lib/hooks/useHandleError';

const StyleComponent = styled(DashboardCard)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  .topContainer {
    width: 100%;
    > div > p {
      &:first-child {
        margin-bottom: 0;
        font-weight: 700;
        /* line-height: 1.5; */
        font-size: 1.25rem;
      }
    }
  }

  .flex {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }
  .margin-top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .flex-col {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
  }

  #circlechart {
    width: 100%;
    margin-right: 1vw;
  }
`;

export const DatePickerStyle = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  margin: 0 10px;
  h5 {
    margin: 0 8px;
    font-weight: 400;
  }
  > div > div > button {
    width: 100%;
  }
  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 9px 12px;
    font-size: 14px;
    margin-left: 10px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 5px 0 0;
    > div > div > button {
      padding: 6px 10px;
      font-size: 13px;
      > svg {
        width: 17px;
      }
    }
    select {
      padding: 9px 5px;
    }
  }
`;

export const DatePickerRenewal = styled.div`
  display: flex;
  border: 1px solid #dde4eb;
  background-color: #ffffff !important;
  border-radius: 0.5rem;
  font-size: 1rem;
  padding: 0.25rem;
  align-items: center;
  .react-datepicker__input-container {
    button {
      background: none !important;
      color: #686c71;
      border: none;
      font-size: 1rem;
      :focus {
        box-shadow: none;
      }
      :active {
        box-shadow: none !important;
        color: #686c71 !important ;
        border: none;
      }
    }
  }

  h5 {
    margin-bottom: 0;
  }
  select {
    padding: 0 1rem 0 0.25rem;
    -webkit-appearance: none; /* for chrome */
    -moz-appearance: none; /*for firefox*/
    appearance: none;
    border: none;
    border-right: 1px solid #dde4eb;
    color: #686c71;
    background: url(${Arrow}) no-repeat no-repeat 90% 50%;
  }
  > img {
    padding-left: 0.3vw;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const SaleData = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  border-radius: 100px;

  .title {
    display: flex;

    justify-content: flex-start;
    align-items: center;
    border-radius: 100px;
    gap: 0.5rem;
    > p {
      color: #ffffff;
      font-size: 1rem;
      color: ${({ theme }) => theme.neutralGray[500]};
      margin-bottom: 0;
    }
    > div {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background: ${(props) => props.color};
    }
  }
  .data {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    width: 100%;
    align-items: center;
    background: ${({ theme }) => theme.neutralGray[100]};
    border-radius: 1rem;
    padding: 0.25rem;
    p {
      text-align: center;
      margin-bottom: 0;
      font-size: 0.875rem;
      font-weight: 600;
      color: #686c71;
      &:first-child,
      &:nth-child(2) {
        border-right: 1px solid #dde4eb;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .title {
      width: 6rem;

      > p {
        font-size: 1rem;
      }
    }
    .data {
      p {
        font-size: 0.875rem;
      }
    }
  }
`;

const DayButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  button {
    background-color: #ffffff;
    border-radius: 0.5rem;
    color: #91979e;
    border: 1px solid #dde4eb;
    font-weight: 500;
    font-size: 1rem;
    padding: 0.5rem;
    &.check {
      background-color: var(--dashboard-default);
      color: #ffffff;
    }
  }
`;

const SalesChart = ({
  paddingStyle,
  isMobile,
  setIsLoading,
  mid,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  style,
  itemRef,
}) => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const [salesList, setSalesList] = useState([]);
  // const [startDate, setStartDate] = useState(
  //   moment(new Date()).startOf("month")._d
  // );
  // const [endDate, setEndDate] = useState(new Date());

  const [selectDate, setSelectDate] = useState('이번 달');
  useEffect(() => {
    getPaylogAllListApi(mid);
  }, [mid]);

  const getPaylogAllListApi = async (merchantId) => {
    if (!merchantId) return;
    try {
      setIsLoading(true);
      const { data } = await createRequest(
        getPayAllList,
        merchantId,
        moment(startDate).format('YYYY-MM-DD 00:00:00'),
        moment(endDate).format('YYYY-MM-DD 23:59:59'),
      );
      if (data) {
        setSalesList(data);
        setIsLoading(false);
      }
    } catch (error) {
      catchError(error);
    }
  };

  let credit = 0;
  let kakaopay = 0;
  let cash = 0;
  let local = 0;
  let virtual = 0;
  let webPayment = 0;

  let creditPrice = 0;
  let kakaopayPrice = 0;
  let cashPrice = 0;
  let localPrice = 0;
  let virtualPrice = 0;
  let webPaymentPrice = 0;
  let credit_cancel = 0;
  let kakaopay_cancel = 0;
  let cash_cancel = 0;
  let local_cancel = 0;
  let virtual_cancel = 0;
  let webPayment_cancel = 0;
  let creditPrice_cancel = 0;
  let localPrice_cancel = 0;
  let kakaopayPrice_cancel = 0;
  let cashPrice_cancel = 0;
  let virtualPrice_cancel = 0;
  let webPaymentPrice_cancel = 0;

  for (let i = 0; i < salesList.length; i++) {
    if (salesList[i].paymentType === 'credit' || salesList[i].paymentType === 'applepay') {
      credit += 1;
      creditPrice += salesList[i].amount;
    } else if (
      salesList[i].paymentType === 'credit-cancel' ||
      salesList[i].paymentType === 'applepay-cancel'
    ) {
      credit_cancel += 1;
      creditPrice_cancel += salesList[i].amount;
    } else if (salesList[i].paymentType === 'cash') {
      cash += 1;
      cashPrice += salesList[i].amount;
    } else if (salesList[i].paymentType === 'cash-cancel') {
      cash_cancel += 1;
      cashPrice_cancel += salesList[i].amount;
    } else if (salesList[i].paymentType === 'local') {
      local += 1;
      localPrice += salesList[i].amount;
    } else if (salesList[i].paymentType === 'local-cancel') {
      local_cancel += 1;
      localPrice_cancel += salesList[i].amount;
    } else if (
      salesList[i].paymentType === 'kakaopay' ||
      salesList[i].paymentType === 'naverpay' ||
      salesList[i].paymentType === 'payco'
    ) {
      kakaopay += 1;
      kakaopayPrice += salesList[i].amount;
    } else if (
      salesList[i].paymentType === 'kakaopay-cancel' ||
      salesList[i].paymentType === 'naverpay-cancel' ||
      salesList[i].paymentType === 'payco-cancel'
    ) {
      kakaopay_cancel += 1;
      kakaopayPrice_cancel += salesList[i].amount;
    } else if (salesList[i].paymentType === 'virtual') {
      virtual += 1;
      virtualPrice += salesList[i].amount;
    } else if (salesList[i].paymentType === 'virtual-cancel') {
      virtual_cancel += 1;
      virtualPrice_cancel += salesList[i].amount;
    } else if (
      salesList[i].paymentType === 'toss' ||
      salesList[i].paymentType === 'hecto' ||
      salesList[i].paymentType === 'kspay'
    ) {
      webPayment += 1;
      webPaymentPrice += salesList[i].amount;
    } else if (
      salesList[i].paymentType === 'toss-cancel' ||
      salesList[i].paymentType === 'hecto-cancel' ||
      salesList[i].paymentType === 'kspay-cancel'
    ) {
      webPayment_cancel += 1;
      webPaymentPrice_cancel += salesList[i].amount;
    } else {
    }
  }

  const totalPayDataCount = +credit + +cash + +local + +kakaopay + +virtual + +webPayment;
  const totalPayCancelCount =
    +credit_cancel +
    +cash_cancel +
    +local_cancel +
    +kakaopay_cancel +
    +virtual_cancel +
    +webPayment_cancel;
  const totalPayDataPrice =
    +creditPrice + +cashPrice + +localPrice + +kakaopayPrice + +virtualPrice + +webPaymentPrice;
  const totalPayCancelDataPrice =
    +creditPrice_cancel +
    +cashPrice_cancel +
    +localPrice_cancel +
    +kakaopayPrice_cancel +
    +virtualPrice_cancel +
    +webPaymentPrice_cancel;
  const credit1 =
    credit > 0
      ? Math.round(((credit - credit_cancel) / (totalPayDataCount - totalPayCancelCount)) * 100)
      : 0;
  const cash1 =
    cash > 0
      ? Math.round(((cash - cash_cancel) / (totalPayDataCount - totalPayCancelCount)) * 100)
      : 0;
  const local1 =
    local > 0
      ? Math.round(((local - local_cancel) / (totalPayDataCount - totalPayCancelCount)) * 100)
      : 0;
  const kakaopay1 =
    kakaopay > 0
      ? Math.round(((kakaopay - kakaopay_cancel) / (totalPayDataCount - totalPayCancelCount)) * 100)
      : 0;
  const virtual1 =
    virtual > 0
      ? Math.round(((virtual - virtual_cancel) / (totalPayDataCount - totalPayCancelCount)) * 100)
      : 0;
  const webPayment1 =
    webPayment > 0
      ? Math.round(
          ((webPayment - webPayment_cancel) / (totalPayDataCount - totalPayCancelCount)) * 100,
        )
      : 0;

  // sale chart

  const apexRadialBarChart = {
    series: [credit1, virtual1, cash1, local1, kakaopay1, webPayment1],
    options: {
      chart: {
        height: isMobile ? 300 : 320,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            show: false,
            name: {
              show: true,
              fontSize: '22px',
            },
            value: {
              show: true,
              fontSize: '16px',
              formatter: function (val) {
                return val + '회';
              },
            },
            total: {
              show: true,
              label: '결제 총 횟수',
              formatter: function (w) {
                return (
                  w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0) + '회'
                );
              },
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: ['카드', '가상결제', '현금', '지역화폐', '간편결제', '웹결제'],
      colors: ['#00CFE2', '#F2A354', '#EC6E85', '#179E9F', '#6EAD69', '#8E78AA'],
    },
  };

  const handleDate = (value) => {
    setSelectDate(value);
    if (value === '어제') {
      setStartDate(moment(new Date()).subtract(1, 'd')._d);
      setEndDate(moment(new Date()).subtract(1, 'd')._d);
    } else if (value === '이번 주') {
      setStartDate(moment(new Date()).isoWeekday(1).startOf('isoWeek')._d);
      setEndDate(moment(new Date()).isoWeekday(1).endOf('isoWeek')._d);
    } else if (value === '이번 달') {
      setStartDate(moment(new Date()).startOf('month')._d);
      //setEndDate(new Date());
      setEndDate(moment(new Date()).endOf('month')._d);
    } else if (value === '저번 달') {
      setStartDate(moment(new Date()).subtract(1, 'M').startOf('month')._d);
      setEndDate(moment(new Date()).startOf('month').subtract(1, 'd')._d);
    } else if (value.includes(new Date().getFullYear().toString())) {
      setStartDate(moment(new Date()).subtract(1, 'y')._d);
      setEndDate(new Date());
    } else if (value === '저번 주') {
      setStartDate(moment().add(-1, 'week').startOf('week').day(1)._d);
      setEndDate(moment().add(-1, 'week').day(7)._d);
    }
  };

  useEffect(() => {
    getPaylogAllListApi(mid);
  }, [startDate, endDate]);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="btn-primary btn-sm" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
    <Fragment>
      <StyleComponent ref={itemRef} style={style || {}}>
        <ResponsiveArea>
          <div className="topContainer">
            <div className="flex">
              <p>결제 유형별 매출 현황</p>
              <div className="topbar">
                <DatePickerRenewal>
                  <SelectDate
                    nowTime={'이번 달'}
                    setStartDate={(val) => setStartDate(val)}
                    setEndDate={(val) => setEndDate(val)}
                  />

                  <img src={Schedule} alt="s" />
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    selectsStart
                    locale={ko}
                    startDate={startDate}
                    maxDate={endDate}
                    endDate={endDate}
                    customInput={<ExampleCustomInput />}
                  />
                  <h5>-</h5>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    locale={ko}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    customInput={<ExampleCustomInput />}
                  />
                </DatePickerRenewal>
                {/* {isMobile && (
                  <DayButtons>
                    {dateArray.map((item) => (
                      <button
                        className={selectDate === item ? 'check' : ''}
                        onClick={() => handleDate(item)}>
                        {item}
                      </button>
                    ))}
                  </DayButtons>
                )} */}
              </div>
            </div>

            <FinalAmount>
              {numberWithCommas(totalPayDataPrice - totalPayCancelDataPrice)}원
            </FinalAmount>
            <div id="circlechart">
              <Chart
                options={apexRadialBarChart.options}
                series={apexRadialBarChart.series}
                type="radialBar"
                height={isMobile ? 270 : 290}
              />
            </div>
          </div>

          <div>
            <SaleData color="#00CFE2">
              <div className="title">
                <div />
                <p>카드</p>
              </div>
              <div className="data">
                <p>{credit1}%</p>
                <p>{numberWithCommas(creditPrice - creditPrice_cancel)}원</p>
                <p>{credit - credit_cancel}회</p>
              </div>
            </SaleData>
            <SaleData color="#F2A354">
              <div className="title">
                <div />
                <p>가상결제</p>
              </div>
              <div className="data">
                <p>{virtual1}%</p>
                <p>{numberWithCommas(virtualPrice - virtualPrice_cancel)}원</p>
                <p>{virtual - virtual_cancel}회</p>
              </div>
            </SaleData>
            <SaleData color="#EC6E85">
              <div className="title">
                <div />
                <p>현금</p>
              </div>
              <div className="data">
                <p>{cash1}%</p>
                <p>{numberWithCommas(cashPrice - cashPrice_cancel)}원</p>
                <p>{cash - cash_cancel}회</p>
              </div>
            </SaleData>
            <SaleData color="#179E9F">
              <div className="title">
                <div />
                <p>지역화폐</p>
              </div>
              <div className="data">
                <p>{local1}%</p>
                <p>{numberWithCommas(localPrice - localPrice_cancel)}원</p>
                <p>{local - local_cancel}회</p>
              </div>
            </SaleData>
            <SaleData color="#6EAD69">
              <div className="title">
                <div />
                <p>간편결제</p>
              </div>
              <div className="data">
                <p>{kakaopay1}%</p>
                <p>{numberWithCommas(kakaopayPrice - kakaopayPrice_cancel)}원</p>
                <p>{kakaopay - kakaopay_cancel}회</p>
              </div>
            </SaleData>
            <SaleData color="#8E78AA">
              <div className="title">
                <div />
                <p>웹결제</p>
              </div>
              <div className="data">
                <p>{webPayment1}%</p>
                <p>{numberWithCommas(webPaymentPrice - webPaymentPrice_cancel)}원</p>
                <p>{webPayment - webPayment_cancel}회</p>
              </div>
            </SaleData>
          </div>
        </ResponsiveArea>
      </StyleComponent>
    </Fragment>
  );
};
export default SalesChart;

const ResponsiveArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FinalAmount = styled.div`
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  color: var(--dashboard-default);
  text-align: center;

  @media screen and (max-width: 767px) {
    text-align: start;
  }
`;
