import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import { DatePickerStyle } from '../../pages/seatInfo';
import moment from 'moment';
import { secondToHour } from '../../pages/ticketList';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/locale';
import { ExampleCustomInput } from '../../pages/ticketCustom';
import { Button } from '../../pages/lockerChange';
import ConfigDB from '../../data/customizer/config';
import { Dropdown } from '../common/Dropdown';

const ModalContainer = styled.div`
  strong {
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
    color: #222;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #555;
  }
`;

const LabelRow1 = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  h5 {
    margin-right: 1vw;
    margin-bottom: 0;
    font-size: 14px;
    strong {
      color: red;
    }
  }
  p {
    margin-bottom: 0;
    font-size: 12px;
    color: #555;
  }
  :not(:last-child) {
    margin-bottom: 15px;
  }
  input,
  select {
    margin-top: 5px;
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 6px 13px;
  }
  select {
    width: 50%;
  }
  > div {
    width: 150px;
    > div > button {
      width: 100%;
    }
  }
`;

const ModalBottom = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 20px;
`;
const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;

/**
 * @description 이용권 정보 모달
 *
 * @param isOpen boolean
 * @param onToggle ()=>void
 * @param ticket 티켓 정보 객체
 * @param onSave 이용권 정보 저장 함수
 * @param addTimeCheck 시간/기간 수정 시 해당 정보 표시 여부
 */

const TicketInfoModal = ({
  isOpen,
  onToggle,
  ticket,
  onSave,
  onCancel,
  addTimeCheck,
  addTime,
  sign,
  addDay,
  amount,
  payType,
  openTimeModal,
}) => {
  const [validStart, setValidStart] = useState(moment(ticket?.startDate)._d); // 유효기간 시작일 (이용권 정보모달)
  const [validEnd, setValidEnd] = useState(moment(ticket?.endDate)._d); // 유효기간 종료일 (이용권 정보모달)
  const [level, setLevel] = useState(ticket?.level); // 이용권 정보 모달 등급

  const DateToString = (date) => moment(date).format('YYYY-MM-DD HH:mm:ss');

  const onCancelModal = () => {
    onToggle();
    onCancel();
  };

  const onSaveModal = () => {
    const modifiedTicket = {
      ...ticket,
      startDate: validStart,
      endDate: validEnd,
      level,
    };
    onSave(modifiedTicket);
  };

  return (
    <Modal centered isOpen={isOpen}>
      <ModalHeader className="modal-title" toggle={onToggle}>
        이용권 정보
      </ModalHeader>
      <ModalBody>
        <ModalContainer>
          <p>
            <strong>타입 : </strong>
            {ticket.type === 1
              ? '일회권'
              : ticket.type === 2
              ? '기간권'
              : ticket.type === 3
              ? '시간정액권'
              : ticket.type === 4
              ? '세미나'
              : '사물함'}
          </p>
          <p>
            <strong>구매자</strong>: {ticket.phoneNum}
          </p>
          {(ticket.type === 1 || ticket.type === 2 || ticket.type === 3) && (
            <DatePickerStyle style={{ margin: 0, marginBottom: '1rem' }}>
              <p style={{ margin: 0 }}>
                <strong>등급</strong>
                {' :'}
              </p>
              <Dropdown>
                <select value={level} onChange={(e) => setLevel(e.target.value)}>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </Dropdown>
            </DatePickerStyle>
          )}
          <p>
            <strong>시작일 : </strong>
            {DateToString(ticket.startDate)}
          </p>
          <p>
            <strong>만료일 : </strong>
            {DateToString(ticket.endDate)}
          </p>
          <p>
            <strong>마지막입장 : </strong>
            {ticket.lastIn}
          </p>
          <p>
            <strong>마지막퇴장 : </strong>
            {ticket.lastOut && ticket.lastOut !== ticket.lastIn ? DateToString(ticket.lastOut) : ''}
          </p>
          <p>
            <strong>사용시간 : </strong>
            {ticket.usedTime
              ? secondToHour(ticket.usedTime)
              : ticket.isUsing === 2
              ? secondToHour(ticket.remainTime)
              : !ticket.usedTime &&
                ticket.type === 1 &&
                ticket.remainTime - ticket.remainTimeSec > 0
              ? secondToHour(ticket.remainTime - ticket.remainTimeSec)
              : !ticket.usedTime &&
                ticket.type === 1 &&
                ticket.remainTime - ticket.remainTimeSec < 0
              ? `${secondToHour(+(ticket.remainTime - ticket.remainTimeSec))}초과`
              : '00:00:00'}
          </p>
          <p>
            <strong>남은시간 : </strong>
            {ticket.remainTime2}
          </p>
          {ticket.type !== 4 && (
            <div>
              <LabelRow1>
                <h5>유효기간 시작일 강제 지정</h5>
                <DatePicker
                  selected={validStart}
                  onChange={setValidStart}
                  selectsStart
                  locale={ko}
                  startDate={validStart}
                  maxDate={validEnd}
                  endDate={validEnd}
                  customInput={<ExampleCustomInput />}
                />
              </LabelRow1>
              <LabelRow1>
                <h5>유효기간 만료일 강제 지정</h5>
                <DatePicker
                  selected={validEnd}
                  onChange={setValidEnd}
                  selectsEnd
                  locale={ko}
                  startDate={validStart}
                  minDate={validStart}
                  endDate={validEnd}
                  customInput={<ExampleCustomInput />}
                />
              </LabelRow1>
            </div>
          )}
          {addTimeCheck && (
            <>
              {ticket.type === 1 || ticket.type === 3 ? (
                <p>
                  <strong>추가시간 : </strong>{' '}
                  {addTime && sign === '-'
                    ? `-${Math.floor(+addTime / 3600)}시간 
                   ${(+addTime / 60) % 60}분`
                    : `${Math.floor(+addTime / 3600)}시간 
                   ${(+addTime / 60) % 60}분`}
                </p>
              ) : (
                <p>
                  <strong>추가일자 : </strong>
                  {addDay}일
                </p>
              )}

              <p>
                <strong>금액 : </strong>
                {amount}원
              </p>
              <p>
                <strong>결제 종류 : </strong>
                {payType === 'credit'
                  ? '카드'
                  : payType === 'cash'
                  ? '현금'
                  : payType === 'kakaopay'
                  ? '간편결제'
                  : '가매입/가매출'}
              </p>
            </>
          )}
        </ModalContainer>
        <ModalBottom>
          {ticket.type !== 4 && (
            <Button color="#186A3A" onClick={() => openTimeModal(true)}>
              시간/기간 수정
            </Button>
          )}

          <Button color="#fff" onClick={onCancelModal}>
            취소
          </Button>
          <Button color={primary} onClick={onSaveModal}>
            저장
          </Button>
        </ModalBottom>
      </ModalBody>
    </Modal>
  );
};

export default TicketInfoModal;
