import React from 'react';
import styled from 'styled-components';
import CloseImg from '../../assets/images/close.svg';

const ModalHeader = ({ title, close, isHeaderBolder }) => {
  return (
    <ModalHeaderContainer isHeaderBolder={isHeaderBolder}>
      <span>{title}</span>
      {close && <img src={CloseImg} alt="" onClick={close} />}
    </ModalHeaderContainer>
  );
};

export default ModalHeader;

const ModalHeaderContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  > img {
    cursor: pointer;
    position: absolute;
    right: 1.25rem;
    width: 1.25rem;
  }
  > span {
    font-size: 1.5rem;
    font-weight: 700;
  }
  border-bottom: ${({ isHeaderBolder }) => (isHeaderBolder ? '1px solid #eaeaea' : 'none')};
`;
