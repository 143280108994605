import { messageWithMerchantToken } from '.';

/**
 * 전화번호 지정 메시지 전송
 * @param requestFormData
 * @returns
 */
export const postMessageEvery = (requestFormData: FormData) =>
  messageWithMerchantToken.post(`/every`, requestFormData);

/**
 * 조건별 메시지 전송
 * @param requestFormData
 * @returns
 */
export const postMessageCondition = (requestFormData: FormData) =>
  messageWithMerchantToken.post(`/condition`, requestFormData);
