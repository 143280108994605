import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: string;
}

const Container = styled.div<ContainerProps>`
  position: relative;

  > .tooltip-text {
    max-width: ${({ width }) => width || '100px'};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  min-width: 10rem;
  top: 0%;
  left: 50%;
  transform: translate(-20%, -120%);
  background: #00000083;
  color: white;
  font-weight: 500;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 16px 1px rgba(0, 0, 0, 0.12), 0 7px 10px 1px rgba(0, 0, 0, 0.14),
    0 4px 5px -2px rgba(0, 0, 0, 0.2);

  padding: 5px;
  z-index: 1;
`;

interface Props {
  width?: string;
  text: string;
}

const TooltipText: React.FC<Props> = ({ text, width }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;

    if (hovering) {
      timer = setTimeout(() => {
        setShowTooltip(true);
      }, 500);
    } else {
      if (timer) clearTimeout(timer);
      setShowTooltip(false);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [hovering]);

  return (
    <Container
      width={width}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}>
      <div className="tooltip-text">
        <span>{text}</span>
      </div>
      {showTooltip && <Tooltip>{text}</Tooltip>}
    </Container>
  );
};

export default TooltipText;
