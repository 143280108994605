import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { swalMessage } from '../../components/common';

const PaymentResult = () => {
  const history = useHistory();
  const params: any = useParams();

  const navigateToPaymentPage = (target: string) => {
    if (target === 'ticket') return history.push('/user/ticket/buy');
    if (target === 'seminar') return history.push('/user/seminar/buy');
  };

  const checkPaymentResult = () => {
    if (params.statusOrTransactionId && params.statusOrTransactionId === 'fail')
      return swalMessage('error', '결제에 실패했습니다.', '확인', false);
    if (params.ticketType)
      return swalMessage(
        'success',
        '결제에 성공했습니다.',
        '확인',
        false,
        () => navigateToPaymentPage(params.ticketType),
        () => navigateToPaymentPage(params.ticketType),
      );
    return swalMessage('error', '잘못된 접근입니다.', '확인', false);
  };

  useEffect(() => {
    checkPaymentResult();
  }, []);

  return <div></div>;
};

export default PaymentResult;
