import * as CSS from 'csstype';
import React, { ReactNode, createContext, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import dropIcon from '../../assets/images/dropIcon.svg';
import icon_help from '../../assets/images/icon_help_filled.svg';

import { FlexWrapCenter, ScrollbarStyle } from '../commonStyle';
import TooltipWrapper from './TooltipWrapper';

const buttonBgColor = {
  navy: '#332986',
  sky: '#00CFE2',
  purple: '#6c60f4',
  red: '#D43434',
  davyGrey: '#555',
  lightGray: '#e2e2e2',
  deepGreen: '#186a3a',
  lightRed: '#FBEBEB',
  lightSky: '#E5FAFC',
  orange: '#f9994c',
};

/**
 * 테이블 버튼
 */
const TableButton = styled.button<{ bgcolor: keyof typeof buttonBgColor }>`
  display: flex;
  width: 100%;
  padding: 0.4375rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.25rem;
  background: ${({ bgcolor }) => buttonBgColor[bgcolor]};
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  :disabled {
    background: ${buttonBgColor.lightGray};
  }
`;

type ControllerContextProps = {
  minWidth?: string;
  responsiveWidth?: number;
};
type RowContextProps = {
  isStart?: boolean;
  isEnd?: boolean;
};
const ControllerContext = createContext<ControllerContextProps>({});
const RowContext = createContext<RowContextProps>({});

const TableRowLabel = styled.div<{
  isMiddle?: boolean;
  minWidth?: string;
  responsiveWidth?: number;
  highlight?: boolean;
}>`
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '6.5rem')};
  min-height: 3rem;
  padding: 0.5rem 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  border-left: ${({ isMiddle }) => (isMiddle ? '1px solid #e1e1e1' : 'none')};
  border-right: 1px solid #e1e1e1;
  white-space: pre-line;
  gap: 0.5rem;
  > div {
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    text-align: center;

    /* > strong {
      margin-left: 3px;
      margin-bottom: 5px;
      margin-right: -10px;
    } */
  }

  @media (max-width: ${(p) => (p.responsiveWidth ? `${p.responsiveWidth}px` : '1279px')}) {
    border-left: none;
    > span {
      font-size: 11px;
    }
  }
`;

const TableColumn = styled.div<{
  isStart?: boolean;
  isMiddle?: boolean;
  isEnd?: boolean;
  responsiveWidth?: number;
}>`
  position: relative;
  display: flex;

  width: 100%;

  border-top: ${({ isStart, isEnd, isMiddle }) =>
    !(isStart && isMiddle) && (!isStart || isMiddle) ? '1px solid #e1e1e1' : 'none'};

  @media (max-width: ${(p) => (p.responsiveWidth ? `${p.responsiveWidth}px` : '1279px')}) {
    border-top: ${({ isStart, isEnd, isMiddle }) =>
      !(isStart && !isMiddle) ? '1px solid #e1e1e1' : 'none'};
  }
`;

export const TableCol = ({
  title,
  highlight,
  isMiddle,
  children,
  style,
  contentStyle,
  rowLabelStyle,
  help,
  helpTooltipComponent,
  isButton,
  buttonTitle,
  buttonOnClick,
  tooltipStyle,
  subTitle,
}: {
  title: string | ReactNode;
  highlight?: boolean;
  isMiddle?: boolean;
  children?: ReactNode;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  rowLabelStyle?: React.CSSProperties;
  help?: boolean;
  helpTooltipComponent?: React.ReactNode;
  tooltip?: ReactNode;
  isButton?: boolean;
  buttonTitle?: string;
  tooltipTitle?: string;
  buttonOnClick?: () => void;
  tooltipStyle?: React.CSSProperties;
  subTitle?: string;
}) => {
  const { minWidth, responsiveWidth } = useContext(ControllerContext);
  const { isStart, isEnd } = useContext(RowContext);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  return (
    <TableColumn
      isMiddle={isMiddle}
      isStart={isStart}
      isEnd={isEnd}
      responsiveWidth={responsiveWidth}
      style={style}>
      {title !== '' && (
        <TableRowLabel
          className="table-col-label"
          isMiddle={isMiddle}
          minWidth={minWidth}
          responsiveWidth={responsiveWidth}
          highlight={highlight}
          style={rowLabelStyle}>
          <TextWrapper>
            <HighlightText highlight={highlight}>{title}</HighlightText>
            {help && (
              <TooltipWrapper tooltipComponent={helpTooltipComponent} tooltipStyle={tooltipStyle}>
                <img src={icon_help} alt="help" />
              </TooltipWrapper>
            )}
          </TextWrapper>
          {subTitle && <div>{subTitle}</div>}
          {isButton && (
            <TableButton
              bgcolor="sky"
              onClick={buttonOnClick}
              style={{ width: '5rem', padding: '0.25rem 0.5rem' }}>
              {buttonTitle}
            </TableButton>
          )}
        </TableRowLabel>
      )}
      {title === '' ? (
        <FlexWrapCenter style={{ width: '100%', ...contentStyle }}>{children}</FlexWrapCenter>
      ) : (
        <FlexWrapCenter style={{ padding: '0.5rem', width: '100%', ...contentStyle }}>
          {children}
        </FlexWrapCenter>
      )}
    </TableColumn>
  );
};

const Row = styled.div<{ isStart?: boolean; isEnd?: boolean; responsiveWidth?: number }>`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  > p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 15px;
    color: ${({ theme }) => theme.lightIndigo};
  }

  @media (max-width: ${(p) => (p.responsiveWidth ? `${p.responsiveWidth}px` : '1279px')}) {
    flex-direction: column;
  }
`;

type RowProps = {
  isStart?: boolean;
  isEnd?: boolean;
  children: ReactNode;
  style?: React.CSSProperties;
};

export const TableRow = ({ isStart, isEnd, children, style }: RowProps) => {
  const { responsiveWidth } = useContext(ControllerContext);
  return (
    <RowContext.Provider value={{ isStart, isEnd }}>
      <Row isStart={isStart} isEnd={isEnd} responsiveWidth={responsiveWidth} style={style}>
        {children}
      </Row>
    </RowContext.Provider>
  );
};

const Container = styled.div`
  border: 1px solid #e1e1e1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
`;

type Props = {
  children: ReactNode;
  style?: React.CSSProperties;
  minWidth?: string;
  responsiveWidth?: number;
};

const TableController = ({ children, style, minWidth, responsiveWidth }: Props) => {
  return (
    <ControllerContext.Provider value={{ minWidth, responsiveWidth }}>
      <Container style={style}>{children}</Container>
    </ControllerContext.Provider>
  );
};

export default TableController;

export const TableInput = styled.input<{ textAlign?: CSS.Properties['textAlign'] }>`
  min-width: 7rem;
  width: ${({ width }) => (width ? width : '100%')};
  padding: 0.5rem;
  background: #f8fcff;
  border: 1px solid #e1e1e1;
  text-align: ${({ textAlign }) => textAlign ?? 'left'};
  border-radius: 4px;
  :disabled {
    color: #cfcfcf;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    filter: brightness(0.98);
  }
`;

export const TableDropDown = styled.select<{ width?: string }>`
  min-width: 7rem;
  width: ${({ width }) => (width ? width : '100%')};
  padding: 8px;
  /* background: #f8fcff; */
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  background: url(${dropIcon}) #f8fcff no-repeat;
  background-position: right 10px bottom 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  ${ScrollbarStyle}
  option {
    padding-right: 3rem;
    :disabled {
      color: #e1e1e1;
      cursor: not-allowed;
    }
  }

  &:not(:disabled):hover {
    filter: brightness(0.98);
  }
`;

export const TableMonthInput = styled(TableInput).attrs({ type: 'month' })`
  &:disabled {
    color: black;
    background: #f3f3f3;
  }
`;
export const TableDateInput = styled(TableInput).attrs({ type: 'date' })`
  &:disabled {
    color: black;
    background: #f3f3f3;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const HighlightText = styled.span<{ highlight?: boolean }>`
  ${({ highlight }) =>
    highlight &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      ::after {
        color: ${({ theme }) => theme.color.red};
        content: '*';
      }
    `}
`;
