import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, Container } from 'reactstrap';
import styled from 'styled-components';

import { getLockerList, getMerchantInfo, getSeatList, merchantId_api } from '../lib/api';
import useHandleError from '../lib/hooks/useHandleError';
import useHandleAPICall from '../lib/hooks/useHandleAPICall';

const StyleContainer = styled.div`
  width: 40%;
  p {
    margin-bottom: 11px;
  }

  .card {
    padding: 30px;
  }

  .card-body {
    padding: 0 !important;
  }
  .datachart {
    display: flex;
    align-items: center;
    margin-top: -30px;
    margin-left: -25px;
    p {
      margin-bottom: 0;
      margin-left: 1vw;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const options = {
  chart: {
    id: 'basic-bar',
    type: 'bar',
    stacked: true,
    stackType: '100%',
    height: 90,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
      columnWidth: '100%',
      barHeight: 300,
    },
    radialBar: {
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          show: false,
        },
      },
    },
  },
  stroke: {
    width: 1,
    colors: [],
  },
  title: {
    text: '',
  },
  xaxis: {
    type: 'category',
    categories: [],
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  tooltip: {
    fillSeriesColor: false,
  },
  legend: {
    show: false,
  },
  colors: ['#051C2C', '#e3e3e3'],
  yaxis: {
    show: false,
    max: 100,
  },
  grid: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
};

const TicketTypeContainer = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
  }
`;

const TicketTypeBox = styled.div`
  border-radius: 1vw;
  width: 33%;
  padding: 1vw;
  margin-right: 0.5vw;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 14px -3px;
  .name {
    font-weight: 700;
    font-size: 14px;
  }
  span {
    display: flex;
    width: 100%;
    justify-content: space-between;
    p {
      font-weight: 500;
    }
  }
  @media screen and (max-width: 768px) {
    width: 32%;
    padding: 1.4vw 1.4vw;
    margin: 1vw;
    .name {
      font-weight: 700;
      font-size: 12px;
    }
    span {
      p {
        font-size: 11px;
      }
    }
  }
  @media screen and (max-width: 320px) {
    width: 30%;
    padding: 1.4vw 1.4vw;
    margin: 1vw;
    .name {
      font-weight: 700;
      font-size: 12px;
    }
    span {
      p {
        font-size: 11px;
      }
    }
  }
`;

const StoreOverview = () => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const [seatShare, setSeatShare] = useState();
  const [lockerShare, setLockerShare] = useState(0);
  const [massageShare, setMassageShare] = useState(0);
  const [seatLength, setSeatLength] = useState(0);
  const [occData, setOccData] = useState(0);
  const [usingLocker, setUsingLocker] = useState(0);
  const [lockerLength, setLockerLength] = useState(0);
  const [usingMassage, setUsingMassage] = useState(0);
  const [massageLength, setMassageLength] = useState(0);
  const [usingData, setUsingData] = useState([]);
  let ticketTypeData = ['일회권', '시간정액권', '기간권'];
  const getMerchantId = async () => {
    try {
      const data = await createRequest(merchantId_api);
      if (data.status === 200) {
        seatData(data.data.id);
        LockerData(data.data.id);
        messageData(data.data.id);
      }
    } catch (error) {
      catchError(error);
    }
  };
  const seatData = async (e) => {
    try {
      let data = await createRequest(getSeatList, e);
      let usingFilterData = data.data.filter((item) => item.isUsing === 1);
      setSeatLength(data.data.length);
      setOccData(usingFilterData.length);
      let usingSeatData = {
        oneTicket: 0,
        dayTicket: 0,
        timeTicket: 0,
      };
      for (let i = 0; i < usingFilterData.length; i++) {
        if (usingFilterData[i].ticketType === 1) {
          usingSeatData.oneTicket += 1;
        } else if (usingFilterData[i].ticketType === 2) {
          usingSeatData.dayTicket += 1;
        } else if (usingFilterData[i].ticketType === 3) {
          usingSeatData.timeTicket += 1;
        }
      }

      setUsingData(usingSeatData);
      setSeatShare(
        Math.floor(
          (data.data.filter((item) => item.isUsing === 1).length / data.data.length) * 100,
        ),
      );
    } catch (error) {
      catchError(error);
    }
  };

  const LockerData = async (e) => {
    try {
      let data = await createRequest(getLockerList, e);
      setLockerLength(data.data.length);
      setUsingLocker(data.data.filter((item) => item.userId).length);
      setLockerShare(
        Math.floor((data.data.filter((item) => item.userId).length / data.data.length) * 100),
      );
    } catch (error) {
      catchError(error);
    }
  };

  const messageData = async (e) => {
    try {
      const res = await createRequest(getMerchantInfo, e);
      setMassageLength(res.data[0].maxSendMessage);
      setUsingMassage(res.data[0].sendMessageUseCount);
      setMassageShare(
        Math.floor((res.data[0].sendMessageUseCount / res.data[0].maxSendMessage) * 100),
      );
    } catch (error) {
      catchError(error);
    }
  };
  useEffect(() => {
    getMerchantId();
  }, []);

  const series = [
    {
      name: '사용 좌석',
      data: [occData],
    },
    {
      name: '남은 좌석',
      data: [seatLength - occData],
    },
  ];

  const lockerSeries = [
    {
      name: '사용 사물함',
      data: [usingLocker],
    },
    {
      name: '남은 사물함',
      data: [lockerLength - usingLocker],
    },
  ];

  const massageSeries = [
    {
      name: '사용 메시지',
      data: [usingMassage],
    },
    {
      name: '남은 메시지',
      data: [massageLength - usingMassage],
    },
  ];
  return (
    <Fragment>
      <StyleContainer>
        <Container style={{ padding: 0 }} fluid={true}>
          <Card>
            <CardBody>
              <h5>매장 개요</h5>
              <p>좌석 점유율</p>
              <div className="datachart">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="bar"
                  width="200"
                  height="60"
                />
                <p>
                  {seatShare ? seatShare : 0}%<br />
                  <span>
                    {occData}/{seatLength}
                  </span>
                </p>
              </div>

              <p>사물함 점유율</p>
              <div className="datachart">
                <ReactApexChart
                  options={options}
                  series={lockerSeries}
                  type="bar"
                  width="200"
                  height="60"
                />
                <p>
                  {lockerShare ? lockerShare : 0}%<br />
                  <span>
                    {usingLocker}/{lockerLength}
                  </span>
                </p>
              </div>

              <p>메시지 사용량</p>
              <div className="datachart">
                <ReactApexChart
                  options={options}
                  series={massageSeries}
                  type="bar"
                  width="200"
                  height="60"
                />
                <p>
                  {massageShare ? massageShare : 0}%<br />
                  <span>
                    {usingMassage}/{massageLength}
                  </span>
                </p>
              </div>
              <TicketTypeContainer>
                {ticketTypeData.map((item) => (
                  <TicketTypeBox>
                    <p className="name">{item}</p>
                    <span>
                      <p>
                        {item === '일회권'
                          ? usingData.oneTicket
                          : item === '기간권'
                          ? usingData.dayTicket
                          : usingData.timeTicket}
                        명
                      </p>
                      <p>
                        (
                        {item === '일회권'
                          ? Math.floor((usingData.oneTicket / seatLength) * 100)
                          : item === '기간권'
                          ? Math.floor((usingData.dayTicket / seatLength) * 100)
                          : Math.floor((usingData.timeTicket / seatLength) * 100)}
                        %)
                      </p>
                    </span>
                  </TicketTypeBox>
                ))}
              </TicketTypeContainer>
            </CardBody>
          </Card>
        </Container>
      </StyleContainer>
    </Fragment>
  );
};

export default StoreOverview;
