import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';
import DetailsModal from '../components/modal/detailsModal';
import moment from 'moment';
import { getSeatLogList, merchantId_api, userList } from '../lib/api';
import { seatlogListColumn } from '../data/columns';
import 'moment/locale/ko';
import PaginationComponent from '../components/pagination';
import { exportExcel } from '../components/common';

import { TabButton, TabList } from './seatList';
import { utcToKst } from '../util/time';
import PerPageSelect from '../components/table/PerPageSelect';
import Table, { TableHeaderContainerV2 } from '../components/common/Table';
import PageContainer from '../components/common/PageContainer';

import DatePickerComponent from '../components/filter/DatePicker';
import { Dropdown } from '../components/common/Dropdown';
import Search from '../components/common/Search';

import { ExcelButton } from '../components/common/Button';
import useHandleError from '../lib/hooks/useHandleError';
import useHandleAPICall from '../lib/hooks/useHandleAPICall';

export const DatePickerStyle = styled.div`
  display: grid;
  align-items: center;

  h5 {
    /* margin: 0 8px; */
    font-weight: 400;
  }
  > div > div > button {
    width: 100%;
  }
  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 9px 12px;
    font-size: 14px;
    /* margin-left: 10px; */
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    select,
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
`;

const SeatInfo = ({ setIsLoading }) => {
  const { catchError } = useHandleError();
  const { createRequest } = useHandleAPICall();

  const [merchantId, setMerchantId] = useState('');
  const [lists, setLists] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchKeyword, setSearchKeyword] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  const [totalLength, setTotalLength] = useState(0);
  const [detailUserModal, setDetailUserModal] = useState(false);

  const [userData, setUserData] = useState({});
  const [defaultList, setDefaultList] = useState([]);

  const [tabData, setTabData] = useState('all');
  const [searchFilterData, setSearchFilterData] = useState([]);
  const [searchOption, setSearchOption] = useState('좌석번호');

  let searchKeywordData = ['좌석번호', '유형', '전화번호', '권번'];

  const getMerchantId = async () => {
    try {
      const { data } = await merchantId_api();
      setMerchantId(data.id);
    } catch (error) {}
  };

  const sendDetailData = (phoneNum) => {
    getUserList(phoneNum);
  };

  const getUserList = async (phoneNum) => {
    const result = await userList(merchantId, phoneNum);
    if (result && result.status === 200) {
      setUserData(result.data[0]);
      setDetailUserModal(!detailUserModal);
    }
  };

  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  const diffTime = (t2, t1) => {
    const duration = moment.duration(moment(t2).diff(moment(t1)));

    const days = duration.days();
    duration.subtract(moment.duration(days, 'days'));

    const hours = days * 24 + duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  const getSeatlogListApi = async (startDate, endDate) => {
    try {
      if (merchantId) {
        const data = await createRequest(
          getSeatLogList,
          merchantId,
          moment(startDate).format('YYYYMMDD000000'),
          moment(endDate).format('YYYYMMDD235959'),
        );
        const result = data.data.result;
        const dataCount = data.data.result.length;
        setTotalLength(dataCount);
        if (dataCount > 0 && data.status === 200) {
          let ticketIdSet = new Set();
          let ticketIdMap = {};
          for (let i = dataCount - 1; i >= 0; i--) {
            const item = result[i];
            console.log(dataCount);
            if (item.ticketType === 5) continue;
            if (ticketIdSet.has(item.ticketId)) {
              ticketIdMap[item.ticketId].push({ dateTime: item.createdAt, message: item.message });
            } else {
              if (item.message.includes('입장')) {
                ticketIdSet.add(item.ticketId);
                ticketIdMap[item.ticketId] = [{ dateTime: item.createdAt, message: item.message }];
              }
            }
          }
          let expireDateMap = {};
          let processedList = [];
          for (let i = 0; i < dataCount; i++) {
            const item = result[i];
            const typeData =
              item.ticketType === 1
                ? '일회권'
                : item.ticketType === 2
                ? '기간권'
                : item.ticketType === 4
                ? '세미나실'
                : item.ticketType === 5
                ? '사물함'
                : '시간정액권';
            const type =
              item.ticketType === 1 ? (
                <div className="text-danger">일회권</div>
              ) : item.ticketType === 2 ? (
                <div className="text-primary">기간권</div>
              ) : item.ticketType === 3 ? (
                <div className="text-secondary">시간정액권</div>
              ) : item.ticketType === 4 ? (
                <div style={{ color: '#3E67B6' }}>세미나실</div>
              ) : (
                <div style={{ color: '#AB84FF' }}>사물함</div>
              );
            const lastIn = moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss');
            const isUsing = (
              <div
                className={`btn-${
                  item.message === '입장' || item.message === '사물함 사용'
                    ? 'secondary'
                    : 'success'
                } btn-sm`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                {item.message}
              </div>
            );
            const phoneNumTag = (
              <div
                onClick={() => {
                  sendDetailData(item.phoneNum);
                }}
                className="phoneNum-click">
                {item.phoneNum}
              </div>
            );
            const action = moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss');
            const numberName = (item.number !== null ? item.number : '-') + '번';
            let expireDate = '-';
            if (expireDateMap[item.id]) {
              expireDate = expireDateMap[item.id];
            } else {
              const ticketData = ticketIdMap[item.ticketId];
              if (ticketData) {
                const firstTicket = ticketData[0];
                const lastTicket = ticketData.length > 1 ? ticketData[ticketData.length - 1] : -1;
                if (item.ticketType !== 5) {
                  if (lastTicket !== -1) {
                    if (lastTicket.message.includes('퇴장')) {
                      if (firstTicket.message.includes('퇴장')) {
                        expireDate = '해당기간의 입장내역이 없습니다.';
                      } else {
                        const duration = moment.duration(
                          moment(utcToKst(lastTicket.dateTime)).diff(
                            moment(utcToKst(firstTicket.dateTime)),
                          ),
                        );
                        if (duration.asSeconds() < 0) {
                          // 체류 시간이 음수인 경우
                          expireDate = '해당 기간에 입장 또는 퇴장 내역이 없습니다.';
                        } else {
                          const hours = Math.floor(duration.asHours());
                          const minutes = duration.minutes();
                          const seconds = duration.seconds();
                          expireDate = `${hours.toString().padStart(2, '0')}:${minutes
                            .toString()
                            .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                        }
                      }
                    } else {
                      const duration = moment.duration(
                        moment().diff(moment(utcToKst(firstTicket.dateTime))),
                      );
                      if (duration.asSeconds() < 0) {
                        expireDate = '해당 기간에 입장 또는 퇴장 내역이 없습니다.';
                      } else {
                        const hours = Math.floor(duration.asHours());
                        const minutes = duration.minutes();
                        const seconds = duration.seconds();
                        expireDate = `${hours.toString().padStart(2, '0')}:${minutes
                          .toString()
                          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                      }
                    }
                  } else {
                    const duration = moment.duration(
                      moment().diff(moment(utcToKst(firstTicket.dateTime))),
                    );
                    if (duration.asSeconds() < 0) {
                      expireDate = '해당 기간에 입장 또는 퇴장 내역이 없습니다.';
                    } else {
                      const hours = Math.floor(duration.asHours());
                      const minutes = duration.minutes();
                      const seconds = duration.seconds();
                      expireDate = `${hours.toString().padStart(2, '0')}:${minutes
                        .toString()
                        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                    }
                  }
                }
                expireDateMap[item.id] = expireDate;
              }
            }
            processedList.push({
              ...item,
              typeData,
              type,
              lastIn,
              isUsing,
              phoneNumTag,
              action,
              numberName,
              expireDate,
            });
          }
          if (tabData === 'locker') {
            let ticketData = processedList.filter(
              (item) => item.ticketType === 5 || item.seatType === 'locker',
            );
            setLists(ticketData);
            setSearchFilterData(ticketData);
            setCurrentPage(1);
          } else if (tabData === 'seat') {
            let ticketData = processedList.filter((item) => item.ticketType === 1);
            setLists(ticketData);
            setSearchFilterData(ticketData);
            setCurrentPage(1);
          } else {
            setLists(processedList);
            setSearchFilterData(processedList);
            setCurrentPage(1);
          }
          setDefaultList(processedList);
        } else if (dataCount === 0 && data.status === 200) {
          setDefaultList([]);
        }
      }
    } catch (error) {
      console.log(error);
      catchError(error);
    }
  };

  // const getSeatlogListApi = async (startDate, endDate) => {
  //   if (merchantId) {
  //     setIsLoading(true);

  //     const data = await getSeatLogList(
  //       merchantId,
  //       moment(startDate).format('YYYYMMDD000000'),
  //       moment(endDate).format('YYYYMMDD235959'),
  //     );
  //     const result = data.data.result;
  //     const dataCount = data.data.count;

  //     setTotalLength(dataCount);

  //     if (dataCount > 0 && data.status === 200) {
  //       // 체류 시간 계산 (expireDate)
  //       let ticketIdSet = new Set();
  //       let ticketIdMap = {};

  //       for (let i = dataCount - 1; i >= 0; i--) {
  //         const item = result[i];
  //         if (item.ticketType === 5) continue;
  //         if (ticketIdSet.has(item.ticketId)) {
  //           ticketIdMap[item.ticketId].push({ dateTime: item.createdAt, message: item.message });
  //         } else {
  //           if (item.message.includes('입장')) {
  //             ticketIdSet.add(item.ticketId);
  //             ticketIdMap[item.ticketId] = [{ dateTime: item.createdAt, message: item.message }];
  //           }
  //         }
  //       }

  //       //체류시간 저장
  //       let expireDateMap = {};

  //       let processedList = [];

  //       for (let i = 0; i < dataCount; i++) {
  //         const item = result[i];

  //         const typeData =
  //           item.ticketType === 1
  //             ? '일회권'
  //             : item.ticketType === 2
  //             ? '기간권'
  //             : item.ticketType === 4
  //             ? '세미나실'
  //             : item.ticketType === 5
  //             ? '사물함'
  //             : '시간정액권';

  //         const type =
  //           item.ticketType === 1 ? (
  //             <div className="text-danger">일회권</div>
  //           ) : item.ticketType === 2 ? (
  //             <div className="text-primary">기간권</div>
  //           ) : item.ticketType === 3 ? (
  //             <div className="text-secondary">시간정액권</div>
  //           ) : item.ticketType === 4 ? (
  //             <div style={{ color: '#3E67B6' }}>세미나실</div>
  //           ) : (
  //             <div style={{ color: '#AB84FF' }}>사물함</div>
  //           );

  //         const lastIn = moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss');
  //         const isUsing = (
  //           <div
  //             className={`btn-${
  //               item.message === '입장' || item.message === '사물함 사용' ? 'secondary' : 'success'
  //             } btn-sm`}
  //             style={{
  //               display: 'flex',
  //               alignItems: 'center',
  //               justifyContent: 'center',
  //             }}>
  //             {item.message}
  //           </div>
  //         );

  //         const phoneNumTag = (
  //           <div
  //             onClick={() => {
  //               sendDetailData(item.phoneNum);
  //             }}
  //             className="phoneNum-click">
  //             {item.phoneNum}
  //           </div>
  //         );
  //         const action = moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'); // 확인
  //         const numberName = (item.number !== null ? item.number : '-') + '번';

  //         let expireDate = '-';
  //         if (expireDateMap[item.id]) {
  //           expireDate = expireDateMap[item.id];
  //         } else {
  //           const ticketData = ticketIdMap[item.ticketId];
  //           if (ticketData) {
  //             const firstTicket = ticketData[0];
  //             const lastTicket = ticketData.length > 1 ? ticketData[ticketData.length - 1] : -1;

  //             if (item.ticketType !== 5) {
  //               if (lastTicket !== -1) {
  //                 if (lastTicket.message.includes('퇴장')) {
  //                   if (firstTicket.message.includes('퇴장')) {
  //                     expireDate = '해당기간의 입장내역이 없습니다.';
  //                   } else {
  //                     expireDate = diffTime(
  //                       utcToKst(lastTicket.dateTime),
  //                       utcToKst(firstTicket.dateTime),
  //                     );
  //                   }
  //                 } else {
  //                   expireDate = diffTime(
  //                     moment().format('YYYY-MM-DD HH:mm:ss'),
  //                     utcToKst(firstTicket.dateTime),
  //                   );
  //                 }
  //               } else {
  //                 expireDate = diffTime(
  //                   moment().format('YYYY-MM-DD HH:mm:ss'),
  //                   utcToKst(firstTicket.dateTime),
  //                 );
  //               }
  //             }

  //             expireDateMap[item.id] = expireDate;
  //           }
  //         }

  //         processedList.push({
  //           ...item,
  //           typeData,
  //           type,
  //           lastIn,
  //           isUsing,
  //           phoneNumTag,
  //           action,
  //           numberName,
  //           expireDate,
  //         });
  //       }

  //       if (tabData === 'locker') {
  //         let ticketData = processedList.filter(
  //           (item) => item.ticketType === 5 || item.seatType === 'locker',
  //         );
  //         setLists(ticketData);
  //         setSearchFilterData(ticketData);
  //         setCurrentPage(1);
  //       } else if (tabData === 'seat') {
  //         let ticketData = processedList.filter((item) => item.ticketType === 1);
  //         setLists(ticketData);
  //         setSearchFilterData(ticketData);
  //         setCurrentPage(1);
  //       } else {
  //         setLists(processedList);
  //         setSearchFilterData(processedList);
  //         setCurrentPage(1);
  //       }
  //       setDefaultList(processedList);
  //     } else if (dataCount === 0 && data.status === 200) {
  //       setDefaultList([]);
  //     }
  //     setIsLoading(false);
  //   } else {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (!merchantId) return;
  //   // getSeatlogListApi(merchantId);
  // }, [merchantId, startDate, endDate]);

  const handleSearchKeyword = (keyword) => {
    if (keyword === '') {
      setSearchKeyword(null);
    } else {
      setSearchKeyword(keyword);
    }
  };

  const searchData = () => {
    if (!searchKeyword) {
      setLists(searchFilterData);
    } else {
      let copyData = [...searchFilterData];
      let searchData;
      if (searchOption === '유형') {
        searchData = copyData.filter((item) => item.typeData?.includes(searchKeyword));
      } else if (searchOption === '좌석번호') {
        if (searchKeyword.includes('번')) {
          searchData = copyData.filter((item) => item.numberName?.toString() === searchKeyword);
        } else {
          searchData = copyData.filter(
            (item) => item.numberName?.toString() === searchKeyword + '번',
          );
        }
      } else if (searchOption === '전화번호') {
        searchData = copyData.filter((item) => item.phoneNum?.includes(searchKeyword));
      } else {
        searchData = copyData.filter((item) => item.ticketId?.toString().includes(searchKeyword));
      }

      setLists(searchData);
    }
    setCurrentPage(1);
  };

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  const changeShowData = (tabName) => {
    setIsLoading(true);
    setTabData(tabName);
    setCurrentPage(1);
    setSearchKeyword(null);
    if (tabName === 'all') {
      setLists(defaultList);
      setSearchFilterData(defaultList);
      setTotalLength(defaultList.length);
    } else if (tabName === 'seat') {
      const ticketData = defaultList.filter((item) => item.ticketType === 1);
      setLists(ticketData);
      setSearchFilterData(ticketData);
      setTotalLength(ticketData.length);
    } else if (tabName === 'locker') {
      const lockerData = defaultList.filter(
        (item) => item.ticketType === 5 || item.seatType === 'locker',
      );
      setLists(lockerData);
      setSearchFilterData(lockerData);
      setTotalLength(lockerData.length);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getMerchantId();
  }, []);

  useEffect(() => {
    if (!merchantId) return;
    getSeatlogListApi(startDate, endDate);
  }, [merchantId]);

  return (
    <PageContainer
      title="맵뷰어 상세 조회"
      itemsRightSideTitle={[
        <ExcelButton onClick={() => exportExcel(lists, startDate, endDate, '맵뷰어 상세 조회')} />,
      ]}>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          seat={userData}
          back={true}
        />
      )}

      <TabList>
        <TabButton selected={tabData === 'all'} onClick={() => changeShowData('all')}>
          전체
        </TabButton>
        <TabButton selected={tabData === 'locker'} onClick={() => changeShowData('locker')}>
          사물함
        </TabButton>
        <TabButton selected={tabData === 'seat'} onClick={() => changeShowData('seat')}>
          일회권
        </TabButton>
      </TabList>
      <TableHeaderContainerV2>
        <DatePickerComponent
          withCalendar
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startMaxDate={endDate}
          endMinDate={startDate}
          maxPeriod={3}
          fetch={getSeatlogListApi}
        />
        {/* <DatePickerStyle>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            locale={ko}
            startDate={startDate}
            maxDate={endDate}
            endDate={endDate}
            customInput={<ExampleCustomInput />}
          />
          <h5> - </h5>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            locale={ko}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            customInput={<ExampleCustomInput />}
          />

          <SelectDate
            setStartDate={(val) => setStartDate(val)}
            setEndDate={(val) => setEndDate(val)}
          />
        </DatePickerStyle> */}
        <PerPageSelect onChange={setPostsPerPage} />
        <Dropdown>
          <select onChange={(e) => setSearchOption(e.target.value)} value={searchOption}>
            {searchKeywordData.map((item) => (
              <option>{item}</option>
            ))}
          </select>
        </Dropdown>
        <Search
          onSubmit={searchData}
          searchKeyword={searchKeyword}
          setSearchKeyword={handleSearchKeyword}
        />

        {/* <Form
          onSubmit={(e) => {
            e.preventDefault();
            searchData();
          }}>
          <LabelRow className="m-0">
            <Input
              placeholder="검색어를 입력해주세요"
              className="form-control"
              value={searchKeyword || ''}
              onChange={(e) => handleSearchKeyword(e.target.value)}
            />

            <SearchButton onClick={searchData} />
          </LabelRow>
        </Form> */}

        {/* <SearchContainer></SearchContainer> */}
      </TableHeaderContainerV2>

      {totalLength > 0 ? (
        <div id="seatInfoTable">
          <Table
            data={currentPosts(lists)}
            columns={seatlogListColumn}
            striped={true}
            center={true}
          />
        </div>
      ) : (
        <h6 style={{ textAlign: 'center' }}>정보가 없습니다.</h6>
      )}

      {totalLength > 0 && (
        <PaginationComponent
          setCurrentPage={setCurrentPage}
          totalLength={lists.length}
          postsPerPage={postsPerPage}
          currentPage={currentPage}
        />
      )}
    </PageContainer>
  );
};

export default SeatInfo;
