// auth 링크에서만 쓰이는 Header
import React from 'react';
import Logo from '../assets/images/logo/logo.svg';
import styled from 'styled-components';

const HeaderStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 3vw;
  height: 4rem;
  position: absolute;
  z-index: 2;
  justify-content: space-between;
  border-bottom: 1px solid #e7e8e8;
  box-shadow: 0px 4px 16px 4px #a4a8ae0a;
  background-color: #ffffff;

  img {
    width: 140px;
    max-height: 35px;
    object-fit: cover;
  }
`;

const AuthHeader = () => {
  return (
    <HeaderStyle>
      <img src={Logo} alt="logo" />
    </HeaderStyle>
  );
};

export default AuthHeader;
