import React from 'react';
import styled, { css } from 'styled-components';
import theme from '../../theme';

const getSeatColor = (type: string, seatType: number, status: string) => {
  if (status === 'using') return theme.occupiedSeat;
  if (status === 'block') return theme.unavailableSeat;
  if (type === 'seminar') return theme.seminarSeat;
  if (seatType === 1) return theme.fixedSeat;
  return theme.normalSeat;
};
const getSeatTextColor = (status: string) => {
  if (status === 'block') return theme.neutralGray[500];
  return theme.normalSeat;

  // if (status === 'block') return theme.neutralGray[400];
  // if (status === 'using') return theme.neutralGray[100];
  // return theme.indigo;
};

const getSeatChangeTextColor = (status: string) => {
  if (status === 'block') return theme.neutralGray[400];
  if (status === 'using') return theme.neutralGray[100];
  return theme.indigo;
};

const getSeatMapColor = (type: string, seatType: number, ticketType: number, status: string) => {
  if (status === 'block') return theme.unavailableSeat;
  if (type === 'seminar') {
    if (status === 'idle') return theme.idleSeat;
    return theme.seminarSeat;
  }
  if (status === 'idle' && seatType === 0) return theme.idleSeat;
  if (status === 'idle' && seatType === 1) return theme.idleSeat;
  if (status === 'using') {
    if (seatType === 1) return theme.fixedSeatWithPeriodicTicket;
    if (ticketType === 1) return theme.normalSeatWithDisposableTicket;
    if (ticketType === 2) return theme.normalSeatWithPeriodicTicket;
    if (ticketType === 3) return theme.normalSeatWithTimeBasedTicket;
  }

  if (status === 'rest') {
    if (seatType === 1) return theme.fixedSeatWithPeriodicTicketRest;
    if (ticketType === 1) return theme.normalSeatWithDisposableTicketRest;
    if (ticketType === 2) return theme.normalSeatWithPeriodicTicketRest;
    if (ticketType === 3) return theme.normalSeatWithTimeBasedTicketRest;
  }
  return theme.normalSeat;
};
const getSeatMapBorderColor = (
  type: string,
  seatType: number,
  ticketType: number,
  status: string,
) => {
  if (status === 'block') return theme.unavailableSeat;
  if (type === 'seminar') return theme.seminarSeat;
  if (status === 'idle' && seatType === 0) return theme.neutralGray[300];
  if (status === 'idle' && seatType === 1) return theme.fixedSeatWithPeriodicTicket;

  if (status === 'using') {
    if (seatType === 1) return theme.fixedSeatWithPeriodicTicket;
    if (ticketType === 1) return theme.normalSeatWithDisposableTicket;
    if (ticketType === 2) return theme.normalSeatWithPeriodicTicket;
    if (ticketType === 3) return theme.normalSeatWithTimeBasedTicket;
  }

  if (status === 'rest') {
    if (seatType === 1) return theme.fixedSeatWithPeriodicTicketRest;
    if (ticketType === 1) return theme.normalSeatWithDisposableTicketRest;
    if (ticketType === 2) return theme.normalSeatWithPeriodicTicketRest;
    if (ticketType === 3) return theme.normalSeatWithTimeBasedTicketRest;
  }

  return theme.normalSeat;
};

export const SeatV2 = styled.div<{
  top: number;
  left: number;
  status: string;
  type: string;
  seatType: number;
  rest?: boolean;
}>`
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};
  position: absolute;
  color: ${({ status }) => getSeatChangeTextColor(status)};
  background: ${({ type, seatType, status }) => getSeatColor(type, seatType, status)};

  width: 3rem;
  height: 3rem;
  padding: 0.125rem;
  /* display: flex;
  flex-direction: column; */

  display: grid;
  grid-template-rows: repeat(3, 1fr);

  opacity: ${(props) => (props.rest ? 0.8 : 1)};
  p {
    grid-row: 2;
    margin-bottom: 0;
    font-size: 1.125rem;
    font-weight: 600;
    text-align: center;
    line-height: 100%;
  }
  .seatType {
    font-size: 1.4vw;
  }
`;

export const MapViewSeat = styled.div<{
  top: number;
  left: number;
  status: string;
  type: string;
  seatType: number;
  ticketType: number;
  rest: boolean;
  ratio?: number;
  isMin?: boolean;
}>`
  display: grid;
  grid-template-rows: repeat(3, 1fr);

  position: absolute;
  color: ${({ status }) => getSeatTextColor(status)};
  background: ${({ type, seatType, ticketType, status }) =>
    getSeatMapColor(type, seatType, ticketType, status)};

  padding: 0;

  div {
    margin: 0;
    font-weight: 600;
    text-align: center;
    line-height: 110%;
    letter-spacing: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .seatNumber {
    grid-row: 2;
    margin-bottom: 0;
  }
  .seatInfoAndRemainedTime,
  .userInfo {
    grid-row: 1 span 3;
    vertical-align: middle;
  }

  ${({ isMin, top, left, ratio, type, seatType, ticketType, status }) =>
    isMin
      ? css`
          border: calc(${(ratio || 1) * 2}px) solid
            ${getSeatMapBorderColor(type, seatType, ticketType, status)};
          top: ${top * (ratio || 1)}px;
          left: ${left * (ratio || 1)}px;
          width: calc(5rem * ${ratio || 1});
          height: calc(3.625rem * ${ratio || 1});
          .seatNumber {
            font-size: calc(1.125rem * ${ratio || 1});
          }
          .seatInfoAndRemainedTime,
          .userInfo {
            height: calc(3.4rem * ${ratio || 1});
            font-size: calc(0.7rem * ${ratio || 1});
          }
          .seatType {
            font-size: 1.4vw;
          }
        `
      : css`
          border: 2px solid ${getSeatMapBorderColor(type, seatType, ticketType, status)};
          top: ${top}px;
          left: ${left}px;
          width: 5rem;
          height: 3.625rem;
          .seatNumber {
            font-size: 1.125rem;
          }
          .seatInfoAndRemainedTime,
          .userInfo {
            height: 3.4rem;
            font-size: 0.7rem;
          }
          .seatType {
            font-size: 1.4vw;
          }
        `}
`;

export const MapViewLockerV1 = styled.div<{
  ratio?: number;
  isMin?: boolean;
  top: number;
  left: number;
  status: string;
  type: string;
  warning: boolean;
  blocked: boolean;
  isUse: boolean;
  toggle?: boolean;
}>`
  position: relative;
  background-color: ${(props) =>
    props.warning
      ? props.theme.warning
      : props.blocked
      ? props.theme.unavailableSeat
      : props.isUse
      ? props.theme.occupiedSeat
      : '#fff'};

  color: ${({ isUse, theme }) => (isUse ? theme.white : theme.occupiedSeat)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${({ toggle }) => (toggle ? '0.475rem' : '1.5rem')};
  font-weight: 600;

  text-align: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 0;
    /* text-align: center; */
    font-size: 1rem;
  }

  ${({ isMin, ratio, toggle }) =>
    isMin
      ? css`
          width: calc(3rem * ${ratio || 1});
          height: calc(3rem * ${ratio || 1});
          font-size: ${toggle ? `calc(0.475rem * ${ratio || 1})` : `calc(1.5rem * ${ratio || 1})`};
          border: ${Math.max(1 * (ratio || 1), 0.125)}px solid #546382;
        `
      : css`
          width: 3rem;
          height: 3rem;
          font-size: ${toggle ? '0.475rem' : '1.5rem'};
          border: 1px solid #546382;
        `}
`;

export const MapViewLocker = styled.div<{
  ratio?: number;
  isMin?: boolean;
  top: number;
  left: number;
  status: string;
  type: string;
  warning: boolean;
  blocked: boolean;
  isUse: boolean;
  toggle?: boolean;
}>`
  position: absolute;
  background-color: ${(props) =>
    props.warning
      ? props.theme.warning
      : props.blocked
      ? props.theme.unavailableSeat
      : props.isUse
      ? props.theme.occupiedSeat
      : '#fff'};

  color: ${({ isUse, theme }) => (isUse ? theme.white : theme.occupiedSeat)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${({ toggle }) => (toggle ? '0.475rem' : '1.5rem')};
  font-weight: 600;

  text-align: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 0;
    /* text-align: center; */
    font-size: 1rem;
  }

  ${({ isMin, top, left, ratio, toggle }) =>
    isMin
      ? css`
          top: ${top * (ratio || 1)}px;
          left: ${left * (ratio || 1)}px;
          width: calc(3rem * ${ratio || 1});
          height: calc(3rem * ${ratio || 1});
          font-size: ${toggle ? `calc(0.475rem * ${ratio || 1})` : `calc(1.5rem * ${ratio || 1})`};
          border: ${Math.max(1 * (ratio || 1), 0.125)}px solid #546382;
        `
      : css`
          top: ${top}px;
          left: ${left}px;
          width: 3rem;
          height: 3rem;
          font-size: ${toggle ? '0.475rem' : '1.5rem'};
          border: 1px solid #546382;
        `}
`;

export const SeatUserInfoHoverArea = styled.div<{
  x: number;
  y: number;
  status: string;
  type: string;
  seatType: number;
  ticketType: number;
  rest: boolean;
}>`
  position: fixed;
  z-index: 10;

  left: ${({ x }) => `${x}px`};
  top: ${({ y }) => `${y}px`};

  padding: 0.5rem;
  border-radius: 0.5rem;

  color: black;
  background: white;

  text-align: center;
`;

const StyledSeatLevel = styled.div<{ inverted?: boolean; ratio?: number; isMinimalized?: boolean }>`
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ inverted, theme }) =>
    inverted
      ? css`
          color: ${theme.indigo};
          background: ${theme.white};
        `
      : css`
          color: ${theme.white};
          background: ${theme.indigo};
        `}
  ${({ isMinimalized, ratio }) =>
    isMinimalized
      ? css`
          height: calc(${ratio} * 0.75rem);
          width: calc(${ratio} * 1.5rem);
          font-size: calc(${ratio} * 0.625rem);
          border-radius: calc(${ratio} * 1rem);
        `
      : css`
          height: 0.75rem;
          width: 1.5rem;
          font-size: 0.625rem;
          border-radius: 1rem;
        `}
`;

export const SeatLevel = ({
  level,
  inverted,
  ratio,
  isMinimalized,
}: {
  level: number;
  inverted?: boolean;
  ratio?: number;
  isMinimalized?: boolean;
}) => (
  <StyledSeatLevel inverted={inverted || false} ratio={ratio} isMinimalized={isMinimalized}>
    Lv.{level}
  </StyledSeatLevel>
);

export const LockerViewV2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  width: 100%;
  max-width: 1080px;
  height: 1440px;
  border: 3px solid #222;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 0.6vw;
    height: 0.6vw;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c4cbd6;
    border-radius: 1vw;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 1vw;
  }
`;

export const LockerViewV1 = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  width: 100%;
  max-width: 1080px;
  border: 3px solid #222;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 0.6vw;
    height: 0.6vw;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c4cbd6;
    border-radius: 1vw;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 1vw;
  }
`;
