import React from 'react';
import styled, { css } from 'styled-components';

import IconExcel from '../../assets/images/button/icon_button_excel.svg';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  buttonType: 'default' | 'action' | 'warning' | 'excel';
  striped?: boolean;
  color?: string;
  disabled?: boolean;
  icon?: string;
  style?: React.CSSProperties;
}

const BaseButton = styled.button`
  padding: 0.5rem 1rem;
  /* min-width: 6rem; */
  border-radius: 0.375rem;
`;

export const Button = ({
  buttonType,
  striped,
  color,
  icon,
  disabled,
  style,
  children,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton
      buttonType={buttonType}
      striped={striped}
      color={color}
      style={style}
      icon={icon}
      {...props}>
      {icon && <img src={icon} alt="button-icon" />}
      <span>{children}</span>
    </StyledButton>
  );
};

export const StyledButton = styled(BaseButton)<ButtonProps>`
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  background: ${({ theme, buttonType, color }) =>
    color
      ? color
      : buttonType === 'default'
      ? theme.secondary[600]
      : buttonType === 'action'
      ? theme.primary
      : buttonType === 'excel'
      ? theme.excel
      : theme.red};

  ${({ striped, buttonType }) =>
    striped &&
    css`
      background: white;
      border: 1px solid
        ${({ theme }) =>
          buttonType === 'default'
            ? theme.secondary[600]
            : buttonType === 'action'
            ? theme.primary
            : theme.red};
      color: ${({ theme }) =>
        buttonType === 'default'
          ? theme.secondary[600]
          : buttonType === 'action'
          ? theme.primary
          : theme.red};
    `}

  &:hover {
    filter: saturate(150%);
  }
  &:disabled {
    filter: grayscale(100%);
  }

  @media screen and (min-width: 768px) {
    min-width: 6rem;
  }

  @media screen and (max-width: 767px) {
    /* width: 100%;
    min-width: 100%; */
    padding: 0.5rem 0.625rem;
    ${({ icon }) =>
      icon &&
      css`
        span {
          display: none;
        }
      `}
  }
`;

export const ActionButton = styled(StyledButton)`
  @media screen and (max-width: 767px) {
    min-width: 6rem;
  }
`;

export const ToggleButton = styled(BaseButton)<{ enabled?: boolean }>`
  border: 1px solid ${({ theme }) => theme.neutralGray[200]};

  color: ${({ theme, enabled }) => (enabled ? theme.main[600] : 'black')};
`;

export const ExcelButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button buttonType="excel" icon={IconExcel} onClick={onClick}>
      엑셀 출력하기
    </Button>
  );
};
