import React, { useEffect, useState } from 'react';
import Receipt from '../components/receipt';
import { getReceipt } from '../lib/api';
import Swal from 'sweetalert2';

const WebReceipt = () => {
  const getUrl = window.location.pathname;
  const transactionId = getUrl.replace('/receipt/', '');
  const [receiptData, setReceiptData] = useState({});
  console.log(transactionId);

  const getReceiptData = async () => {
    try {
      const { data, status } = await getReceipt(transactionId);
      console.log(data, status);
      if (status === 200) {
        setReceiptData(data);
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: 'error',
        text: '정보를 가져올 수 없습니다. 다시 시도해주세요',
        timer: 3500,
      });
    }
  };

  useEffect(() => {
    getReceiptData();
  }, []);
  return <Receipt receiptData={receiptData} />;
};

export default WebReceipt;
