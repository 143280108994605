import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = debounce((e: MouseEvent) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    }, 8); // equiv. 1frame@120fps

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      handleMouseMove.cancel(); // 컴포넌트 언마운트 시 디바운스 취소
    };
  });

  return mousePosition;
};

export default useMousePosition;
