import { AxiosRequestConfig } from 'axios';

class Token {
  private axiosHeaderConfig: Record<string, string>;
  private token: string | null;
  private refreshToken: string | null;

  constructor() {
    this.refreshToken = null;
    this.token = null;
    this.axiosHeaderConfig = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    };
  }

  public getToken(): string | null {
    return this.token;
  }
  public getRefreshToken(): string | null {
    return this.refreshToken;
  }

  public setToken(tokenKeyValue: AccessTokenKey): void {
    this.token = localStorage.getItem(tokenKeyValue);
  }
  public setRefreshToken(tokenKeyValue: RefreshTokenKey): void {
    this.refreshToken = localStorage.getItem(tokenKeyValue);
  }

  public getAxiosConfig(): AxiosRequestConfig {
    return { headers: this.axiosHeaderConfig };
  }

  public setTokenInAxiosConfig(): void {
    this.axiosHeaderConfig['Authorization'] = `Bearer ${this.token}`;
  }

  public setRefreshTokenInAxiosConfig(): void {
    this.axiosHeaderConfig['Refreshtoken'] = this.refreshToken?.toString() || '';
  }

  public setContentTypeInAxiosConfig(contentType: string): void {
    this.axiosHeaderConfig['Content-Type'] = contentType;
  }

  public getConfigWithToken(tokenKeyValue: AccessTokenKey): AxiosRequestConfig {
    this.setToken(tokenKeyValue);
    this.setTokenInAxiosConfig();
    return this.getAxiosConfig();
  }
  public getConfigWithRefreshToken(tokenKeyValue: RefreshTokenKey): AxiosRequestConfig {
    this.setRefreshToken(tokenKeyValue);
    this.setRefreshTokenInAxiosConfig();
    return this.getAxiosConfig();
  }
}

export default Token;

export const AllTokenKey: TokenKey[] = [
  'userToken',
  'merchantToken',
  'adminToken',
  'userRefreshToken',
  'merchantRefreshToken',
  'adminRefreshToken',
];

/**
 * 토큰 세트
 * @param key 토큰 키
 * @param value 토큰 정보
 * @returns none
 */
export const setToken = (key: string, value: string) => localStorage.setItem(key, value);

/**
 * 모든 토큰을 가져온다.
 * @returns
 */
export const getAllToken: () => TokenList = () => {
  const getToken = (tokenKey: TokenKey): string | null => {
    const token = new Token();
    token.setToken(tokenKey as AccessTokenKey);
    return token.getToken();
  };
  const userToken = getToken('userToken');
  const merchantToken = getToken('merchantToken');
  const userRefreshToken = getToken('userRefreshToken');
  const merchantRefreshToken = getToken('merchantRefreshToken');
  const adminToken = getToken('adminToken');
  const adminRefreshToken = getToken('adminRefreshToken');
  return {
    userToken,
    merchantToken,
    adminToken,
    userRefreshToken,
    merchantRefreshToken,
    adminRefreshToken,
  };
};

/**
 * 토큰 제거
 * @param tokenKeyList 제거하려는 키 목록
 */
export const removeToken = (tokenKeyList: TokenKey[] = AllTokenKey): void => {
  tokenKeyList.forEach((tokenKey) => {
    localStorage.removeItem(tokenKey);
  });
};
