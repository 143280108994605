import { isAxiosError } from 'axios';
import { swalMessage } from '../../components/common';

const parseToJsonString = (content: any) => {
  try {
    const stringified = JSON.stringify(content);
    if (typeof content === 'string') return content;
    return stringified;
  } catch (error) {
    return content;
  }
};

const useHandleError = () => {
  const createErrorMessage = (error: any, fallbackErrorMessage?: string) => {
    try {
      // 일반 Error 객체인 경우
      if (isAxiosError(error)) {
        const { response } = error;
        if (response?.data) return response.data as string;
        return `잘못된 요청입니다. 잠시 후 다시 시도해 주세요.\n문제가 계속 발생하는 경우 관리자에게 문의해 주세요.\n\n${
          fallbackErrorMessage || parseToJsonString(error)
        }`;
      }
      const { message } = error;
      if (message)
        return `웹 페이지에서 처리되지 않은 오류가 발생했습니다.\n관리자에게 문의해 주세요.`;
      return fallbackErrorMessage || parseToJsonString(error);
    } catch (error) {
      return fallbackErrorMessage || error
        ? parseToJsonString(error)
        : '알 수 없는 오류가 발생했습니다.\n관리자에게 문의해 주세요.';
    }
  };

  const catchError = (error: any, fallbackErrorMessage?: string) => {
    const errorMessage = createErrorMessage(error, fallbackErrorMessage);
    swalMessage('error', errorMessage);
  };

  return { catchError };
};

export default useHandleError;
