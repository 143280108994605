import React from 'react';
import { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import { multiOpenDoor, multiPcRestart, multiProgramRestart, openDoor } from '../../lib/socket';

const ModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 101;
`;

const Select = styled.select`
  background: #f8fcff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 8px 15px;
  margin-right: 20px;
  width: 210px;

  > p {
    font-weight: 500;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;

  .btn-primary {
    margin-left: 10px;
  }
`;
const OpenDoorModal = ({ show, onClose, data, id, kind }) => {
  const [choiceNum, setChoiceNum] = useState(1);

  const openDoorFunc = () => {
    if (+choiceNum === 1) openDoor(id);
    else {
      multiOpenDoor(id, +choiceNum);
    }
  };

  return (
    <>
      <Modal isOpen={show} toggle={onClose} centered>
        <ModalBody>
          <p>
            {kind === 'PC'
              ? '재시작 할 내부 PC를 선택해주세요'
              : kind === 'Program'
              ? '재시작 할 내부 프로그램를 선택해주세요'
              : '문을 열 키오스크를 선택해주세요'}
          </p>

          <Select value={choiceNum} onChange={(e) => setChoiceNum(+e.target.value)}>
            {data &&
              [...Array(data)].map((item, index) => (
                <option value={index + 1}>{index + 1}층</option>
              ))}
          </Select>
          <Buttons>
            <button className="btn-secondary btn-sm" onClick={() => onClose(false)}>
              취소
            </button>
            {kind === 'PC' ? (
              <button className="btn-primary btn-sm" onClick={() => multiPcRestart(id, +choiceNum)}>
                PC재시작
              </button>
            ) : kind === 'Program' ? (
              <button
                className="btn-primary btn-sm"
                onClick={() => multiProgramRestart(id, +choiceNum)}>
                프로그램 재시작
              </button>
            ) : (
              <button className="btn-primary btn-sm" onClick={() => openDoorFunc()}>
                문열기
              </button>
            )}
          </Buttons>
        </ModalBody>
      </Modal>
      <ModalBackground />
    </>
  );
};

export default OpenDoorModal;
