import React from 'react';
import styled, { css } from 'styled-components';
import theme from '../../theme';

type DisclaimerType = 'info' | 'warning' | 'misc' | 'green';

const DISCLAIMER_COLORS = {
  info: {
    background: theme.main[100],
    main: theme.main[600],
  },
  warning: {
    background: '#fbebeb',
    main: '#d43434',
  },
  misc: {
    background: theme.secondary[100],
    main: theme.secondary[500],
  },
  green: {
    background: '#E8F0EB',
    main: '#186A3A',
  },
};

const StyledDisclaimer = styled.div<{
  type: DisclaimerType;
  dense: boolean;
}>`
  display: grid;
  grid-template-columns: 1.5rem auto;
  gap: 0.5rem;
  align-items: start;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  white-space: pre-line;
  line-height: 150%;

  background: ${({ type }) => DISCLAIMER_COLORS[type].background};
  color: ${({ type }) => DISCLAIMER_COLORS[type].main};
  font-size: 1rem;

  ${({ dense }) =>
    dense &&
    css`
      padding: 0.5rem;
      font-size: 0.875rem;
    `}
`;

const InfoIcon = ({ fillColor }: { fillColor: string }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="ic-18_info_outline">
        <circle id="Ellipse 204" cx="9" cy="9" r="6.75" stroke={fillColor} stroke-width="1.5" />
        <path
          id="i"
          d="M9.00586 13.4824C8.52692 13.4824 8.13867 13.0942 8.13867 12.6152V7.98633C8.13867 7.50739 8.52692 7.11914 9.00586 7.11914C9.48479 7.11914 9.87305 7.50739 9.87305 7.98633V12.6152C9.87305 13.0942 9.48479 13.4824 9.00586 13.4824ZM8.05664 5.4082C8.05664 4.91602 8.48438 4.52344 9.00586 4.51758C9.52148 4.52344 9.94922 4.91602 9.94336 5.4082C9.94922 5.88867 9.52148 6.28711 9.00586 6.28711C8.48438 6.28711 8.05664 5.88867 8.05664 5.4082Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
};

const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Disclaimer = ({
  type,
  message,
  dense,
  style,
  iconStyle,
}: {
  type: DisclaimerType;
  message: string;
  dense?: boolean;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
}) => (
  <StyledDisclaimer type={type} dense={dense || false} style={style}>
    <IconWrapper style={iconStyle}>
      <InfoIcon fillColor={DISCLAIMER_COLORS[type].main} />
    </IconWrapper>
    <div>{message}</div>
  </StyledDisclaimer>
);

export default Disclaimer;
