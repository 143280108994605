import React from 'react';
import styled from 'styled-components';

interface ModalButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  buttonType?: 'confirm' | 'cancel';
}

const StyledModalButton = styled.button<ModalButtonProps>`
  margin: 0;
  padding: 0.688rem 1.5rem;
  font-size: 1rem;
  min-width: 5rem;

  color: ${({ theme, buttonType }) => (buttonType === 'cancel' ? theme.main[600] : '#FFFFFF')};
  background-color: ${({ theme, buttonType }) =>
    buttonType === 'confirm' ? theme.main[600] : '#FFFFFF'};

  /* Button/XL */
  font-family: Pretendard;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */

  border-radius: 0.25rem;
  border: ${({ theme }) => `1px solid ${theme.main[600]}`};
`;

export const ModalButton = styled(StyledModalButton)``;
