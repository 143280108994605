import React from 'react';
import styled from 'styled-components';

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { CheckBox, FlexCenterH, SolidBtn } from '../commonStyle';

import { FilterListType } from './NewTableComponent';

const DropdownContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: rgb(234, 234, 234);
`;

const Dropdown = styled.div`
  position: absolute;
  top: 2rem;
  transform: translateX(0%);
  overflow: hidden;

  z-index: 99999;
  padding: 1rem 1rem;
`;

const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;

  width: 10rem;
  max-height: 15rem;

  overflow-y: auto;

  box-shadow: 0 2px 16px 1px rgba(0, 0, 0, 0.12), 0 7px 10px 1px rgba(0, 0, 0, 0.14),
    0 4px 5px -2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  text-align: left;

  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2b2b2b;
  background-color: #fff;

  @keyframes dropdown {
    from {
      opacity: 0;
      transform: translateY(5px);
    }
    to {
      opacity: 1;
    }
  }
  animation: dropdown 0.4s ease;
`;

const MenuContainer = styled.div``;
const DropdownMenuItem = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-size: 0.875rem;
  &:hover {
    background-color: #f5f5f5;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const DropdownContent = styled.div<{ hilightText: boolean }>`
  display: flex;
  width: 100%;
  height: 3rem;
  padding: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  color: ${({ hilightText }) => (hilightText ? '#6c60f4' : '#555')};

  &:hover {
  }
`;

const TitleContainer = styled.div`
  position: relative;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

type Props = {
  title: string;
  titleWidth?: string;
  filterList: FilterListType[];
  width?: string;
  multiselect?: boolean;
  onClickDropdownItem: (filterValue: FilterListType | FilterListType[]) => void;
  defaultData: any[];
  setCurrentPage: Dispatch<SetStateAction<number>>;
};
const TableFilterDropdown = ({
  defaultData,
  title,
  titleWidth,
  filterList,
  width,
  multiselect,
  onClickDropdownItem,
  setCurrentPage,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<FilterListType>({ id: 'all', value: '전체' });
  const [dropdownTitle, setDropdownTitle] = useState(title);
  const [checklist, setChecklist] = useState<any[]>(filterList);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (filterList) setChecklist(filterList);
  }, [filterList]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const onClickMenuItem = (item: FilterListType) => {
    if (multiselect) return;

    setDropdownTitle(item.id === 'all' ? title : item.value);
    onClickDropdownItem(item);
    setSelected(item);

    setIsOpen(false);
  };

  const checkFilterItem = (item: FilterListType) => {
    if (checklist.some((checkItem) => checkItem.id === item.id)) {
      setChecklist(checklist.filter((checkItem) => checkItem.id !== item.id));
    } else {
      setChecklist([...checklist, item]);
    }
  };

  const applyFilters = () => {
    onClickDropdownItem(checklist);
    setIsOpen(false);
    if (multiselect) {
      if (checklist.length === 0) {
        setDropdownTitle(title);
      } else {
        if (checklist.length === filterList.length) {
          setSelected({ id: 'all', value: '전체' });
          setDropdownTitle(dropdownTitle);
        } else {
          setSelected(checklist[0]);
          if (checklist.length === 1) setDropdownTitle(checklist[0].value);
          else setDropdownTitle(`"${checklist[0].value}" 외 ${checklist.length - 1}개`);
        }
      }
    }
  };

  useEffect(() => {
    console.log('defaultData');

    setDropdownTitle(title);
    setSelected({ id: 'all', value: '전체' });
  }, [defaultData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selected]);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownContent onClick={() => setIsOpen(!isOpen)} hilightText={selected.id !== 'all'}>
        <TitleContainer>
          {dropdownTitle}

          {/* <TooltipText text={dropdownTitle} width={titleWidth} /> */}
        </TitleContainer>
        <button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="feather feather-chevron-down">
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </button>
      </DropdownContent>

      {isOpen && (
        <Dropdown>
          <DropdownMenu
            style={{ overflow: multiselect ? 'hidden' : 'auto', width: width ? width : '10rem' }}>
            <MenuContainer
              style={{
                maxHeight: multiselect ? '15rem' : 'auto',
                overflowX: 'hidden',
              }}>
              <DropdownMenuItem
                key={'all'}
                onClick={() => onClickMenuItem({ id: 'all', value: '전체' })}>
                {multiselect && (
                  <FlexCenterH>
                    <CheckBox
                      checked={checklist.length === filterList.length}
                      onClick={() =>
                        checklist.length === filterList.length
                          ? setChecklist([])
                          : setChecklist(filterList)
                      }
                    />
                  </FlexCenterH>
                )}
                <span>전체</span>
              </DropdownMenuItem>
              {multiselect && (
                <div style={{ padding: '0 8px' }}>
                  <Divider />
                </div>
              )}

              {filterList &&
                filterList.map((item) => (
                  <DropdownMenuItem key={item.id} onClick={() => onClickMenuItem(item)}>
                    {multiselect && (
                      <FlexCenterH>
                        <CheckBox
                          checked={checklist.some((checkItem) => checkItem.id === item.id)}
                          onClick={() => checkFilterItem(item)}
                        />
                      </FlexCenterH>
                    )}

                    <span>{item.value}</span>
                  </DropdownMenuItem>
                ))}
            </MenuContainer>

            {multiselect && (
              <ButtonContainer>
                <SolidBtn
                  style={{
                    width: '3rem',
                    padding: '0.5rem 0.5rem',
                    whiteSpace: 'nowrap',
                    margin: '0.5rem 0',
                  }}
                  onClick={applyFilters}>
                  적용
                </SolidBtn>
              </ButtonContainer>
            )}
          </DropdownMenu>
        </Dropdown>
      )}
    </DropdownContainer>
  );
};

export default TableFilterDropdown;
