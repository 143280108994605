import React from 'react';
import styled from 'styled-components';

const StyledSearchButton = styled.div`
  position: absolute;
  top: 50%;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;

  transform: translateY(-50%);
`;

export const SearchButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <StyledSearchButton onClick={onClick}>
      <i className="fa fa-search" />
    </StyledSearchButton>
  );
};
