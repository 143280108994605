import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../layout/breadcrumb';
import { Container, Row, Col, Card, CardBody, Input } from 'reactstrap';
import Swal from 'sweetalert2';
import { merchantId_api, signUpSite, userList } from '../lib/api';
import styled from 'styled-components';
import { Label1 } from './lockerChange';
import { trigger } from '../components/common';
import PageContainer from '../components/common/PageContainer';

const InputContainer = styled(Label1)`
  display: flex;
  margin-top: 20px;
  h5 {
    display: inherit;
  }
  .btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      padding: 9px 12px;
      width: 60%;
    }

    button {
      width: fit-content;
      padding: 9px 12px;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    .btn-container {
      flex-direction: column;
      width: 100%;
      input {
        width: 100%;
      }
      button {
        width: 100%;
        margin: 10px 0;
      }
    }
    select {
      padding: 9px 5px;
    }
  }
`;

let mid;
const UserEnroll = () => {
  const [enrollData, setEnrollData] = useState({
    phoneNum: '010',
    pinNumber: '123456',
    // birth: '',
    gender: null,
    parentPhoneNum: '',
    merchantId: '',
    mercketingSms: false,
  });
  let regex = /[^0-9]/g;
  //트리거
  const [isTrigger, setIsTrigger] = useState(false);

  useEffect(() => {
    getMerchantId();
  }, []);

  const getMerchantId = async () => {
    const midData = await merchantId_api();

    if (midData && midData.status === 200) {
      mid = midData.data.id;
      setEnrollData({ ...enrollData, merchantId: midData.data.id });
    }
  };

  const handleChange = (e) => {
    setEnrollData({
      ...enrollData,
      [e.target.name]: e.target.value.replace(regex, ''),
    });
  };
  const UserEnrollApi = async () => {
    if (enrollData.phoneNum === '') {
      Swal.fire({
        icon: 'error',
        title: '휴대폰 번호를 입력해주세요',
        timer: 3000,
      });
    } else if (enrollData.phoneNum.length !== 11) {
      Swal.fire({
        icon: 'error',
        title: '휴대폰 번호를 정확히 입력해주세요',
        timer: 3000,
      });
    } else {
      try {
        if (trigger(isTrigger, setIsTrigger)) return;
        const res = await userList(mid, enrollData.phoneNum);

        if (res.status === 200 && res.data.length === 0) {
          const data = await signUpSite(enrollData);
          if (data.status === 200 && data.data === 0) {
            Swal.fire({
              icon: 'success',
              title: '회원 등록이 완료되었습니다.',
              timer: 2000,
            }).then(() => {
              setEnrollData({
                ...enrollData,
                phoneNum: '010',
                pinNumber: '123456',
                // birth: '',
                gender: null,
                parentPhoneNum: '',
                mercketingSms: false,
              });
              setIsTrigger(false);
            });
          } else if (data.status === 200 && data.data === 1) {
            Swal.fire({
              icon: 'error',
              title: '등록할 수 없습니다. 관리자에게 문의바랍니다',
              timer: 2000,
            });
          } else if (data.status === 200 && data.data === 2) {
            Swal.fire({
              icon: 'error',
              title: '등록하신 회원은 다른지점에 있는 회원으로, 회원정보가 업데이트됩니다.',
              timer: 2000,
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: '등록 도중 에러가 발생했습니다. 다시 시도해주세요',
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: '이미 등록되어있는 회원입니다.',
            timer: 2000,
          });
        }
      } catch (err) {
        Swal.fire({
          icon: 'error',
          title: '등록 도중 에러가 발생했습니다. 다시 시도해주세요.',
          timer: 2000,
        });
      }
    }
  };

  return (
    <Fragment>
      <Breadcrumbs parent="회원관리" title="회원 수동 등록" />
      <PageContainer title="회원 수동 등록">
        <InputContainer>
          <h5>전화번호를 입력해주세요. ('-'를 제외하고 입력)</h5>

          <div className="btn-container">
            <Input
              name="phoneNum"
              value={enrollData.phoneNum}
              onChange={handleChange}
              placeholder="-를 제외하고 입력해주세요"
            />
            <button onClick={UserEnrollApi} className="btn btn-primary">
              등록하기
            </button>
          </div>
        </InputContainer>
      </PageContainer>
    </Fragment>
  );
};

export default UserEnroll;
