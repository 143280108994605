import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import check from '../../assets/images/check.svg';
import { discountRate, numberWithCommas } from '../common';
import moment from 'moment';
import { Button } from '../../pages/lockerChange';
import configDB from '../../data/customizer/config';
import { Price } from '../../pages/user/userTicketBuy';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

const Container = styled.div`
  width: 100%;
`;

const PaymentWrapper = styled.div`
  width: 100%;
  background: #f6f9fa;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 15px 0;
  margin-bottom: 6px;
`;
const PaymentCardS = styled.div`
  margin: 0 auto;
  width: 94%;
`;

const PaymentDataS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    > p {
      margin-bottom: 0;
    }
    :first-child {
      width: 50%;
      .description {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: start;
        font-weight: 400;
        font-size: 14px;
        color: #555;
      }
    }
    :last-child {
      margin-top: 20px;
    }
  }
`;

const CheckedBtns = styled.div`
  display: flex;
  align-items: center;
`;

const DatePicker = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
`;

const StartDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin-bottom: 20px;

  p {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    select,
    input:disabled {
      width: 95%;
      padding: 12px;

      color: inherit;
      font-weight: 600;
      font-family: inherit;
    }
    select {
      border: 1px solid ${primary};
      border-radius: 4px;
      appearance: none;
      outline: 0 none;
      overflow-x: auto;

      ::-webkit-scrollbar {
        width: 0.6vw;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #c4cbd6;
        border-radius: 1vw;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 1vw;
      }
    }
  }
`;
export const SelectBox = styled.div`
  width: 100%;
  position: relative;
  select option {
    padding: 4vw 1vw;
  }
  img {
    width: 1vw;
    position: absolute;
    transform: rotate(-90deg);
    top: 44%;
    right: 3.8vw;
  }
`;
const EndDate = styled(StartDate)`
  input {
    width: 32.4% !important;
    :focus {
      outline: none;
    }
    :disabled {
      background-color: #f1f1f1;
      border: 1px solid #cccccc;
    }
  }
`;

const PaymentList = styled.div`
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  width: 100%;
  :not(:last-child) {
    margin-bottom: 2vw;
  }
  > p {
    font-size: 16px;
    text-align: ${(props) => props.arrow};
  }
  .beforeCalc {
    color: #aaa;
    font-size: 12px;
    font-weight: 400;
    text-decoration: line-through;
    margin-bottom: 1vw;
  }
`;
const CheckedBtn = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;
  color: inherit;
  letter-spacing: 0;
  :not(:first-child) {
    margin-left: 15px;
  }
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${(props) => (props.isClick ? primary : '#dedede')};
  }
  p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    margin-left: 15px;
  }
  img {
    width: 12px;
    margin-left: -16px;
  }
`;
const Calendar = ({ selected, onClose, show, setChoiceStateDate }) => {
  const [startType, setStartType] = useState(0);
  const [years, setYears] = useState([]);
  const [days, setDays] = useState([]);
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [startYear, setStartYear] = useState(new Date().getFullYear());
  const [startMonth, setStartMonth] = useState(new Date().getMonth() + 1);
  const [startDay, setStartDay] = useState(new Date().getDate());
  const [endYear, setEndYear] = useState(new Date().getFullYear());
  const [endMonth, setEndMonth] = useState(new Date().getMonth() + 1);
  const [endDay, setEndDay] = useState(new Date().getDate());

  const handleOnchange = ({ target }) => {
    if (target.id === 'year') {
      setStartYear(+target.value);
      setDays([]);
    } else if (target.id === 'month') {
      if (startYear <= new Date().getFullYear() && +target.value < new Date().getMonth() + 1) {
        setStartMonth(new Date().getMonth() + 1);
        setDays([]);
      } else {
        setStartMonth(+target.value);
        setDays([]);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (
        startYear <= new Date().getFullYear() &&
        startMonth <= new Date().getMonth() + 1 &&
        +target.value < new Date().getDate()
      ) {
        setStartDay(new Date().getDate());
      } else {
        setStartDay(+target.value);
      }
    }
  };

  useEffect(() => {
    for (let i = 0; i < 10; i++) {
      setYears((pre) => pre.concat(new Date().getFullYear() + i));
    }
  }, []);

  useEffect(() => {
    const getDays = new Date(+startYear, +startMonth, 0).getDate();
    for (let i = 1; i < getDays + 1; i++) {
      setDays((pre) => pre.concat(i));
    }
  }, [startYear, startMonth]);

  useEffect(() => {
    setEndYear(
      moment(new Date(startYear, startMonth - 1, startDay))
        .add(selected.T_day, 'd')
        .format('YYYY'),
    );
    setEndMonth(
      moment(new Date(startYear, startMonth - 1, startDay))
        .add(selected.T_day, 'd')
        .format('M'),
    );

    setEndDay(
      moment(new Date(startYear, startMonth - 1, startDay))
        .add(selected.T_day, 'd')
        .format('D'),
    );
  }, [startYear, startMonth, startDay]);

  const handlePayment = () => {
    if (startType === 0) {
      setChoiceStateDate(moment().format('YYYY-MM-DD HH:mm:ss'));

      onClose();
    } else {
      // eslint-disable-next-line no-lonely-if
      const exception1 =
        startYear <= new Date().getFullYear() && startMonth <= new Date().getMonth() + 1;

      const exception2 =
        startYear === new Date().getFullYear() &&
        startMonth === new Date().getMonth() + 1 &&
        startDay < new Date().getDate();

      const exception3 =
        startYear === new Date().getFullYear() && startMonth < new Date().getMonth() + 1;

      const exception4 =
        startYear === new Date().getFullYear() &&
        startMonth === new Date().getMonth() + 1 &&
        startDay === new Date().getDate();

      if (exception1 && (exception2 || exception3)) {
        onClose();
      } else if (exception4) {
        setChoiceStateDate(moment().format('YYYY-MM-DD HH:mm:ss'));
        // localStorage.setItem(
        //   "startDate",
        //   moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        // );
        onClose();
      } else {
        setChoiceStateDate(
          moment(new Date(startYear, startMonth - 1, startDay)).format('YYYY-MM-DD HH:mm:ss'),
        );

        // localStorage.setItem(
        //   "startDate",
        //   moment(new Date(startYear, startMonth - 1, startDay)).format(
        //     "YYYY-MM-DD HH:mm:ss"
        //   )
        // );
        onClose();
      }
    }
  };

  return (
    <Modal centered isOpen={show} toggle={onClose}>
      <ModalHeader className="modal-title" toggle={onClose}>
        기간 선택
      </ModalHeader>
      <ModalBody>
        <Container>
          <PaymentWrapper>
            <PaymentCardS>
              <PaymentDataS>
                <div>
                  <PaymentList>
                    <p>
                      {selected?.T_name} / {selected?.T_day}일권
                    </p>
                  </PaymentList>
                  {selected?.T_description && (
                    <PaymentList>
                      <p className="description">{selected.T_description}</p>
                    </PaymentList>
                  )}
                </div>
                <div>
                  <PaymentList arrow="right" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Price discount={selected?.T_discount !== 0}>
                      {selected?.T_discount !== 0 && (
                        <p style={{ marginBottom: 0 }} className="percent">
                          {selected.T_discount}%
                        </p>
                      )}
                      <p style={{ marginBottom: 0 }} className="affterCalc">
                        {numberWithCommas(discountRate(selected.T_price, selected.T_discount))}
                        <span>원</span>
                      </p>
                    </Price>
                    {selected?.T_discount !== 0 && (
                      <p className="beforeCalc">{numberWithCommas(selected.T_price)}원</p>
                    )}
                  </PaymentList>
                </div>
              </PaymentDataS>
              <CheckedBtns>
                <CheckedBtn isClick={startType === 0} onClick={() => setStartType(0)}>
                  <div className="circle" />
                  <img src={check} alt="checkIcon" />
                  <p> 바로 사용</p>
                </CheckedBtn>
                <CheckedBtn
                  isClick={startType === 1}
                  onClick={() => setStartType(1)}
                  disabled={selected.seatType === 1}>
                  <div className="circle" />
                  <img src={check} alt="checkIcon" />
                  <p>기간 직접 입력</p>
                </CheckedBtn>
              </CheckedBtns>
              {selected.seatType === 1 && (
                <p>고정석은 구매 즉시 사용되며 시작 일시를 선택할 수 없습니다.</p>
              )}
              {startType === 1 && (
                <DatePicker>
                  <StartDate>
                    <p>시작일</p>
                    <div>
                      <SelectBox>
                        {/* <img src={goBack} alt="go back" draggable={false} /> */}

                        <select id="year" value={startYear} onChange={handleOnchange}>
                          {years.map((year, index) => (
                            <option key={`Y${index}`}>{year}</option>
                          ))}
                        </select>
                      </SelectBox>
                      <SelectBox>
                        {/* <img src={goBack} alt="go back" draggable={false} /> */}

                        <select id="month" value={startMonth} onChange={handleOnchange}>
                          {months.map((month, index) => (
                            <option key={`M${index}`}>{month}</option>
                          ))}
                        </select>
                      </SelectBox>
                      <SelectBox>
                        {/* <img src={goBack} alt="go back" draggable={false} /> */}

                        <select id="day" value={startDay} onChange={handleOnchange}>
                          {days.map((day, index) => (
                            <option key={`D${index}`}>{day}</option>
                          ))}
                        </select>
                      </SelectBox>
                    </div>
                  </StartDate>
                  <EndDate>
                    <p>종료일</p>
                    <div>
                      <input value={endYear} disabled />
                      <input value={endMonth} disabled />
                      <input value={endDay} disabled />
                    </div>
                  </EndDate>
                </DatePicker>
              )}
            </PaymentCardS>
          </PaymentWrapper>
          <div
            style={{
              display: 'flex',
              width: '100%',
              gap: '10px',
            }}>
            <Button color="#fff" onClick={onClose}>
              취소
            </Button>
            <Button onClick={handlePayment} color={primary}>
              구매하기
            </Button>
          </div>
        </Container>
      </ModalBody>
    </Modal>
  );
};
export default Calendar;
