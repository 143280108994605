import React, { useEffect } from 'react';
import PageContainer from '../../components/common/PageContainer';
import { Button } from '../../components/common/Button';
import { useHistory } from 'react-router-dom';

const Error429 = () => {
  const history = useHistory();

  const onUnblockRequest = () => {
    localStorage.setItem('requestBlocked', '0');
    history.replace('/');
  };

  useEffect(() => {
    localStorage.setItem('requestBlocked', '1');
    return () => {
      localStorage.setItem('requestBlocked', '0');
    };
  }, []);

  return (
    <PageContainer title="429 Too Many Requests">
      단시간에 너무 많은 요청을 시도했습니다. 잠시 기다린 후 아래 버튼을 눌러 다시 서비스를 이용해
      주세요.
      <Button buttonType="action" onClick={onUnblockRequest}>
        홈으로 돌아가기
      </Button>
    </PageContainer>
  );
};

export default Error429;
