import styled from "styled-components";

export const Button = styled.button`
	display: flex;
	height: 2rem;
	padding: 0.4375rem 1rem;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;

	border: none;

	border-radius: 0.25rem;
	color: ${(props) => (props.color ? props.color : "#fff")};
	background: ${(props) => (props.bg ? props.bg : "#999")};
`;

export const RadioBox = styled.input.attrs({ type: "radio" })`
	appearance: none;
	border: max(1px, 0.1em) solid lightgray;
	border-radius: 50%;
	width: 1.5rem;
	height: 1.5rem;
	transition: border 0.3s ease-in-out;
	margin-right: 0.5rem;
	&:disabled {
		background-color: #ccc;
	}
	&:checked {
		border: 0.5em solid #6c60f4;
	}
`;
