import { paymentWithMerchantToken } from '.';

/**
 * KSPAY 결제 전 검증 및 전문 처리를 위한 정보 생성
 * @param ticketItemId
 * @param seminarId
 * @param startDate
 * @param remainTime
 * @param seatId
 * @returns
 */
export const getTicketKspayData = (params: {
  ticketItemId?: number;
  seminarId?: number;
  startDate?: string;
  remainTime?: number;
  seatId?: number;
}) =>
  paymentWithMerchantToken.get(`/ticket/kspay/data`, {
    params,
  });

/**
 * KSPAY 환불
 * @param transactionId
 * @returns
 */
export const postTicketKspayRefund = (transactionId: number) =>
  paymentWithMerchantToken.post(`/ticket/kspay/refund`, { transactionId });
