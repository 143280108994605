import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Input } from 'reactstrap';
import styled from 'styled-components';
import { ToggleContainer } from './setting';
import { ToggleBtn } from './seatList';
import { getPaymentSetting, merchantId_api, updatePaymentSetting } from '../lib/api';
import Swal from 'sweetalert2';
import { trigger } from '../components/common';
import PageContainer from '../components/common/PageContainer';

const PaymentSettingComponent = styled.div`
  .flexTable {
    display: table;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .vanData {
    select {
      width: fit-content;

      border: none;
    }
  }
  p {
    margin-bottom: 0;
  }

  .typeContainer {
    align-items: flex-start;
    width: 100%;
    justify-content: space-around;
    gap: 1rem;
    /* border-spacing: 2vw; */
  }
`;

const PaymentTypeWrapper = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const PaymentTypeContainer = styled.div`
  border: 1px solid #e2e2e2;
  border-radius: 1rem;
  padding: 2rem;
  width: 100%;
  display: table-cell;
  strong {
    color: var(--theme-secondary);
  }
`;

const InputContainer = styled.div``;

const PaymentSetting = () => {
  const [paymentTypeData, setpaymentTypeData] = useState({});
  const [defaultPaymentData, setDefaultPaymentData] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState({});
  const [choiceVan, setChoiceVan] = useState('daouVP');
  const [merchantId, setMerchantId] = useState(0);
  const [isTrigger, setIsTrigger] = useState(false);

  const getPayType = (paymentData, vanData) => {
    // 결제수단 설정 함수
    // 기본적으로 데이터는 불러오도록 되어있으나 혹시 모르니 데이터가 없는경우 처리도 해줄것.
    console.log(paymentData);
    const filterData = paymentData.filter(
      (payment) => payment.van === (vanData === 'daouVP' ? 'daouVp' : vanData),
    );

    console.log(filterData);
    // 출력데이터로 가공
    let newObj = {};
    for (let i = 0; filterData.length > i; i++) {
      newObj = {
        ...newObj,
        [filterData[i].paymentType]: filterData[i].isUsed,
      };
    }
    setpaymentTypeData({ ...newObj });
  };
  const getPaymentData = async (id) => {
    try {
      const payment = await getPaymentSetting(id);
      if (payment.status === 200) {
        setPaymentOptions(payment.data.options);
        setDefaultPaymentData(payment.data.merchantPayment);
        setChoiceVan(payment.data.options.card_type);
        getPayType(payment.data.merchantPayment, payment.data.options.card_type);
      }
    } catch (error) {
      Swal.fire({
        text: '정보를 불러오는데 에러가 발생했습니다. 다시 시도해주세요',
        icon: 'error',
        timer: 2000,
      });
    }
  };

  const handleChange = (checked, target) => {
    setpaymentTypeData({ ...paymentTypeData, [target.name]: checked ? 1 : 0 });
  };

  const handleChangeInput = (target) => {
    setPaymentOptions({ ...paymentOptions, [target.name]: target.value });
  };

  const getMerchantId = async () => {
    const { data } = await merchantId_api();
    if (data) {
      await getPaymentData(data.id);
      setMerchantId(data.id);
    }
    console.log(data);
  };

  const savePaymentType = async () => {
    //예외처리
    // 1. 현금결제가 선택되어있는데, 포트 값이 없을경우
    if (paymentTypeData.cash && !paymentOptions.cash_port) {
      return Swal.fire({
        text: '현금결제를 활성화 하려면 현금 포트 번호를 입력해주세요.',
        icon: 'warning',
        timer: 3500,
      });
    }
    // 2. 지역화폐 결제가 선택되어있는데 포트 값이 없을경우
    if (paymentTypeData.localpay && !paymentOptions.local_tid) {
      return Swal.fire({
        text: '지역화폐결제를 활성화 하려면 지역화폐 tid 번호를 입력해주세요.',
        icon: 'warning',
        timer: 3500,
      });
    }

    const getPayArray = defaultPaymentData.filter(
      (payment) => payment.van === (choiceVan === 'daouVP' ? 'daouVp' : choiceVan),
    );

    const changeArray = getPayArray.map((payment) => ({
      isUsed: paymentTypeData[payment.paymentType],
      paymentTypeVanId: payment.paymentTypeVanId,
    }));

    const sendData = {
      payment: changeArray,
      card_code: paymentOptions?.card_code || '',
      card_code_inner: paymentOptions?.card_code_inner || '',
      card_port: paymentOptions?.card_port || null,
      cash_port: paymentOptions?.cash_port || null,
      local_tid: paymentOptions?.local_tid || '',
      card_type: choiceVan || '',
      merchantId: merchantId,
    };

    try {
      if (trigger(isTrigger, setIsTrigger)) return;
      const { status } = await updatePaymentSetting(sendData);
      console.log(status);
      if (status === 200) {
        Swal.fire({
          text: '결제수단 설정이 완료되었습니다.',
          timer: 3500,
          icon: 'success',
        }).then(() => setIsTrigger(false));
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        text: '결제수단 설정이 실패했습니다. \n 다시 시도해주세요',
        timer: 3500,
        icon: 'error',
      }).then(() => setIsTrigger(false));
    }
  };

  useEffect(() => {
    getMerchantId();
  }, []);

  useEffect(() => {
    if (defaultPaymentData.length > 0) getPayType(defaultPaymentData, choiceVan);
  }, [choiceVan]);

  return (
    <PaymentSettingComponent>
      <PageContainer title="결제 수단 설정">
        <div className="flex vanData">
          <p>현재 결제 수단</p>
          <Input
            className="form-control"
            type="select"
            value={choiceVan}
            onChange={(e) => setChoiceVan(e.target.value)}>
            <option value="daouVP">daouVP</option>
            <option value="KIS">KIS</option>
            <option value="KSNET">KSNET</option>
          </Input>
        </div>
        <PaymentTypeWrapper>
          <PaymentTypeContainer>
            <p>일반결제</p>
            <ToggleContainer>
              <h5>카드 결제</h5>
              <ToggleBtn>
                <Input id="카드결제" name="credit" type="checkbox" checked disabled />
                <span className="onoff-switch"></span>
              </ToggleBtn>
            </ToggleContainer>
            <InputContainer>
              <p>카드 코드번호</p>
              <Input
                name="card_code"
                value={paymentOptions?.card_code || ''}
                onChange={({ target }) => handleChangeInput(target)}
              />
            </InputContainer>
            <InputContainer>
              <p>내부 키오스크 카드 코드번호</p>
              <Input
                name="card_code_inner"
                value={paymentOptions?.card_code_inner || ''}
                onChange={({ target }) => handleChangeInput(target)}
              />
            </InputContainer>
            <InputContainer>
              <p>카드 포트번호</p>
              <Input
                name="card_port"
                value={paymentOptions?.card_port || ''}
                onChange={({ target }) => handleChangeInput(target)}
              />
            </InputContainer>
            <ToggleContainer>
              <h5>현금 결제</h5>
              <ToggleBtn>
                <Input
                  id="현금결제"
                  name="cash"
                  type="checkbox"
                  checked={paymentTypeData.cash}
                  onChange={({ target }) => handleChange(target.checked, target)}
                />
                <span className="onoff-switch"></span>
              </ToggleBtn>
            </ToggleContainer>

            <InputContainer>
              <p>현금 포트번호</p>
              <Input
                disabled={!paymentTypeData.cash}
                name="cash_port"
                value={paymentOptions?.cash_port || ''}
                onChange={({ target }) => handleChangeInput(target)}
              />
            </InputContainer>
          </PaymentTypeContainer>
          <PaymentTypeContainer>
            <p>간편 결제</p>
            <ToggleContainer>
              <h5>페이코 결제</h5>
              <ToggleBtn>
                <Input
                  id="페이코결제"
                  name="payco"
                  type="checkbox"
                  checked={paymentTypeData.payco}
                  onChange={({ target }) => handleChange(target.checked, target)}
                />
                <span className="onoff-switch"></span>
              </ToggleBtn>
            </ToggleContainer>
            <ToggleContainer>
              <h5>카카오페이 결제</h5>
              <ToggleBtn>
                <Input
                  id="카카오페이결제"
                  name="kakaopay"
                  type="checkbox"
                  checked={paymentTypeData.kakaopay}
                  onChange={({ target }) => handleChange(target.checked, target)}
                />
                <span className="onoff-switch"></span>
              </ToggleBtn>
            </ToggleContainer>
            {choiceVan === 'KIS' && (
              <>
                <ToggleContainer>
                  <h5>네이버페이 결제</h5>
                  <ToggleBtn>
                    <Input
                      id="네이버페이결제"
                      name="naverpay"
                      type="checkbox"
                      checked={paymentTypeData.naverpay}
                      onChange={({ target }) => handleChange(target.checked, target)}
                    />
                    <span className="onoff-switch"></span>
                  </ToggleBtn>
                </ToggleContainer>
                <ToggleContainer>
                  <h5>지역화폐 결제</h5>
                  <ToggleBtn>
                    <Input
                      id="지역화폐결제"
                      name="localpay"
                      type="checkbox"
                      checked={paymentTypeData.localpay}
                      onChange={({ target }) => handleChange(target.checked, target)}
                    />
                    <span className="onoff-switch"></span>
                  </ToggleBtn>
                </ToggleContainer>

                <InputContainer>
                  <p>offPG 카드 코드번호(지역화폐)</p>
                  <Input
                    disabled={!paymentTypeData.localpay}
                    name="local_tid"
                    value={paymentOptions?.local_tid || ''}
                    onChange={({ target }) => handleChangeInput(target)}
                  />
                </InputContainer>
                <strong>
                  지역 화폐 결제의 경우, 지역화폐용으로 코드번호를 발급 한 후 설정해주세요
                </strong>
                <ToggleContainer>
                  <h5>애플페이 결제</h5>
                  <ToggleBtn>
                    <Input
                      id="애플페이결제"
                      name="applepay"
                      type="checkbox"
                      checked={paymentTypeData.applepay}
                      onChange={({ target }) => handleChange(target.checked, target)}
                    />
                    <span className="onoff-switch"></span>
                  </ToggleBtn>
                </ToggleContainer>
                <strong>nfc를 지원하는 단말기에서만 결제가 가능합니다.</strong>
              </>
            )}
          </PaymentTypeContainer>
        </PaymentTypeWrapper>
        <div className="buttonContent">
          <Button color="primary" className="mr-1" onClick={() => savePaymentType()}>
            저장하기
          </Button>
        </div>
      </PageContainer>
    </PaymentSettingComponent>
  );
};

export default PaymentSetting;
