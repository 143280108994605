import React from 'react';
import { FlexCenterV } from '../../style/flexStyle';
import Select from './Select';
import SearchBar from './SearchBar';
import { useState } from 'react';

/**
 *
 * @param {*} props
 * @param defaultOption : 기본 옵션( string | undefined )
 * @param selectList : 옵션 리스트( { title:string; placeholder?:string; }[] )
 * @param handleSearchKeyword : 검색어 변경시 실행되는 함수( (string)=>void | undefined )
 * @param onSearch : 검색 버튼 클릭시 실행되는 함수( (string,string)=>void | undefined )
 * @param placeholder : 검색창 placeholder ( string | undefined )
 * @returns
 */
const SelectSearchBar = (props) => {
  const [searchOption, setSearchOption] = useState(
    props.defaultOption ? props.defaultOption : props.selectList[0].title,
  );
  const [placeholder, setPlaceholder] = useState(
    props.placeholder ? props.placeholder : props.selectList[0].placeholder,
  );

  const onChange = (e) => {
    const selectedIdx = e.target.selectedIndex;
    setSearchOption(e.target.value);
    if (props.selectList[selectedIdx].placeholder)
      setPlaceholder(props.selectList[selectedIdx].placeholder);
    if (props.onChange) props.onChange(e.target.value);
  };

  const onSearch = (value) => {
    if (props.onSearch) props.onSearch(value, searchOption);
  };

  return (
    <FlexCenterV gap={1}>
      <Select
        defaultOption={searchOption}
        list={props.selectList.map((item) => item.title)}
        onChange={onChange}
      />
      <SearchBar
        placeholder={placeholder}
        handleSearchKeyword={props.handleSearchKeyword}
        onSearch={onSearch}
      />
    </FlexCenterV>
  );
};

export default SelectSearchBar;
