import styled from 'styled-components';

import IconDropdown from '../../assets/images/icon_dropdown.svg';

export const Dropdown = styled.div`
  display: grid;
  align-items: center;

  h5 {
    /* margin: 0 8px; */
    font-weight: 400;
  }
  > div > div > button {
    width: 100%;
  }
  select {
    height: 2.5rem;
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 0 12px;
    font-size: 14px;

    background-image: url(${IconDropdown});
    background-repeat: no-repeat;

    background-position-y: center;
    background-position-x: right;
    /* margin-left: 10px; */
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    select,
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
`;
