import React, {
  ChangeEventHandler,
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { swalReturnWarning } from '../util/commonFunctions';
import { getTextMessageLength } from '../util/text';
import { Input } from 'reactstrap';

import { Button } from './common/Button';
import MessageWriter from './input/MessageWriter';
import { swalMessage, swalSuccess } from './common';
import MessageViewer from './input/MessageViewer';
import MessageDisclaimerTooltip from './tooltip/MessageDisclaimerTooltip';
import TableController, { TableCol, TableRow } from './common/TableController';
import Disclaimer from './common/Disclaimer';
import Format from '../util/formatter';
import { getMerchantInfo, merchantId_api } from '../lib/api';
import useModal from '../lib/hooks/useModal';
import { useHistory } from 'react-router-dom';
import useHandleAPICall from '../lib/hooks/useHandleAPICall';
import useHandleError from '../lib/hooks/useHandleError';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  itemsRightSideTitle?: JSX.Element[];
}

const PageTitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RightSideTitleArea = styled.div`
  display: flex;
  gap: 1rem;
`;

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h2 {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0;
  }

  @media screen and (max-width: 500px) {
    padding: 1rem;
    border-radius: 0;
  }
`;

export const ContentArea = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  @media screen and (max-width: 1440px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Content = ({ title, itemsRightSideTitle, children }: Props) => {
  return (
    <StyledContent>
      {(title || itemsRightSideTitle) && (
        <PageTitleArea>
          {title && <h2>{title}</h2>}
          {itemsRightSideTitle && <RightSideTitleArea>{itemsRightSideTitle}</RightSideTitleArea>}
        </PageTitleArea>
      )}
      {children}
    </StyledContent>
  );
};

export const MessageSendFormMessageContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
`;

interface MessageSendWriterProps {
  image: string;
  setImage: Dispatch<SetStateAction<string>>;
  messageByte: number;
  setMessageByte: Dispatch<SetStateAction<number>>;
  messageTitle: string;
  setMessageTitle: Dispatch<SetStateAction<string>>;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  files: RefObject<HTMLInputElement>;
  handleFileChange: ChangeEventHandler<HTMLInputElement>;
  messagePrefix?: string;
  messagePostfix?: string;
}

export const MessageSendWriter = ({
  image,
  setImage,
  messageByte,
  setMessageByte,
  messageTitle,
  setMessageTitle,
  message,
  setMessage,
  files,
  handleFileChange,
  messagePrefix,
  messagePostfix,
}: MessageSendWriterProps) => {
  // 메시지 작성시 2000자 넘지 않도록하는 함수
  const setMessageData = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    const changeByte = getTextMessageLength(inputValue);
    const totalByte =
      changeByte +
      getTextMessageLength(messagePrefix || '') +
      getTextMessageLength(messagePostfix || '');

    if (totalByte > 2000) return;

    setMessageByte(totalByte);
    setMessage(inputValue);
  };
  return (
    <MessageSendFormMessageContent>
      <div style={{ fontSize: '1rem' }}>내용 입력</div>
      <div style={{ fontSize: '1rem' }}>미리보기</div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        {(image || messageByte > 90) && (
          <Input
            style={{
              margin: 0,
              padding: '0.75rem',

              fontSize: '0.875rem',
              width: '100%',
            }}
            placeholder="제목을 입력해 주세요. (미입력 시 제목 없이 전송)"
            onChange={(e) => setMessageTitle(e.target.value)}
            value={messageTitle}
          />
        )}

        <MessageWriter
          message={message}
          setMessage={(e) => setMessageData(e)}
          placeholder="문자 내용을 입력해 주세요."
          height={20}
          handleDelete={() =>
            swalMessage('warning', '첨부된 사진을 삭제하시겠습니까?', '삭제', true, () => {
              swalSuccess('사진이 삭제');
              setImage('');
            })
          }
        />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto 2.25rem',
            alignItems: 'center',
            gap: '0.25rem',
          }}>
          <Button
            buttonType="default"
            onClick={() => {
              if (image)
                return swalReturnWarning(
                  '사진 첨부는 1장만 등록 가능합니다. 등록된 사진을 삭제하고 다시 등록해 주세요.',
                );
              files.current?.click();
            }}
            style={{ width: '100%' }}>
            사진 첨부
            <input
              type="file"
              accept="image/jpeg, image/png"
              ref={files}
              onChange={(e) => handleFileChange(e)}
              style={{ display: 'none' }}
            />
          </Button>
          <div style={{ position: 'relative', padding: '0.5rem' }}>
            <MessageDisclaimerTooltip />
          </div>
        </div>
      </div>

      <div
        style={{
          borderRadius: '0.3125rem',
          border: '1px solid lightgray',
          position: 'relative',
          background: '#F8F8F8',
        }}>
        <MessageViewer
          image={image}
          message={`${messagePrefix || ''}${message}${messagePostfix || ''}`}
          height={24}
          handleDelete={() =>
            swalMessage('warning', '첨부된 사진을 삭제하시겠습니까?', '삭제', true, () => {
              swalSuccess('사진이 삭제');
              setImage('');
            })
          }
        />

        <div style={{ position: 'absolute', bottom: '0.75rem', right: '0.75rem' }}>
          <span style={{ color: '#6c60f4' }}>{messageByte}</span> /{' '}
          {image || messageByte > 90 ? 2000 : 90}byte
        </div>
      </div>
    </MessageSendFormMessageContent>
  );
};

export const MessageSenderWriter = ({
  callerIds,
  handleSend,
  messagePrefix,
  messagePostfix,
}: {
  callerIds?: any[];
  handleSend: (
    image: File | null,
    message: string,
    messageTitle: string,
    messagePrefix?: string,
    messagePostfix?: string,
  ) => void;
  messagePrefix?: string;
  messagePostfix?: string;
}) => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();
  const { openAlert } = useModal();
  const history = useHistory();

  const [currentMessagePointBalance, setCurrentMessagePointBalance] = useState(0);

  /**
   * Message writer properties
   */
  const files = useRef<HTMLInputElement>(null);
  const [merchantName, setMerchantName] = useState('');
  const [image, setImage] = useState<string>('');
  const [messageTitle, setMessageTitle] = useState<string>('');
  const [messageByte, setMessageByte] = useState(0);
  const [message, setMessage] = useState('');

  const Prefix = useMemo(() => {
    return `${messagePrefix || ''}[${merchantName}]`;
  }, [messagePrefix, merchantName]);
  const Postfix = useMemo(() => {
    return messagePostfix || '';
  }, [messagePostfix]);
  const MessagePointConsumptionText = useMemo(() => {
    const pointPerMessage = image ? 25 : messageByte > 90 ? 9 : 4;
    return `${
      image ? '그림 문자 (MMS)' : messageByte > 90 ? '장문 문자 (LMS)' : '단문 문자 (SMS)'
    }${callerIds ? ` / ${Format.formatNumber(callerIds.length * pointPerMessage)}P` : ''}`;
  }, [messageByte, image, callerIds]);

  const getMessagePoint = async () => {
    try {
      const { data } = await createRequest(merchantId_api);
      if (data) {
        const res = await createRequest(getMerchantInfo, data.id);
        const currentMerchantData = res.data[0];
        if (res.status === 200) {
          setMerchantName(currentMerchantData.name);
          setCurrentMessagePointBalance(
            currentMerchantData?.maxSendMessage - currentMerchantData?.sendMessageUseCount,
          );
        }
      }
    } catch (error) {
      catchError(error);
    }
  };

  const handleClickMessagePointCharge = () => {
    swalMessage(
      'warning',
      '작성을 중단하고 메시지 포인트 충전으로 이동하시겠습니까?\n이동 시 작성 중인 내용은 손실됩니다.',
      '이동',
      true,
      () => history.push('/message/charge'),
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files as FileList;
    if (!files) return;
    const fileList = Array.from(files);
    if (fileList.length > 1) return openAlert('이미지는 1장만 업로드 가능합니다.');
    const filteredFileList = fileList.filter((file) => file.size <= 900 * 1024);
    if (filteredFileList.length === 0)
      return openAlert(
        '개별 이미지 용량이 900KB를 초과합니다.\n용량을 확인하신 후 다시 시도해 주세요.',
      );
    if (filteredFileList.length !== fileList.length)
      openAlert('용량 900KB를 초과하는 이미지는 제외되었습니다.');
    const reader = new FileReader();
    reader.readAsDataURL(filteredFileList[0]);
    reader.onloadend = (e) => {
      const src = e.target?.result;
      setImage(src as string);
    };
  };

  useEffect(() => {
    getMessagePoint();
  }, []);

  return (
    <Content
      title="메시지 전송"
      itemsRightSideTitle={[
        <Button
          buttonType="action"
          disabled={callerIds && callerIds.length === 0}
          onClick={() =>
            handleSend(
              files.current && files.current.files ? files.current.files[0] : null,
              message,
              messageTitle,
              Prefix,
              Postfix,
            )
          }>
          전송하기
        </Button>,
      ]}>
      <TableController>
        <TableRow isStart>
          <TableCol title="전송 상태">
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                gap: '0.5rem',
                width: '100%',
                alignItems: 'center',
              }}>
              <span style={{ fontWeight: '300' }}>{MessagePointConsumptionText}</span>
              <Disclaimer message="90byte가 넘으면 장문 문자로 변경돼요." dense type="misc" />
            </div>
          </TableCol>
        </TableRow>
      </TableController>
      <MessageSendWriter
        image={image}
        setImage={setImage}
        messageByte={messageByte}
        setMessageByte={setMessageByte}
        messageTitle={messageTitle}
        setMessageTitle={setMessageTitle}
        message={message}
        setMessage={setMessage}
        files={files}
        handleFileChange={handleFileChange}
        messagePrefix={`[Web발신]${Prefix}`}
        messagePostfix={Postfix}
      />
      <TableController>
        <TableRow isStart>
          <TableCol title="보유 포인트">
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 8rem',
                gap: '0.5rem',
                width: '100%',
                alignItems: 'center',
              }}>
              <span style={{ fontWeight: '300' }}>
                {Format.formatNumber(currentMessagePointBalance)}P
              </span>
              <Button buttonType="default" onClick={handleClickMessagePointCharge}>
                충전
              </Button>
            </div>
          </TableCol>
        </TableRow>
      </TableController>
      <Disclaimer
        message="단문 문자 (SMS) 4P | 장문 문자(LMS) 9P | 그림 문자(MMS) 25P"
        dense
        type="misc"
      />
    </Content>
  );
};
