/**
 * Class for formatting values.
 * @class Formatter
 */
class Formatter {
  /**
   * 기본 숫자 포맷팅
   * @param number
   * @returns
   */
  formatNumber(num: string | number): string {
    return formatWithFallback(num, (value) =>
      !Number.isNaN(value) ? Number(value).toLocaleString() : '-',
    );
  }

  /**
   * 날짜 포맷팅
   * @param date
   * @returns
   */
  formatDate(date: Date): string {
    return formatWithFallback(date, (value) => {
      const date = new Date(value);
      return `${date.getFullYear()}-${padDate(date.getMonth() + 1)}-${padDate(date.getDate())}`;
    });
  }

  /**
   * 날짜 및 시간 포맷팅
   * @param date
   * @returns
   */
  formatDateTime(date: string): string {
    return formatWithFallback(date, (value) => {
      const date = new Date(value);
      if (Number.isNaN(date.getTime())) throw new Error('Invalid date');
      return `${date.getFullYear()}-${padDate(date.getMonth() + 1)}-${padDate(
        date.getDate(),
      )} ${padDate(date.getHours())}:${padDate(date.getMinutes())}:${padDate(date.getSeconds())}`;
    });
  }

  /**
   * 타이머 포맷팅 (MM:ss)
   * @param num
   * @returns
   */
  formatSecondsToMMss(num?: number): string {
    return formatWithFallback(num, secondsToMMss);
  }

  /**
   * 퍼센테이지 포맷팅
   * @param num
   * @returns
   */
  formatPercentage(num: string | number): string {
    return formatWithFallback(num, (value) => this.formatNumber(value) + '%');
  }

  /**
   * 포인트 정보 포맷팅
   * @param num
   * @returns
   */
  formatPoint(num: string | number): string {
    return formatWithFallback(num, (value) => this.formatNumber(value) + 'pt');
  }

  /**
   * 전화번호 포맷팅
   * @param phone
   * @returns
   */
  formatPhone(phone: string): string {
    return formatWithFallback(phone, addHyphenToPhoneNumber);
  }

  /**
   * 사업자 등록번호 포맷팅
   * @param registrationNumber
   * @returns
   */
  formatRegistrationNumber(registrationNumber: string): string {
    return formatWithFallback(registrationNumber, addHyphenToRegistrationNumber);
  }
}

const Format = new Formatter();
export default Format;

/**
 * Format a value with a fallback value.
 * @param value value to format
 * @param formatFunction function for formatting
 * @returns formatted value or fallback value (on error)
 */
const formatWithFallback = (value: any, formatFunction: (value: any) => string) => {
  try {
    return formatFunction(value);
  } catch (error) {
    return '-';
  }
};

/**
 * 전화번호에 하이픈 붙이기 (지역번호 없는 전화번호도 포함)
 * @author @jhlov https://github.com/jhlov
 * @param value 전화번호
 * @returns 하이픈이 붙은 전화번호
 */
const addHyphenToPhoneNumber = (value: string) => {
  if (!value) {
    return '';
  }

  value = value.replace(/[^0-9]/g, '');

  let result = [];
  let restNumber = '';

  // 지역번호와 나머지 번호로 나누기
  if (value.startsWith('02')) {
    // 서울 02 지역번호
    result.push(value.substr(0, 2));
    restNumber = value.substring(2);
  } else if (value.startsWith('1')) {
    // 지역 번호가 없는 경우
    // 1xxx-yyyy
    restNumber = value;
  } else {
    // 나머지 3자리 지역번호
    // 0xx-yyyy-zzzz
    result.push(value.substr(0, 3));
    restNumber = value.substring(3);
  }

  if (restNumber.length === 7) {
    // 7자리만 남았을 때는 xxx-yyyy
    result.push(restNumber.substring(0, 3));
    result.push(restNumber.substring(3));
  } else {
    result.push(restNumber.substring(0, 4));
    result.push(restNumber.substring(4));
  }

  return result.filter((val) => val).join('-');
};

/**
 * 사업자 등록번호에 하이픈 추가
 * @param value 사업자 등록번호
 * @returns 하이픈이 붙은 사업자 등록번호
 */
const addHyphenToRegistrationNumber = (value: string) =>
  `${value.substring(0, 3)}-${value.substring(3, 5)}-${value.substring(5)}`;

const padDate = (value: number) => value.toString().padStart(2, '0');

const secondsToMMss = (counter: number) => {
  return `${Math.floor((counter || 0) / 60)
    .toString()
    .padStart(2, '0')}:${((counter || 0) % 60).toString().padStart(2, '0')}`;
};
