import React, { Fragment, useState, useEffect, useLayoutEffect, useMemo, useCallback } from 'react';

import { openDoor } from '../../lib/socket';
import styled, { css } from 'styled-components';

import OpenDoorModal from '../../components/modal/openDoorModal';
import Arrow from '../../assets/images/dashboard/arrow.svg';
import List from '../../assets/images/dashboard/list.svg';
import LogOut from '../../assets/images/dashboard/logout.svg';
import OpenDoor from '../../assets/images/dashboard/opendoor.svg';
import Menu from '../../assets/images/menuBlack.svg';
import LogoLetmeup from '../../assets/images/Logo_letmeupBlack.svg';
import User from '../../assets/images/UserCircle.svg';
import { deleteLogout } from '../../lib/api/v2/auth';
import { getAllToken, removeToken } from '../../util/token';
import { useRecoilValue } from 'recoil';
import { merchantDataState, userPhoneNumberState } from '../../state';

// setTranslations({ en, es, pt, fr, du, cn, ae });
// setDefaultLanguage('en');
// setLanguageCookie();

const Divider = styled.div`
  width: 90%;
  background: ${({ theme }) => theme.neutralGray[200]};
  height: 1px;
`;

const ToggleMenu = styled.div`
  position: fixed;

  top: 3.5rem;
  right: 1rem;

  display: flex;
  flex-direction: column;

  width: 14rem;

  border-radius: 1rem;
  overflow: hidden;
  z-index: 450;
  background: white;
  box-shadow: 0px 0px 24px 0px #00000029;

  transition: opacity 0.15s ease-in-out, right 0.2s ease-in-out;

  .list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
  }

  ${({ enabled }) =>
    enabled
      ? css``
      : css`
          z-index: -1;
          right: -16rem;
          opacity: 0;
        `}
`;

const MenuListItem = styled.div`
  display: grid;
  grid-template-columns: 2rem auto;
  align-items: center;
  padding: 0.75rem;
  background: white;
  color: ${({ theme }) => theme.neutralGray[500]};
  font-size: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  font-weight: 600;

  &:hover {
    filter: brightness(95%);
  }
`;

const StyledMenuHandler = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  padding: 0.5rem;
  gap: 0.5rem;

  .merchant-name {
    font-size: 1rem;
    font-weight: 600;
  }

  &:hover {
    backdrop-filter: brightness(95%);
  }
`;

const ResponseMerchantName = styled.div`
  color: #2b2b2b;
  font-size: 16px;
  font-weight: 600;
  @media screen and (max-width: 1279px) {
    padding: 1rem;
    background-color: #f1f3f6;
    /* border-top-left-radius: 4.44vw;
    border-top-right-radius: 4.44vw; */
    display: flex;
    color: #686c71;
    word-break: keep-all;
    > div {
      &:first-child {
        margin-right: 0.5rem;
      }
    }
  }
`;

const Container = styled.div`
  .nav-right {
    margin-right: 0 !important;
  }
  .nav-menus {
    margin-right: 0 !important;
    .media-body {
      padding: 0.5rem;
      &:hover {
        background-color: #f1f3f6;
        border-radius: 0.41vw;
      }
    }
  }
  .profile-media img {
    width: 0.52vw !important;
    height: 0.31vw !important;
    margin-left: 0.2vw;
  }
  .profile-dropdown {
    width: 14rem;
    /* border-radius: 0.83vw !important; */
    border-radius: 1rem;
    padding: 0.41vw 0.41vw !important;
    box-shadow: 0px 0px 24px 0px #00000029;
    right: 1rem;
    /* top: 2.9vw !important; */
    /* right: 0 !important; */
    /* left: 1.53vw !important; */
    li {
      justify-content: flex-start;
      padding: 0.62vw 1.45vw;
      img {
        width: 0.93vw;
        margin-right: 0.41vw;
      }
      span {
        font-size: 0.93vw;
        font-weight: 600;
        color: #686c71 !important;
      }
      &:first-child {
        border-top: none !important;
      }
      &:hover {
        background-color: #f1f3f6;
        border-radius: 0.41vw;
      }
    }
    hr {
      margin: 0;
      width: 80%;
      border-top: 1px solid #dde4eb;
      margin-left: 10%;
    }
  }
  @media screen and (max-width: 805px) {
    .profile-dropdown {
      position: fixed;
      border-radius: 4.44vw !important;
      padding: 0 !important;

      right: 1rem;
      /* left: 36.5vw !important; */
      /* top: 15.55vw !important; */
      li {
        padding: 4.72vw 8.88vw !important;

        img {
          width: 5vw;
          margin-right: 2.22vw;
        }
        span {
          font-size: 5vw;
        }
        &:nth-child(2) {
          border-top: none !important;
        }
        &:hover {
          background-color: #ffffff;
          border-radius: 0.41vw;
        }
      }
      hr {
        margin-left: 6.66vw;
      }
    }
  }
`;

const MobileHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .showMenu {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
    /* margin-right: 5vw; */
  }
  .logo {
    height: 1.5rem;
  }
  .user {
    width: 1.75rem;
  }
`;

const Rightbar = (props) => {
  // 기존 언어관련
  // const [searchresponsive, setSearchresponsive] = useState(false);
  // const [langdropdown, setLangdropdown] = useState(false);
  // const [selected, setSelected] = useState("en");
  // const [cartDropdown, setCartDropDown] = useState(false);
  // const [notificationDropDown, setNotificationDropDown] = useState(false);
  // const [chatDropDown, setChatDropDown] = useState(false);

  const merchantData = useRecoilValue(merchantDataState);

  const [toggleMenuEnabled, setToggleMenuEnabled] = useState(false);
  const userPhoneNumber = useRecoilValue(userPhoneNumberState);
  const [moonlight, setMoonlight] = useState(false);
  const [isDoorModal, setIsDoorModal] = useState(false);
  const [name, setName] = useState('');
  const [merchantCode, setMerchantCode] = useState('');

  const { screenType } = props;

  const { adminToken, userToken, merchantToken } = getAllToken();

  useEffect(() => {
    if (localStorage.getItem('layout_version') === 'dark-only') {
      setMoonlight(true);
    }
  }, []);

  const handleLogout = async () => {
    if (props) {
      await deleteLogout();
      // localStorage.clear();
    }
  };

  const handleClick = useCallback(
    () => setToggleMenuEnabled(!toggleMenuEnabled),
    [toggleMenuEnabled],
  );

  const goToMerchantList = () => {
    removeToken(['merchantToken', 'merchantRefreshToken']);
    if (UserType.merchantUserV2) {
      localStorage.setItem('level', 'user');
      window.location.href = `${process.env.PUBLIC_URL}/select-merchant`;
      return;
    }
    localStorage.setItem('level', 'admin');
    window.location.href = `${process.env.PUBLIC_URL}/merchant-list`;
  };

  const UserType = useMemo(() => {
    return {
      adminMerchant: !!merchantToken && !!adminToken,
      merchantUserV1: !!merchantToken && !userToken && !!userPhoneNumber,
      merchantUserV2: !!merchantToken && !!userToken,
      userV2: !!userToken && !merchantToken,
      merchant: !!merchantToken && !userToken,
    };
  }, [userToken, merchantToken, adminToken, userPhoneNumber]);

  const MenuHandler = useMemo(() => {
    // return (
    //   <div className="media profile-media" style={{ alignItems: 'center', width: 'max-content' }}>
    //     <div>
    //       <div style={{ display: 'flex' }} className="media-body" onClick={() => handleClick()}>
    //         <span>
    //           {name ? name : ''}{' '}
    //           {merchantCode && (
    //             <>
    //               <small>({merchantCode})</small>
    //             </>
    //           )}
    //         </span>
    //         <span>
    //           <img src={Arrow} alt="더보기" />
    //           {/* <i style={{ marginLeft: "15px" }} className="middle fa fa-angle-down"></i> */}
    //         </span>
    //       </div>
    //     </div>
    //   </div>
    // );
    return (
      <StyledMenuHandler onClick={handleClick}>
        <span className="merchant-name">{name || ''}</span>
        <span>{merchantCode && <>({merchantCode})</>}</span>
        <span>
          <img src={Arrow} alt="더보기" />
        </span>
      </StyledMenuHandler>
    );
  }, [handleClick, name, merchantCode]);

  const moveToDashBoard = () => {
    if (UserType.merchant)
      return (window.location.href = `${process.env.PUBLIC_URL}/admin/dashboard`);
    if (UserType.merchantUserV1 || UserType.merchantUserV2)
      return (window.location.href = `${process.env.PUBLIC_URL}/dashboard`);
    if (UserType.userV2)
      return (window.location.href = `${process.env.PUBLIC_URL}/select-merchant`);
  };

  useEffect(() => {
    if (!props.data) return;
    if (UserType.merchantUserV1 || UserType.merchantUserV2) {
      if (!props?.data.userName) {
        setName(`${props.data.merchantName} - ${props.data.phoneNum?.slice(7, 11)} 님` || '');
      } else {
        setName(`${props.data.merchantName} - ${props.data.userName} 님` || '');
      }
      return;
    }
    if (UserType.userV2) return setName(`${props.data.phoneNum?.slice(7, 11)} 님` || '');
    if (UserType.merchant) {
      setName(props?.data?.merchantName || props.data.name);
      setMerchantCode(props.data.phoneNum || props.data.merchant_code);
      return;
    }
  }, [props]);

  return (
    <Fragment>
      {isDoorModal && props && merchantData?.multiKioskFloor && (
        <OpenDoorModal
          show={isDoorModal}
          onClose={setIsDoorModal}
          data={merchantData?.multiKioskFloor}
          id={props.mid}
        />
      )}
      {screenType !== 'desktop' && (
        <MobileHeader>
          <div>
            <img
              className="showMenu"
              onClick={() => props.setIsShowSide(true)}
              src={Menu}
              alt="menu"
            />
            <img className="logo" src={LogoLetmeup} alt="LogoLetmeup" onClick={moveToDashBoard} />
          </div>
          {screenType === 'mobile' && (
            <div>
              <img className="user" onClick={() => handleClick()} src={User} alt="user" />
            </div>
          )}
          {screenType === 'tablet' && MenuHandler}
        </MobileHeader>
      )}

      <Container>
        {screenType === 'desktop' && MenuHandler}
        <div className="nav-right col-8 pull-right right-header p-0">
          <ul className="nav-menus">
            <li className="profile-nav onhover-dropdown p-0">
              <ToggleMenu enabled={toggleMenuEnabled}>
                {screenType !== 'desktop' && (
                  <ResponseMerchantName>
                    <div>
                      <img className="user" src={User} alt="user" />
                    </div>{' '}
                    <div>
                      {name ? name : ''}
                      {merchantCode && (
                        <>
                          <small>({merchantCode})</small>
                        </>
                      )}
                    </div>
                  </ResponseMerchantName>
                )}
                <div className="list-wrapper">
                  {UserType.merchant &&
                    !(UserType.merchantUserV1 || UserType.merchantUserV2) &&
                    (props && !merchantData?.multiKioskFloor ? (
                      <MenuListItem onClick={() => openDoor(props?.mid)}>
                        <img src={OpenDoor} alt="OpenDoor" />
                        <span>문 열기</span>
                      </MenuListItem>
                    ) : (
                      <MenuListItem onClick={() => setIsDoorModal(true)}>
                        <img src={OpenDoor} alt="OpenDoor" />
                        <span>멀티 문열기</span>
                      </MenuListItem>
                    ))}
                  <Divider />
                  <MenuListItem onClick={handleLogout}>
                    <img src={LogOut} alt="logout" />
                    <span>로그아웃</span>
                  </MenuListItem>
                  {(UserType.merchantUserV2 || UserType.adminMerchant) && (
                    <>
                      <Divider />
                      <MenuListItem onClick={goToMerchantList}>
                        <img src={List} alt="list" />
                        <span>지점 리스트</span>
                      </MenuListItem>
                    </>
                  )}
                </div>
              </ToggleMenu>
              {/* <ul className="profile-dropdown onhover-show-div">
                {isMobile && (
                  <ResponseMerchantName>
                    <div>
                      <img className="user" src={User} alt="user" />
                    </div>{' '}
                    <div>
                      {name ? name : ''}
                      {merchantCode && (
                        <>
                          <small>({merchantCode})</small>
                        </>
                      )}
                    </div>
                  </ResponseMerchantName>
                )}
                {UserType.merchant &&
                  !(UserType.merchantUserV1 || UserType.merchantUserV2) &&
                  (props && !props.data.multiKioskFloor ? (
                    <li onClick={() => openDoor(props?.mid)}>
                      <img src={OpenDoor} alt="OpenDoor" />
                      <span>문 열기</span>
                    </li>
                  ) : (
                    <li onClick={() => setIsDoorModal(true)}>
                      <img src={OpenDoor} alt="OpenDoor" />
                      <span>멀티 문열기</span>
                    </li>
                  ))}
                <hr />
                <li onClick={handleLogout}>
                  <img src={LogOut} alt="logout" />
                  <span>로그아웃</span>
                </li>
                <hr />
                {(UserType.merchantUserV2 || UserType.adminMerchant) && (
                  <li onClick={goToMerchantList}>
                    <img src={List} alt="list" />
                    <span>지점 리스트</span>
                  </li>
                )}
              </ul> */}
            </li>
          </ul>
        </div>
      </Container>
    </Fragment>
  );
};
export default Rightbar;
