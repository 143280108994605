import React, { ChangeEvent, Dispatch, FormEvent, SetStateAction } from 'react';
import { Form, Input } from 'reactstrap';
import styled from 'styled-components';
import { SearchButton } from '../input';
import { LabelRow } from '../../pages/ticketCustom';

interface SearchProps {
  onSubmit: () => void;
  placeholder?: string;
  searchKeyword: string;
  setSearchKeyword: (val: string) => void | Dispatch<SetStateAction<string>>;
  style?: React.CSSProperties;
}

const Search = ({ onSubmit, placeholder, searchKeyword, setSearchKeyword, style }: SearchProps) => {
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <InputWrapper onSubmit={handleSubmit} style={style}>
      <LabelRow>
        <SearchInput
          placeholder={placeholder || '검색어를 입력해주세요'}
          className="form-control"
          value={searchKeyword || ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchKeyword(e.target.value)}
        />
        <SearchButton onClick={onSubmit} />
      </LabelRow>
    </InputWrapper>
  );
};

export default Search;

const InputWrapper = styled(Form)`
  > label {
    margin: 0;
  }
  input {
    padding: 9px 12px;
    font-size: 1rem;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    > label {
      width: 100%;
    }
  }
`;

const SearchInput = styled(Input)``;
