import styled from "styled-components";

export const Text = styled.span`
	color: ${(props) => (props.color ? props.color : "#111")};

	/* Body/XL */
	font-family: Pretendard;
	font-size: ${(props) => (props.fontSize ? `${props.fontSize}rem` : "1rem")};
	font-style: normal;
	font-weight: ${(props) => (props.weight ? props.weight : "500")};
	line-height: 150%; /* 1.5rem */
`;
