import React from 'react';

const UseRule = () => {
  return (
    <p>
      {`     *본 매장은 24시간 무인시스템으로 운영되며 직원이 상주하지 않습니다.
*회원 등록 • 결제는 오프라인 매장의 키오스크를 통해 진행되며 온라인서비스를 제공받기 위해서는 오프라인 키오스에서 등록한 휴대폰 번호와 동일한 번호로 온라인 회원가입을 해야하며, 회원탈퇴는 온라인 서비스에서 진행할 수 있습니다.

1. 등록 및 이용

1-1. 명시된 이용 수칙을 준수하셔야만 등록 및 이용 가능합니다.
1-2. 수능 및 고시 준비 회원을 중점으로 서비스를 제공하기 때문에 운영중인 매장이 지정한 고객 부터 등록 및 이용이 가능합니다.

2. 입출입
2-1. 입•퇴실 시, 지정한 방법으로 로그인 하고 이용권을 선택후 입장하여야 합니다.
2-2. 키오스크를 통해 좌석을 선택하고 착석해야 합니다.
2-3. 외출 시/ 퇴장 시 키오스크에서 해당매뉴를 선책 후 퇴실해야 합니다.
2-4. 입출입 수칙 위반 시, 누적 학습 시간에 중대한 오류가 발생할 수 있습니다.

3. 취소 및 환불
3-1. 학원법 제 18조 3항에 의거하여 환불 금액이 계산 됩니다. *환불 신청일 기준
3-2. 28일 기준, 등록 후 1/2 경과 시점인 15일 후에는 환불이 불가 합니다. 다만, 이용하는 지점에 게시된 내용을 우선으로 합니다.
3-3. 이용 기간 시작 전 환불은 해당 지점에 기록이 가능한 전달매체 (메시지,카카오플러스 친구를 통해 요구할 수 있습니다.

4. 휴회
4-1. 사용 중 정지는 1회 가능 합니다. (단, 최소 단위 7일 부터 가능, 프로모션 으로 가입시 불가, 28일권 이상) 다만, 이용하는 지점에 게시된 내용을 우선으로 합니다.
4-2. 기간권 요금제 회원만 휴회가 가능합니다.

5. 휴일
5-1. 공휴일 및 명절에도 정상운영이 되지만 천재지변 및 보수공사 발생시 휴일이 발생할 수 있습니다.
5-2. 매장 보수공사 발생시 이에 대한 별도의 보상은 진행되지 않습니다.
5-3. 천재지변 및 불가피한 외부환경으로 운영이 불가할시 이에 대한 별도의 보상은 진행되지 않습니다.

6. 건물공사 및 기타 소음
6-1. 당사의 의사와 관계 없는 같은 건물 내 공사로 인한 소음은 별도 보상이 진행되지 않습니다.
6-2. 스터디카페 인근에서 발생하는 소음 (공사, 선거유세 등)은 별도 보상이 진행되지 않습니다.

7. 사물함 이용 및 물품보관
7-1. 사물함 처음 사용시 개인 비밀번호를 반드시 설정해서 이용해야 합니다..
7-2. 기간이 만료되기전 사물함의 사용기간을 연장 및 내부 물건을 정리해야 합니다.

8. 회원권 정지
아래와 같은 상황 발생 시, 이용 기간 조기 종료 가능
8-1 이용 수칙에 대한 근무자의 정당한 지시 불이행
8-2 서비스 제공자에게 폭언, 욕설, 비하의 표현을 통해 매장 운영을 어렵게 할 시
8-3 기물 파손 및 절도
8-4 음주, 흡연, 불건전 매체 포함 풍기 문란 행위
8-5 학습공간 내 외부 공통 수칙 위반 시
- 심각한 면학 분위기 훼손 / 시설물 오염 및 훼손 / 학습공간내 냄새가 우려되는 음식 취식
- 본사 비상응대 전화 긴급상황을 제외한 전화 통화 / 폭력과 욕설을 동반한 타 회원과의 마찰 / 각 공간 별 이용수칙 위반 등

8-6 부정사용 시
- 본인 이외에 이용권을 타인에게 대여 / 부정한 방법으로 게이트 입출입 / 세미나룸 무단이용
* 좌석 대여 관련 부정 이용 시, 이용 요금 10배 청구
렛미업 스터디카페 프로그램이 제공해드리는 공간 및 서비스를 더욱 편하고 쾌적하게 이용하시기 위해 이용수칙을 준수해주시기 바랍니다.

1. **사물함 이용. 물품보관 수칙**

본 규정은 렛미업 스터디카페 키오스크에 설치된 사물함 이용 및 관리에 따른 제반 규정을 정함에 그 목적이 있다.
제2조

이용

자격은 렛미업 스터디카페 키오스크에 가입된 회원이다.

제3조
이용기간
사물함 이용은 키오스크에서 원하는 날짜와 기간을 선택할 수 있다.

제4조
신청방법
신청방법은 다음 각호와 같다.
1) 키오스크에서 사물함 현황에 따라 본인이 원하는 빈사물함을 선택한다.
2) 사물함의 변경이나 추가 사용의 경우 지점 상황에 따라 변경, 추가(유료) 가능하다.
3) 사물함 비밀번호 경우, 사물함에 부착된 비밀번호 변경 방법 안내를 참고하여 직접 변경 가능하다.

제5조
이용자 의무
이용자는 다음 각 사항을 준수한다.
1) 사물함은 타인에게 대여하거나 양도할 수 없다.
2) 사물함은 이용 기간 만료 시, 사물함 이용 수칙에 의거하여 처리된다.
3) 발급 받은 사물함이 파손되어 있을 경우 관리자에게 신고한다.
4) 이용 중, 사물함 파손 시 회원에게 배상의 책임이 발생한다.

제6조
보관금지물품
다음 각 호에 해당하는 물품은 사물함에 보관 불가하다.
1) 음란물과 불온 서적
2) 청소년 소지 부적합 물품 (술, 담배 등)
3) 그 외 면학분위기에 부적합 하다고 판단되는 물품
4) 음식물 등 냄새 유발 제품

제7조

현금•귀중품 보관
렛미업 스터디카페 키오스크 운영지점은 귀중품 및 개인물품 분실 시, 상법 135조 고가물에 대한 책임에 근거하여 책임지지 않습니다.
1) 고가 물품 및 귀중품을 포함한 개인 물품은 모두 개인 사물함에 보관해야 한다.
2) 오픈 된 공간에 둔 개인 물품이 분실되었을 경우 책임지지 않는다.

제8조
보관물 처리
보관물 처리는 다음 각호와 같다.
1) 사물함 만료시 사물함내 물건은 반출하여 지점 내 특정 장소(창고 등)에 보관한다. *단, 우산 및 음식물은 당일 폐기
2) 사물함 물건 반출 후, 8일 동안 관리자에게 문의후 수령 가능하며 찾아가지 않을 시, 임의 처분한다

제9조
유실물 처리
*유실물 : 어떤 회원의 물품인지 명확히 파악하기 어려운 물품
1) 유실물은 습득 시점부터 8일 동안 보관되며 찾아가지 않을 경우, 자체 처분 된다.
* 17시 이후부터 수령 가능
* 우산 및 음식물 당일 폐기
    `}
    </p>
  );
};

export default UseRule;
