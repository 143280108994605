import React from 'react';
import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

interface IProps {
  message: string;
  placeholder?: string;
  height?: number;
  withoutBorder?: boolean;
  setMessage: React.ChangeEventHandler<HTMLTextAreaElement>;
  handleDelete?: () => void;
}

const Content = styled.div<{ height?: number; withoutBorder?: boolean }>`
  ${({ withoutBorder }) =>
    !withoutBorder &&
    css`
      border: 1px solid lightgray;
    `}

  border-radius: 0.3125rem;

  height: ${({ height }) => (height ? `${height}rem ` : '14.688rem')};
  overflow-y: scroll;

  textarea {
    resize: none;
    padding: 1rem;
    font-size: 1rem;
    font-family: Pretendard;
    border: none;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
`;

const MessageWriter = ({
  message,
  placeholder,
  height,
  withoutBorder,
  setMessage,
  handleDelete,
}: IProps) => {
  return (
    <Content height={height} withoutBorder={withoutBorder}>
      <TextareaAutosize placeholder={placeholder} value={message} onChange={setMessage} />
    </Content>
  );
};

export default MessageWriter;
