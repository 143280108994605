import React, { Fragment, HTMLAttributes, useEffect, useState } from 'react';

import { getMerchant } from '../../lib/api/v2/user';
import styled from 'styled-components';

import IconMerchant from '../../assets/images/icon_merchant.svg';
import ArrowRight from '../../assets/images/icon_arrow_right_darkgray.svg';
import { maskPhoneNumber, swalReturnWarning } from '../../util/commonFunctions';
import { postUserMerchant } from '../../lib/api/v2/auth';
import { useRecoilState } from 'recoil';
import { userPhoneNumberState } from '../../state';
import Format from '../../util/formatter';

interface MerchantListItemProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
}

const SelectMerchant = () => {
  const [userPhoneNumber] = useRecoilState(userPhoneNumberState);
  const [merchantList, setMerchantList] = useState<{ merchantId: number; merchantName: string }[]>(
    [],
  );
  const fetch = async () => {
    try {
      const { data } = await getMerchant();
      setMerchantList(data);
    } catch (error) {
      swalReturnWarning(
        '사용 가능한 지점 목록을 가져오는 중 오류가 발생했습니다.\n잠시 후 다시 시도해 주세요.',
      );
    }
  };

  const onClickMerchant = async (merchantId: number) => {
    try {
      await postUserMerchant(merchantId);
    } catch (error) {
      swalReturnWarning('지점으로 로그인 중 오류가 발생했습니다.\n잠시 후 다시 시도해 주세요.');
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Fragment>
      <Container>
        <ContentArea>
          <h2>
            <strong>{maskPhoneNumber(Format.formatPhone(userPhoneNumber))}님,</strong>
            <br />
            안녕하세요!
          </h2>
          <h6>내 지점</h6>
          <MerchantList>
            {merchantList.map((merchant) => (
              <MerchantListItem
                name={merchant.merchantName}
                onClick={() => onClickMerchant(merchant.merchantId)}
              />
            ))}
          </MerchantList>
        </ContentArea>
      </Container>
    </Fragment>
  );
};
export default SelectMerchant;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding-top: 3rem;

  height: 80vh;
`;
const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 90%;
  max-width: 30rem;
  gap: 1rem;

  h2 {
    width: 100%;
    text-align: start;
    line-height: 150%;
    font-weight: 500;
    strong {
      color: ${({ theme }) => theme.main[600]};
    }
  }

  h6 {
    width: 100%;
    text-align: start;
  }
`;

const MerchantList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledMerchantListItem = styled.div`
  cursor: pointer;

  width: 100%;
  display: grid;
  grid-template-columns: 2.5rem auto 2rem;
  align-items: center;
  gap: 1rem;

  padding: 1rem;
  border-radius: 0.75rem;
  box-shadow: 0px 0px 12px 0px #303d4c14;

  span {
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.neutralGray[500]};
    word-break: break-all;
  }
`;

const MerchantListItem = ({ name, onClick }: MerchantListItemProps) => {
  return (
    <StyledMerchantListItem onClick={onClick}>
      <img src={IconMerchant} width="40" />
      <span>{name}</span>
      <img src={ArrowRight} width="24" />
    </StyledMerchantListItem>
  );
};
