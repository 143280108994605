import React, { useState, useRef, useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ModalDispatchContext, ModalsStateContext } from '../../store/modalContext';

const ModalButton = styled.button`
  font-size: 1rem;
  line-height: 100%;
  color: ${({ theme, highlighten }) => (highlighten ? 'white' : theme.main[600])};

  padding: 0.69rem 1.5rem;
  border: 1px solid ${({ theme }) => theme.main[600]};
  border-radius: 0.25rem;
  background: ${({ theme, highlighten }) => (highlighten ? theme.main[600] : 'white')};
`;

const StyledModal = styled.div`
  position: fixed;
  top: ${({ show }) => (show ? '50%' : '200%')};
  left: 50%;
  transform: translate(-50%, -50%);

  min-width: 22.5rem;
  min-height: 9rem;
  max-width: 95vw;

  overflow: auto;

  background: white;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;

  color: #555555;
  line-height: 150%;

  padding: ${({ noPadding }) => (noPadding ? 'none' : '2.5rem')};

  border-radius: 1rem;

  transition: top 0.3s ease-in-out, opacity 0.15s ease-in-out;
  opacity: 0;

  ${(props) =>
    props.show &&
    css`
      opacity: 1;
    `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  button {
    margin-bottom: 0;
  }
  button + button {
    margin-left: 1rem;
  }
`;

const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--dvh, 1dvh) * 100);

  background: ${({ enabled }) => (enabled ? 'rgba(0, 0, 0, 0.5)' : 'transparent')};
  /* transition: all 0.15s ease-in-out; */
`;

const Modal = ({
  show,
  modalRef,
  children,
  hasCancelButton,
  uncontrolled,
  noPadding,
  confirmButtonText,
  cancelButtonText,
  onClickConfirm,
  onClickCancel,
  zIndex,
  style,
}) => {
  return (
    <StyledModal
      noPadding={noPadding}
      ref={modalRef}
      show={show}
      style={{ ...style, zIndex: `${zIndex}` }}>
      <div style={{ width: '100%' }}>{children}</div>
      {!uncontrolled && (
        <ModalButtons
          hasCancelButton={hasCancelButton}
          onClickCancel={onClickCancel}
          onClickConfirm={onClickConfirm}
          cancelButtonText={cancelButtonText}
          confirmButtonText={confirmButtonText}
        />
      )}
    </StyledModal>
  );
};

/**
 * 모달 버튼
 */
export const ModalButtons = ({
  hasCancelButton,
  onClickCancel,
  onClickConfirm,
  cancelButtonText,
  confirmButtonText,
}) => {
  return (
    <ButtonWrapper hasCancelButton={hasCancelButton}>
      {hasCancelButton && <ModalButton onClick={onClickCancel}>{cancelButtonText}</ModalButton>}
      <ModalButton highlighten onClick={onClickConfirm} id="confirm_btn">
        {confirmButtonText}
      </ModalButton>
    </ButtonWrapper>
  );
};

const ModalContainer = ({ modal, index }) => {
  const { close } = useContext(ModalDispatchContext);
  const openedModals = useContext(ModalsStateContext);

  const modalRef = useRef(null);
  const [isShow, setIsShow] = useState(false);
  const { Component, props } = modal;
  const onClose = () => {
    // TODO: 닫기 애니메이션 구현
    setIsShow(false);

    // setTimeout(() => {
    close(Component);
    // }, 300);
  };

  // 모달 올라올 때 애니메이션
  useEffect(() => {
    setIsShow(true);
    // 타이머가 있으면 해당 타이머 이후에 모달이 자동으로 닫힘
    if (props.timer) setTimeout(() => onClose(), props.timer);
  }, []);

  return (
    <>
      <Dimmer
        enabled={isShow}
        currentIndex={index}
        lastIndex={openedModals.length - 1}
        onClick={() => {
          if (props.onClickDimmer) props.onClickDimmer();
          if (props.preventClose) return;
          onClose();
        }}
        style={{ zIndex: 499 + 2 * index }}
      />
      <Modal
        style={props.style}
        modalRef={modalRef}
        show={isShow}
        key={index}
        uncontrolled={props.uncontrolled}
        zIndex={500 + 2 * index}
        hasCancelButton={props.hasCancelButton}
        noPadding={props.noPadding}
        onClickConfirm={() => {
          props.onClickConfirm();
          onClose();
        }}
        onClickCancel={
          props.hasCancelButton
            ? () => {
                props.onClickCancel && props.onClickCancel();
                onClose();
              }
            : undefined
        }
        confirmButtonText={props.confirmButtonText || '확인'}
        cancelButtonText={props.cancelButtonText || '취소'}>
        <Component key={index} {...props} />
      </Modal>
    </>
  );
};

const Modals = () => {
  const openedModals = useContext(ModalsStateContext);
  return (
    <>
      {openedModals.map((modal, index) => {
        return <ModalContainer key={index} modal={modal} index={index} />;
      })}
    </>
  );
};

export default Modals;
